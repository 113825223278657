<template>
  <div class="EngineeringService contsctUs">
    <Carousel :imgList="imgList" />
    <div class="contents">
      <h3>{{$t('DataReengineering')}}</h3>
      <p>
      {{$t('scanning')}}
      </p>
      <p>
     {{$t('transformed')}}
      </p>
      <p>
        {{$t('simulation')}}
      </p>
      <div class="img">
        <img src="./../../assets/images/sjfx.png" alt="" />
        <img src="./../../assets/images/zz.png" alt="" />
      </div>
    </div>
    <mainProduct />
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";

// 主要产品
import mainProduct from "./../../components/EngineeringService/mainproduct.vue";
import img1 from './../../assets/images/banner1.jpg'

export default {
  name: "About",
  components: {
    Company,
    tFooter,
    Carousel,
    mainProduct
  },
  props: {},
  data() {
    return {
      // imgList: {
      //   url: img1,
      //   className: "fadeInUp animated textLeft",
      //   title: "Engineering Service",
      //   xtitle: "Provide one-stop solution and complete \n supporting service",
      // },
    };
  },
  methods: {},
  computed:{
    imgList(){
      return{
        url: img1,
        className: "fadeInUp animated textLeft",
        title: this.$t('EngineeringService'),
        xtitle: this.$t('engineeXtitle'),
      }
    }
  },
  created() {},
};
</script>

<style lang="less">

</style>
