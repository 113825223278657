<template>
  <div class="Drop-downBox">
    <div class="dropdown">
      <button class="dropbtn" @click="isShow = !isShow">
        <img class="tupan" src="./../../assets/images/caiDan.png" />
      </button>
      <div class="dropdown-content" v-show="isShow">
        <a>
          <div
            type="button"
            :class="{ uls: true, 'active-color': 1 === active }"
          >
            <span @click="toProducts()">{{ $t("Products") }} </span>
          </div>
        </a>
        <a
          ><div :class="{ uls: true, 'active-color': 2 === active }">
            <span @click="toEngineeringService()">
              {{ $t("EngineeringService") }}
            </span>
          </div></a
        >
        <a
          ><div :class="{ uls: true, 'active-color': 3 === active }">
            <span @click="toFreeHelp()">{{ $t("FreeMaintanceHelp") }}</span>
          </div></a
        >
        <a
          ><div :class="{ uls: true, 'active-color': 4 === active }">
            <span @click="toAbout()">{{ $t("About") }}</span>
          </div></a
        >
        <a
          ><div :class="{ uls: true, 'active-color': 5 === active }">
            <span @click="toNews()">{{ $t("News") }}</span>
          </div></a
        >
        <a
          ><div :class="{ uls: true, 'active-color': 7 === active }">
            <span @click="toContactUs()">{{ $t("ContactUs") }}</span>
          </div></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      Visiable: false,
      LogindialogVisible: false,
      psssworddialogVisible: false,
      createdialogVisible: false,
      activeIndex: "1",
      activeIndex2: "1",
      active: 0,
      form: {
        name: "",
      },
      imgUrl: "src/assets/images/caiDan.png",
      openDialog: false,
    };
  },
  methods: {
    arrowDown() {
      this.isShowSelect = !this.isShowSelect;
    },
    // 7.实现点击事件，点击事件一定要包含以下内容
    handleClick(data) {
      this.Visiable = true;
      this.$nextTick(() => {
        //这里的dialog与上面dialog-component组件里面的ref属性值是一致的
        //init调用的是dialog-component组件里面的init方法
        //data是传递给弹窗页面的值
        this.$refs.dialog.init(data);
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    reload() {
      this.isShow = false;
    },
    toVisible() {
      this.active = 6;
      this.LogindialogVisible = true;
    },
    toContactUs() {
      this.active = 7;
      this.$router.push("/contactUs");
      this.reload();
    },
    toAbout() {
      this.active = 4;
      this.$router.push("/About");
      this.reload();
    },
    toNews() {
      this.active = 5;
      this.$router.push("/news");
      this.reload();
    },
    toFreeHelp() {
      this.active = 3;
      this.$router.push("/MaintenanceHlep");
      this.reload();
    },
    toProducts() {
      this.active = 1;
      this.$router.push("/Products");
      this.reload();
    },
    toEngineeringService() {
      this.active = 2;
      this.$router.push("/EngineeringService");
      this.reload();
    },
    // 按钮方法
    /*handleDialog() {
      this.openDialog = true;
      this.$nextTick(() => {
        this.$refs.testDialog.init();
      });
    }*/
  },
  hongtiao(e) {
    this.setData({
      currentIndexNav: e.target.dataset.index,
    });
  },
};
</script>

<style scpoed lang="less">
.dropbtn {
  background-color: rgba(255, 255, 255, 0.01);
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  position: relative;
  left: 50px;
  top: 20px;
}
.tupan {
  position: absolute;
  left: -0.125rem !important;
  top: 0.25rem !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 560px;
  height: 118.625rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 70px;
}

.dropdown-content a {
  color: black;
  padding: 70px 16px;
  text-decoration: none;
  display: block;
  font-size: 6rem !important;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

//.dropdown:hover .dropdown-content {
//  display: block;
//}

.dropdown:hover .dropbtn {
  background-color: rgba(255, 255, 255, 0.06);
}
/*.longin {
  .el-dialog {
    width: 780px;
    height: 528px;
    padding-top: 40px;
    .loginform {
      margin: 33px 89px 0 94px;
    }
    .el-button {
      width: 100%;
    }
    .el-checkbox__inner {
      border: 1px solid rgba(255, 255, 255, 0.05);
    }
    .GetValicode {
      width: 230px;
      float: right;
    }
    .GetValicodeinput {
      width: 278px;
    }
    .wjmm {
      float: right;
      cursor: pointer;
      &:hover {
        color: rgba(255, 255, 255, 0.02);
      }
    }
  }
}*/
// 弹框大小
/*.el-popper{
  width: 1000px;
  height: 1000px;
}*/
.Continue {
  background: #d0101b !important;
  border: 1px solid #707070 !important;
}
@media screen and (max-width: 766px) {
  .nav {
    width: 100%;
    z-index: 100;
    // background: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    //小屏样式
    position: fixed;
    top: 230px;
    height: 0.05rem;
    #nav {
      z-index: 200;
      background: rgba(0, 0, 0, 0);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0);
    }
    .el-dropdown-link {
      position: relative;
      top: 1.75rem;
    }
    .Drop-downBox {
      background-color: rgba(204, 204, 204, 0.01);
      width: 200px;
      height: 200px;
      position: relative;
      top: -12.75rem;
    }
    .menu1 {
      display: none;
    }
    .menu2 {
      display: none;
    }
    img {
      width: 150px;
      position: relative;
      left: 0.875rem;
      top: 2.25rem;
    }
    .denglu {
      position: relative;
      left: 1559px;
      top: -412px;
    }
  }
}
@media screen and (min-width: 768px) {
  .nav {
    width: 100%;
    z-index: 100;
    background: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}
</style>
