<template>
  <div class="news contsctUs">
    <Carousel :imgList="imgList" />

    <!-- 新闻页面列表 -->
    <div class="CompanyNews">
      <h2>{{ $t("CompanyNews") }}</h2>
      <!-- <div class="bordered"></div> -->
      <div class="Newsuls" v-for="(item, index) in list" :key="index">
        <div class="Newslis" @click="toDetails(index)">
          <div class="img">
            <div class="date" v-if="item.articleDotime != 'Invalid date'">
              <p>{{ item.articleDotime.slice(-2) }}</p>
              <p>{{ item.articleDotime.slice(0, 7) }}</p>
            </div>
            <div class="NewImg"><img :src="item.articleMedia" alt="" /></div>
          </div>
          <div class="con">
            <h3>
              {{ item.articleTitle | titleLeng }}
            </h3>
            <p>
              {{ item.articleResume | msglimit }}
            </p>
            <span class="btn" @click="toDetails(index)">{{
              $t("MoreInformation")
            }}</span>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="5"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";
import img1 from "./../../assets/images/banner1.jpg";
import { article } from "../../api/api";
//引入moment函数
import moment from "moment";

export default {
  name: "About",
  components: {
    Company,
    tFooter,
    Carousel,
  },
  props: {},
  data() {
    return {
      form: {
        current: 1,
        size: 5,
      },
      list: [], //新闻数据
      lang: "", //语言
      total: 0, //总页
    };
  },
  filters: {
    msglimit(val) {
      if (val.length > 300) {
        val = val.slice(0, 300) + "...";
      }
      return val;
    },
    titleLeng(val) {
      if (val.length > 60) {
        val = val.slice(0, 60) + "...";
      }
      return val;
    },
  },
  methods: {
    //改变分页
    handlePageChange(currentPage) {
      this.form.current = currentPage;

      //在此刷新数据
      //获取新闻列表
      this.article();
    },
    //获取新闻
    article() {
      // debugger
      article(this.form).then((res) => {
        
        if (res.status == 200) {
          this.list.length = 0;
          this.total = res.data.total; //总页数赋值
          res.data.records.forEach((item) => {
            if (this.lang == null) {
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitleen,
                articleResume: item.articleResumeen,
                articleDotime: moment(item.articleDotime).format("YYYY-MM-DD"),
              });
              // console.log(this.list)
            } else if (this.lang === "zh") {
              //中文
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitlezh,
                articleResume: item.articleResumezh,
                articleDotime: moment(item.articleDotime).format("YYYY-MM-DD"),
              });
              console.log(this.list);
            } else if (this.lang === "en") {
              //英文
              // debugger
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitleen,
                articleResume: item.articleResumeen,
                articleDotime: moment(item.articleDotime).format("YYYY-MM-DD"),
              });
              // console.log(this.list)
            } else if (this.lang === "fr") {
              //法语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitlefr,
                articleResume: item.articleResumefr,
                articleDotime: moment(item.articleDotime).format("YYYY-MM-DD"),
              });
              // console.log(this.list)
            } else if (this.lang === "ru") {
              //俄语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitleru,
                articleResume: item.articleResumeru,
                articleDotime: moment(item.articleDotime).format("YYYY-MM-DD"),
              });
              // console.log(this.list)
            } else if (this.lang === "es") {
              //西班牙语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitlees,
                articleResume: item.articleResumees,
                articleDotime: moment(item.articleDotime).format("YYYY-MM-DD"),
              });
              // console.log(this.list)
            } else if (this.lang === "ar") {
              //阿拉伯语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitlear,
                articleResume: item.articleResumear,
                articleDotime: moment(item.articleDotime).format("YYYY-MM-DD"),
              });
              // console.log(this.list)
            } else if (this.lang === "id") {
              //印尼语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitleid,
                articleResume: item.articleResumeid,
                articleDotime: moment(item.articleDotime).format("YYYY-MM-DD"),
              });
              // console.log(this.list)
            } else if (this.lang === "tr") {
              //土耳其语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitleetr,
                articleResume: item.articleResumetr,
                articleDotime: moment(item.articleDotime).format("YYYY-MM-DD"),
              });
              // console.log(this.list)
            }
          });
        }
      });
    },
    toDetails(index) {
      // debugger
      let articleId = this.list[index].articleId;
      console.log(index, "index");
      this.$router.push({
        path: "/newsdeail",
        query: {
          articleId: articleId,
          index: index,
          total: this.total,
          current: this.form.current,
          size: this.form.size,
        },
      });
    },
  },
  computed: {
    imgList() {
      return {
        url: window.g.newsImg,
        className: "fadeInUp animated textLeft",
        title: this.$t("News"),
        xtitle: "",
      };
    },
  },
  created() {
    this.article();
  },
  mounted() {
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      console.log(this.lang);
      //语言改变 重新去请求
      this.article();
    });
  },
};
</script>

<style lang="less">
.news {
  @media screen and (max-width: 766px) {
    .CompanyNews {
      margin: 0 156px;
      h2 {
        font-weight: normal;
        height: 64px;
        font-size: 6rem;
        color: #000;
        // text-align: center;
        text-align: left;
      }
      .bordered {
        width: 160px;
        height: 2px;
        background: #d11d26;
        margin: auto;
      }
      .Newsuls {
        margin-top: 200px;
        .NewImg {
          width: 600px;
          height: 600px;
        }
        .Newslis {
          margin-top: 80px;
          position: relative;
          .img {
            position: relative;
            display: inline-block;
            vertical-align: top;
            img {
              width: 600px;
              height: 600px;
              border: 1px solid #ffffff;
              opacity: 1;
            }
            .date {
              position: absolute;
              width: 250px;
              height: 250px;
              z-index: 11;
              background: #d3232d;
              color: #fff;
              p {
                text-align: center;
                &:first-child {
                  font-size: 50px;
                  display: block;
                  margin: 30px;
                }
                &:last-child {
                  font-size: 40px;
                  display: block;
                  margin: 30px;
                }
              }
            }
          }
          .con {
            display: inline-block;
            vertical-align: top;
            margin-left: 50px;
            h3 {
              font-weight: normal;
              display: block;
              font-size: 60px;
              color: #333;
              width: 900px;
            }
            p {
              display: block;
              font-size: 40px;
              color: #999;
              width: 900px;
            }
            .btn {
              display: block;
              padding: 10px 20px;
              background: #d3232d;
              color: #fff;
              border-radius: 10px;
              text-align: center;
              font-size: 40px;
              position: absolute;
              bottom: 30px;
              cursor: pointer;
            }
          }
        }
      }
      .Newsuls1 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 79px;
        .Newsulis1 {
          width: 100%;
          height: 1000px;
          background: #fff;
          box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.16);
          border-radius: 30px;
          // border: 1px solid #707070;
          opacity: 1;
          position: relative;
          margin-bottom: 50px;
          padding: 10px;
          .bf {
            width: 380px;
            height: 380px;
            position: absolute;
            cursor: pointer;
            left: 645px;
            top: 210px;
            background: url(./../../assets/images/953.png) 0 0 no-repeat;
            background-size: cover;
          }
          img {
            width: 100%;
            height: 800px;
            border: 1px solid #707070;
            border-radius: 30px 30px 0px 0px;
            opacity: 1;
          }
          p {
            font-size: 60px;
            color: #999;
            margin: 51px 21px;
          }
        }
      }
      .pagination {
        margin: 100px auto;
        text-align: center;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .CompanyNews {
      margin: 0 156px;
      h2 {
        height: 64px;
        font-size: 48px;
        color: #000;
        // text-align: center;
        text-align: left;
      }
      .bordered {
        width: 160px;
        height: 2px;
        background: #d11d26;
        margin: auto;
      }
      .Newsuls {
        margin-top: 80px;
        .Newslis {
          cursor: pointer;
          height: 360px;
          display: flex;
          .img {
            width: 450px;
            height: 360px;
            position: relative;
            img {
              width: 450px;
              height: 360px;
              border: 1px solid #ffffff;
              border-radius: 0px;
              opacity: 1;
            }
            .date {
              position: absolute;
              width: 120px;
              height: 120px;
              z-index: 11;
              background: #d3232d;
              color: #fff;
              p {
                margin: 11px 0 0 0;
                width: 100%;
                &:first-child {
                  margin: 6px 0 0 0;
                  font-size: 48px;
                  text-align: center;
                  height: 64px;
                  line-height: 64px;
                }
                &:last-child {
                  font-size: 24px;
                  text-align: center;
                  height: 31px;
                  margin: 5px 0 0 0;
                  line-height: 31px;
                }
              }
            }
          }
          .con {
            flex: 1;
            margin-left: 59px;
            position: relative;
            h3 {
              font-size: 32px;
              color: #333;
              // width: 835px;
              // margin: 10px 50px;
              // height: 101px;
            }
            p {
              margin: 0;
              font-size: 29px;
              color: #999;
              margin-top: 24px;
              height: 156px;
            }
            .btn {
              display: block;
              width: 200px;
              padding: 10px 20px;
              position: absolute;
              bottom: 0;
              background: #d3232d;
              color: #fff;
              border-radius: 10px;
              text-align: center;
              font-size: 20px;
              line-height: 50px;
              margin-bottom: 0px;
              cursor: pointer;
            }
          }
        }
      }
      .Newsuls1 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 79px;
        .Newsulis1 {
          width: 506px;
          height: 590px;
          background: #fff;
          box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.16);
          border-radius: 30px;
          // border: 1px solid #707070;
          opacity: 1;
          position: relative;
          .bf {
            width: 180px;
            height: 180px;
            position: absolute;
            cursor: pointer;
            left: 163px;
            top: 80px;
            background: url(./../../assets/images/953.png) 0 0 no-repeat;
            background-size: cover;
          }
          img {
            width: 506px;
            height: 339px;
            border: 1px solid #707070;
            border-radius: 30px 30px 0px 0px;
            opacity: 1;
          }
          p {
            font-size: 24px;
            color: #999;
            margin: 51px 21px;
          }
        }
      }
      .pagination {
        margin: 100px auto;
        text-align: center;
      }
    }
  }
}
</style>

