import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import html2canvas from 'html2canvas'
import "./assets/css/font.css";
// 动画
import "./assets/css/animate.min.css";

import "./utils/rem";

//cookie引入
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)



//引入图片地址的文件并全局注册
// import { imgUrl } from "./../public/global";
Vue.prototype.$global = {
  imgUrl: window.g.imgUrl,
};

Vue.use(VueI18n);

Vue.config.productionTip = false;

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
//全局注册饿了么ui
Vue.use(ElementUI);
//多语言实例
//多语言实例

const i18n = new VueI18n({
  locale: window.sessionStorage.getItem("lang") || "en", // 语言标识,
  messages: {
    // zh: require("./assets/language/Chinese"), //汉语zh
    // en: require("./assets/language/English"), //英语en
    // fr: require("./assets/language/French"), //法语fr
    // ru: require("./assets/language/Russian"), //俄语ru
    // es: require("./assets/language/Spanish"), //西班牙语es
    // ar: require("./assets/language/Arabic"), //阿拉伯文ar
    // id: require("./assets/language/Indonesian"), //印尼文id
    // tr: require("./assets/language/Turkish"), //土耳其文tr
    zh: window.g.Zh, //汉语zh
    en: window.g.En, //英语en
    fr:window.g.Fr, //法语fr
    ru:window.g.Ru, //俄语ru
    es:window.g.Sp, //西班牙语es
    ar: window.g.Ar, //阿拉伯文ar
    id: window.g.Ind, //印尼文id
    tr: window.g.Tr, //土耳其文tr
  },
});

new Vue({
  router,
  i18n,
  store,
  html2canvas,
  render: (h) => h(App),
}).$mount("#app");

//首先在main.js中给Vue.protorype注册一个全局方法，其中，我们约定好了想要监听的sessionStorage的key值为’lang’，
//然后创建一个StorageEvent方法，当我在执行sessionStorage.setItem(k, val)这句话的时候，初始化事件，并派发事件。
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === "lang") {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  }
};
