<template>
  <div class="contsctUs about">
    <Carousel :imgList="imgList" />
    <!--        <div class="content" v-html="aboutData"></div>-->
    <!-- 我们是谁 -->
    <div class="whoWeare">
      <h2>{{ $t('Whoweare') }}</h2>
      <!-- <div class="borderred"></div> -->
      <p>
        {{ $t('supply') }}
      </p>
      <div class="q">
        <img class="img11" :src="about1" alt="" />
        <img class="img11" :src="about2" alt="" />
      </div>
    </div>
    <!-- CORE VALUES我们的价值观 -->
    <div class="coreValues">
      <h2>{{ $t('COREVALUES') }}</h2>
      <!-- <div class="borderred"></div> -->
      <div class="core-uls">
        <img src="./../../assets/images/651.png" alt="" />
        <p>{{ $t('PeopleOriented') }}</p>
        <p>
          {{ $t('Establish') }}
        </p>
      </div>
      <div class="core-uls">
        <img src="./../../assets/images/656.png" alt="" />
        <p>{{ $t('CustomerFirst') }}</p>
        <p>
          {{ $t('customer') }}
        </p>
      </div>
      <div class="core-uls">
        <img src="./../../assets/images/659.png" alt="" />
        <p>{{ $t('Qualityfirst') }}</p>
        <p>
          {{ $t('problems') }}
        </p>
      </div>
      <div class="core-uls">
        <img src="./../../assets/images/703.png" alt="" />
        <p>{{ $t('Integritymanagement') }}</p>
        <p>
          {{ $t('development') }}
        </p>
      </div>
    </div>

    <!-- Service Industry -->
    <div class="whoWeare">
      <h2>{{ $t('ServiceIndustry') }}</h2>
      <!-- <div class="borderred"></div> -->
      <p>
        {{ $t('different') }}
      </p>
      <p>
        {{ $t('procurement') }}
      </p>
      <div class="best">
        <div class="best0 bestbg0">
          <div class="best1">{{ $t('mining') }}</div>
        </div>
        <div class="best0 bestbg1">
          <div class="best1">{{ $t('chemical') }}</div>
        </div>
        <div class="best0 bestbg2">
          <div class="best1">{{ $t('riverdredging') }}</div>
        </div>
        <div class="best0 bestbg3">
          <div class="best1">{{ $t('metallurgy') }}</div>
        </div>
        <div class="best0 bestbg4">
          <div class="best1">{{ $t('powerplant') }}</div>
        </div>
        <div class="best0 bestbg5">
          <div class="best1">{{ $t('agriculture') }}</div>
        </div>
      </div>
    </div>

    <!-- team -->
    <div class="information">
      <h2>{{ $t('Servicepersonnel') }}</h2>
      <!-- <div class="borderred"></div> -->
      <Info />
    </div>

    <div class="information">
      <h2>{{ $t('supporting') }}</h2>
      <!-- <div class="borderred"></div> -->
      <div class="opv">
        <img class="img1" src="./../../assets/images/610.png" />
        <img class="img2" src="./../../assets/images/620.png" />
        <img class="img3" src="./../../assets/images/614.png" />
        <img class="img4" src="./../../assets/images/602.png" />
      </div>
    </div>

    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";

// 人员列表
import Info from "./../../components/ContactUs/info.vue";

// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";
import { getarticle } from "../../api/api";

export default {
  name: "About",
  components: {
    Company,
    tFooter,
    Info,
    Carousel,
  },
  props: {},
  data() {
    return {
      lang: '',//语言
      aboutData: {},//关于页面的数据
      about1: window.g.aboutLeft,
      about2: window.g.aboutRight,
      form: {
        Lang: '',
        articleId: 1, //传1是关于界面的数据
      },//要传的参数
      //   imgList: {
      //   url: "https://adm.moretecgroup.com/upload/bill/0/3/21080216090004.jpg",
      //   className: "fadeInUp animated textLeft",
      //   title: "Contact Us",
      //   xtitle: "Anytime，Anywhere. \n We're here when you need us.",
      // }
    };
  },
  methods: {
    //关于的接口
    about() {
      //页面一进入获取默认语言
      let lang = sessionStorage.getItem('lang')
      if (lang == null) {
        lang = 'en'
      }
      this.form.Lang = lang
      getarticle(this.form).then(res => {
        console.log(res)
        if (res.status == 200) {
          if (lang === 'zh') {
            this.aboutData = res.data.records[0].articleContentzh.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
              return `<img src='${require('../../assets' + p1)}' />`
            })
          } else if (lang === 'en') {
            this.aboutData = res.data.records[0].articleContenten.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
              //return `<img src='${p1.indexOf('http') > -1 ? p1 : this.$global.localIp +'/static'+ p1}' />`
              // console.log(p1);
              return `<img src='${require('../../assets' + p1)}' />`
            })
            // this.aboutData = res.data.records[0].articleContenten.replace(/\(\)/g, (match, p1) => {
            //     //return `<img src='${p1.indexOf('http') > -1 ? p1 : this.$global.localIp +'/static'+ p1}' />`
            //     console.log(p1);
            //     return `<img src='${require('../../assets' + p1)}' />`
            // })
            //字符串转dom
            // const tpl = document.createElement('div');
            // tpl.innerHTML =res.data.records[0].articleContenten;
            // console.log(tpl.innerHTML)
          } else if (lang === 'fr') {
            this.aboutData = res.data.records[0].articleContentfr.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
              return `<img src='${require('../../assets' + p1)}' />`
            })
          } else if (lang === 'ru') {
            this.aboutData = res.data.records[0].articleContentru.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {

            })
          } else if (lang === 'es') {
            this.aboutData = res.data.records[0].articleContentes.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
              return `<img src='${require('../../assets' + p1)}' />`
            })
          } else if (lang === 'ar') {
            this.aboutData = res.data.records[0].articleContentar.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
              return `<img src='${require('../../assets' + p1)}' />`
            })
          } else if (lang === 'id') {
            this.aboutData = res.data.records[0].articleContentid.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
              return `<img src='${require('../../assets' + p1)}' />`
            })
          } else if (lang === 'tr') {
            this.aboutData = res.data.records[0].articleContenttr.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
              return `<img src='${require('../../assets' + p1)}' />`
            })
          }
        }
      })
    },
  },
  computed: {
    imgList() {
      return {
        url: window.g.aboutImg,
        className: "fadeInUp animated textLeft",
        title: this.$t('AboutUs'),
        xtitle: this.$t('Anytime'),
      }
    }
  },
  mounted() {
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem('lang')
    // 添加对 sessionStorage 的监听
    window.addEventListener('setItem', () => {
      this.lang = sessionStorage.getItem('lang');
      console.log(this.lang)
      //语言改变 重新去调用关于界面接口
      this.about()
    })
  },
  created() {
    this.about()
  },
};
</script>

<style lang="less">
.wechat1 {
  position: fixed;
  right: 121px;
  bottom: 21px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  z-index: 101;
  background: url(./../../assets/images/product.png) no-repeat;
  background-size: contain;
}

.about {
  @media screen and (max-width: 766px) {
    .whoWeare {
      margin: 48px 156px;

      h2 {
        height: 55px;
        font-size: 142px;
        color: #333;
      }

      .borderred {
        width: 160px;
        height: 2px;
        background: #d11d26;
      }

      p {
        color: black;
        font-size: 50px;
      }

      .q {
        display: flex;
        justify-content: space-between;
      }

      img {
        width: 50%;
        height: 624px;
        // margin-top: 52px;
        // margin-left: 48px;
        margin: 52px 48px 0px 0px;
        align-items: center;
      }

      .best {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px;

        .best0 {
          width: 750px;
          height: 500px;
          margin-bottom: 40px;

          .best1 {
            width: 500px;
            height: 120px;
            display: none;
            border-radius: 5px;
            text-align: center;
            line-height: 120px;
            background-color: #d01f27;
            color: #fff;
            font-size: 70px;
            margin: 25% auto;
            cursor: pointer;
          }

          &:hover {
            .best1 {
              display: block;
            }
          }
        }

        .bestbg0 {
          background: url(./../../assets/images/642.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg1 {
          background: url(./../../assets/images/634.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg2 {
          background: url(./../../assets/images/638.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg3 {
          background: url(./../../assets/images/646.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg4 {
          background: url(./../../assets/images/630.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg5 {
          background: url(./../../assets/images/556.png) 0 0 no-repeat;
          background-size: cover;
        }
      }
    }

    .coreValues {
      margin: 176px 110px 0 150px;
      padding-bottom: 100px;
      background: url(https://adm.moretecgroup.com/upload/bill/0/3/21080216090004.jpg) no-repeat;
      background-size: cover;

      h2 {
        color: #fff;
        font-size: 148px;
        text-align: center;
        padding-top: 170px;
      }

      .borderred {
        margin-top: 20px;
        width: 160px;
        height: 5px;
        background: #d11d26;
        margin: auto;
      }

      .core-uls {
        margin: 57px 155px 0 135px;
        position: relative;

        img {
          width: 150px;
          height: 150px;
          position: absolute;
        }

        p {
          font-size: 65px;
          color: #fff;
          text-align: left;
          padding-left: 212px;
          line-height: 137px;
        }
      }
    }

    .opv {
      display: flex;
      justify-content: space-between;
      padding: 0px 0 0px 0;
      box-sizing: border-box;
      height: 200px;
      align-items: center;

      .img1 {
        height: 58px;
        width: 370px;
      }

      .img2 {
        height: 123px;
        width: 308px;
      }

      .img3 {
        height: 61px;
        width: 314px;
      }

      .img4 {
        height: 55px;
        width: 346px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .whoWeare {
      margin: 48px 156px;

      h2 {
        height: 55px;
        font-size: 42px;
        color: #333;
      }

      .borderred {
        width: 160px;
        height: 2px;
        background: #d11d26;
      }

      p {
        font-size: 28px;
      }

      .q {
        display: flex;
        justify-content: space-between;
      }

      img {
        width: 48%;
        // height: 624px;
        // margin-top: 52px;
        // margin-left: 48px;
        // margin: 52px 48px 0px 0px;
        align-items: center;
      }

      .best {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .best0 {
          width: 515px;
          height: 340px;
          margin-bottom: 40px;

          .best1 {
            width: 188px;
            height: 55px;
            display: none;
            border-radius: 5px;
            text-align: center;
            line-height: 55px;
            background-color: #d01f27;
            color: #fff;
            font-size: 22px;
            margin: 142px auto;
            cursor: pointer;
          }

          &:hover {
            .best1 {
              display: block;
            }
          }
        }

        .bestbg0 {
          background: url(./../../assets/images/642.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg1 {
          background: url(./../../assets/images/634.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg2 {
          background: url(./../../assets/images/638.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg3 {
          background: url(./../../assets/images/646.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg4 {
          background: url(./../../assets/images/630.png) 0 0 no-repeat;
          background-size: cover;
        }

        .bestbg5 {
          background: url(./../../assets/images/556.png) 0 0 no-repeat;
          background-size: cover;
        }
      }
    }

    .coreValues {
      margin: 76px 156px;
      height: 1071px;
      background: url(https://adm.moretecgroup.com/upload/bill/0/3/21080216090004.jpg) no-repeat;
      background-size: cover;

      h2 {
        color: #fff;
        font-size: 48px;
        text-align: center;
        padding-top: 39px;
      }

      .borderred {
        margin-top: 20px;
        width: 160px;
        height: 5px;
        background: #d11d26;
        margin: auto;
      }

      .core-uls {
        margin: 57px 155px 0 135px;
        position: relative;

        img {
          width: 120px;
          height: 120px;
          position: absolute;
        }

        p {
          font-size: 28px;
          color: #fff;
          text-align: left;
          padding-left: 212px;
          line-height: 37px;
        }
      }
    }

    .opv {
      display: flex;
      justify-content: space-between;
      padding: 0px 0 0px 0;
      box-sizing: border-box;
      height: 200px;
      align-items: center;

      .img1 {
        height: 58px;
        width: 370px;
      }

      .img2 {
        height: 123px;
        width: 308px;
      }

      .img3 {
        height: 61px;
        width: 314px;
      }

      .img4 {
        height: 55px;
        width: 346px;
      }
    }
  }
}</style>

