<template>
  <div class="header">
    <div class="titmenu">
      <div class="titmenu0">
        <div class="lafs">
          <a href="mailto:wendy.liu@mizztech.com" style="">
            <span @click="gocontactUs()">
              <img src="./../../assets/images/email.png" />
              sales@mizztech.com
            </span>
          </a>
          <span @click="gocontactUs()">
            <img src="./../../assets/images/phone.png" />+86 138 1077 7318
          </span>
        </div>
      </div>
      <div class="titmenu1" @click="toHome()">
        <img src="./../../assets/images/logo.png" />
      </div>
      <div class="titmenu2">
        <!--        搜索框-->

        <div class="search">
          <el-input
            v-model="form.goodsBrand"
            class="titmenu2_iput"
            :placeholder="$t('Search')"
            suffix-icon="el-icon-search"
            @change="search1"
          ></el-input>
        </div>
        <div class="lang">
          <el-dropdown
            class="slelect-lang"
            @command="handleLang"
          >
            <div>
              <img class="Guoqi" :src="imgsrc" />
              <i class="el-icon-caret-bottom"></i>
            </div>
            <!-- <span class="el-dropdown-link selected-lang"> </span> -->
            <el-dropdown-menu slot="dropdown" class="ul-list">
              <el-dropdown-item command="zh">
                <img src="./../../assets/images/zh.svg" />
                <span>中文</span>
              </el-dropdown-item>
              <el-dropdown-item command="en">
                <img src="./../../assets/images/en.svg" /><span>English</span>
              </el-dropdown-item>
              <el-dropdown-item command="fr">
                <img src="./../../assets/images/fr.svg" /><span>Français</span>
              </el-dropdown-item>
              <el-dropdown-item command="ru">
                <img src="./../../assets/images/ru.svg" /><span>Pусский</span>
              </el-dropdown-item>
              <el-dropdown-item command="es"
                ><img src="./../../assets/images/es.svg" /><span>Español</span>
              </el-dropdown-item>

              <el-dropdown-item command="ar">
                <img src="./../../assets/images/ar.svg" /><span>عربى</span>
              </el-dropdown-item>

              <el-dropdown-item command="id">
                <img src="./../../assets/images/id.svg" />
                <span>Indonesia</span>
              </el-dropdown-item>
              <el-dropdown-item command="tr">
                <img src="./../../assets/images/tr.svg" /><span>турецкий</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="suoxiaoguoqi">
          <el-dropdown
            class="slelect-lang1"
            trigger="click"
            @command="handleLang"
          >
            <span>
              <img :src="imgsrc" style="width: 25px" />
            </span>
            <el-dropdown-menu trigger="click" slot="dropdown" class="ul-list">
              <!-- <el-dropdown-item command="zh">
                <img src="./../../assets/images/zh.svg" />
              </el-dropdown-item> -->
              <el-dropdown-item command="en">
                <img src="./../../assets/images/en.svg" /><span class="yuYan"
                  >English</span
                >
              </el-dropdown-item>
              <el-dropdown-item command="fr">
                <img src="./../../assets/images/fr.svg" /><span class="yuYan"
                  >Français</span
                >
              </el-dropdown-item>
              <el-dropdown-item command="ru">
                <img src="./../../assets/images/ru.svg" /><span class="yuYan"
                  >Pусский</span
                >
              </el-dropdown-item>
              <el-dropdown-item command="es"
                ><img src="./../../assets/images/es.svg" /><span class="yuYan"
                  >Español</span
                >
              </el-dropdown-item>

              <el-dropdown-item command="ar">
                <img src="./../../assets/images/ar.svg" /><span class="yuYan"
                  >عربى</span
                >
              </el-dropdown-item>

              <el-dropdown-item command="id">
                <img src="./../../assets/images/id.svg" />
                <span class="yuYan">Indonesia</span>
              </el-dropdown-item>
              <el-dropdown-item command="tr">
                <img src="./../../assets/images/tr.svg" /><span class="yuYan"
                  >турецкий</span
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="smallSearchBc">
      <div class="smallSearch">
        <el-input
          suffix-icon="el-icon-search"
          v-model="form.goodsBrand"
          class="titmenu2_iput"
          @change="search1"
        >
        </el-input>
      </div>
    </div>
    <!-- <div class="mailbox1" @click="abc()">
      <span>
        <img src="./../../assets/images/email.png" />
      </span>
    </div> -->
    <el-popover placement="top" trigger="click" v-model="visible">
      <div class="wechat-con">
        <div class="wechatred"></div>
        <div class="wechatbai"></div>
        <div class="erweima">
          <div class="logo1">
            <img src="./../../assets/images/logo.png" style="width: 90px" />
          </div>
          <div class="Contact">Contact Us On Whatsapp</div>
          <div class="tel">+86 138 1077 7318</div>
          <div class="Contact">or Scan Code below</div>
          <div class="saoyisao">
            <img
              src="./../../assets/images/erweima.png"
              alt=""
              style="width: 80px"
            />
          </div>
        </div>
      </div>

      <!-- <div slot="reference" class="btn">Call Us</div> -->
      <div slot="reference" class="mailbox1" @click="abc()">
        <span>
          <img src="./../../assets/images/email.png" />
        </span>
      </div>
    </el-popover>
  </div>
</template>
<script>
import "../../assets/css/nationalFlag.css";
import { search } from "../../api/api";

export default {
  data() {
    return {
      text: "", //存储查询条件
      form: {
        goodsBrand: "", //搜索条件
      },
      imgsrc: "",
      list: [],
      arr: [], //最终数据
      lang: "", //语言
      visible: false,
    };
  },
  mounted() {
    this.imgsrc = require("./../../assets/images/" +
      this.$i18n.locale +
      ".svg");
    //页面一进入获取默认语言

    if (window.sessionStorage.getItem("lang") == null) {
      window.sessionStorage.setItem("lang", "en");
    }
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      console.log(this.lang);
    });
  },
  methods: {
    abc() {
      console.log(123);
    },
    gocontactUs() {
      this.$router.push("/contactUs");
    },
    gocontactUs() {
      this.$router.push("/contactUs");
      // href="mailto:wendy.liu@mizztech.com"
    },
    //搜索
    search1() {
      if (this.form.goodsBrand == "") {
        // this.$message({
        //   message: this.$t('perfeccionarMessage'),
        //   type: 'warning'
        // });
      } else if (this.form.goodsBrand == undefined) {
        // this.$message({
        //   message: this.$t('perfeccionarMessage'),
        //   type: 'warning'
        // });
      } else if (this.lang == null) {
        let items = {
          goodsNameen: this.form.goodsBrand,
        };
        let arr = [];
        search(items).then((res) => {
          this.list = res.data.data.records;
          this.list.forEach((item) => {
            arr.push({
              goodsImg: this.$global.imgUrl + item.goodsImg,
              goodsId: item.goodsId,
              goodsName: item.goodsNameen,
            });
          });
          this.$router.push({
            path: "/Products",
            query: {
              arr: JSON.stringify(arr),
            },
          });
        });
      } else if (this.lang == "zh") {
        let items = {
          goodsNamezh: this.form.goodsBrand,
        };
        let arr = [];
        search(items).then((res) => {
          this.list = res.data.data.records;
          this.list.forEach((item) => {
            arr.push({
              goodsImg: this.$global.imgUrl + item.goodsImg,
              goodsId: item.goodsId,
              goodsName: item.goodsNamezh,
            });
          });
          this.$router.push({
            path: "/Products",
            query: {
              arr: JSON.stringify(arr),
            },
          });
        });
      } else if (this.lang == "en") {
        let items = {
          goodsNameen: this.form.goodsBrand,
        };
        let arr = [];
        search(items).then((res) => {
          this.list = res.data.data.records;
          this.list.forEach((item) => {
            arr.push({
              goodsImg: this.$global.imgUrl + item.goodsImg,
              goodsId: item.goodsId,
              goodsName: item.goodsNameen,
            });
          });
          this.$router.push({
            path: "/Products",
            query: {
              arr: JSON.stringify(arr),
            },
          });
        });
      } else if (this.lang == "fr") {
        let items = {
          goodsNamefr: this.form.goodsBrand,
        };
        let arr = [];
        search(items).then((res) => {
          this.list = res.data.data.records;
          this.list.forEach((item) => {
            arr.push({
              goodsImg: this.$global.imgUrl + item.goodsImg,
              goodsId: item.goodsId,
              goodsName: item.goodsNamefr,
            });
          });
          this.$router.push({
            path: "/Products",
            query: {
              arr: JSON.stringify(arr),
            },
          });
        });
      } else if (this.lang == "ru") {
        let items = {
          goodsNameru: this.form.goodsBrand,
        };
        let arr = [];
        search(items).then((res) => {
          this.list = res.data.data.records;
          this.list.forEach((item) => {
            arr.push({
              goodsImg: this.$global.imgUrl + item.goodsImg,
              goodsId: item.goodsId,
              goodsName: item.goodsNameru,
            });
          });
          this.$router.push({
            path: "/Products",
            query: {
              arr: JSON.stringify(arr),
            },
          });
        });
      } else if (this.lang == "ar") {
        let items = {
          goodsNamear: this.form.goodsBrand,
        };
        let arr = [];
        search(items).then((res) => {
          this.list = res.data.data.records;
          this.list.forEach((item) => {
            arr.push({
              goodsImg: this.$global.imgUrl + item.goodsImg,
              goodsId: item.goodsId,
              goodsName: item.goodsNamear,
            });
          });
          this.$router.push({
            path: "/Products",
            query: {
              arr: JSON.stringify(arr),
            },
          });
        });
      } else if (this.lang == "es") {
        let items = {
          goodsNamees: this.form.goodsBrand,
        };
        let arr = [];
        search(items).then((res) => {
          this.list = res.data.data.records;
          this.list.forEach((item) => {
            arr.push({
              goodsImg: this.$global.imgUrl + item.goodsImg,
              goodsId: item.goodsId,
              goodsName: item.goodsNamees,
            });
          });
          this.$router.push({
            path: "/Products",
            query: {
              arr: JSON.stringify(arr),
            },
          });
        });
      } else if (this.lang == "id") {
        let items = {
          goodsNameid: this.form.goodsBrand,
        };
        let arr = [];
        search(items).then((res) => {
          this.list = res.data.data.records;
          this.list.forEach((item) => {
            arr.push({
              goodsImg: this.$global.imgUrl + item.goodsImg,
              goodsId: item.goodsId,
              goodsName: item.goodsNameid,
            });
          });
          this.$router.push({
            path: "/Products",
            query: {
              arr: JSON.stringify(arr),
            },
          });
        });
      } else if (this.lang == "tr") {
        let items = {
          goodsNametr: this.form.goodsBrand,
        };
        let arr = [];
        search(items).then((res) => {
          this.list = res.data.data.records;
          this.list.forEach((item) => {
            arr.push({
              goodsImg: this.$global.imgUrl + item.goodsImg,
              goodsId: item.goodsId,
              goodsName: item.goodsNametr,
            });
          });
          this.$router.push({
            path: "/Products",
            query: {
              arr: JSON.stringify(arr),
            },
          });
        });
      }
      //调用搜索接口
      // search(this.form).then((res) => {
      //   // console.log(res.data.data,'abc')
      //   if (res.status == 200) {
      //     this.list = res.data.data.records;
      //     console.log(res.data, "res.data");
      //     this.text = this.form.goodsBrand;
      //     delete this.form.goodsBrand;
      //     this.form.goodsVision = this.text;
      //     // console.log(this.form)
      //     // debugger
      //     var that = this;
      //     that.list.forEach((item) => {
      //       if (this.lang == null) {
      //         this.arr.push({
      //           goodsImg: this.$global.imgUrl + item.goodsImg,
      //           goodsId: item.goodsId,
      //           goodsName: item.goodsNameen,
      //         });
      //         // console.log(this.list)
      //       } else if (this.lang === "zh") {
      //         //中文
      //         this.arr.push({
      //           goodsImg: this.$global.imgUrl + item.goodsImg,
      //           goodsId: item.goodsId,
      //           goodsName: item.goodsNamezh,
      //         });
      //         // console.log(this.list)
      //       } else if (this.lang === "en") {
      //         //英文
      //         // debugger
      //         this.arr.push({
      //           goodsImg: this.$global.imgUrl + item.goodsImg,
      //           goodsId: item.goodsId,
      //           goodsName: item.goodsNameen,
      //         });
      //         // console.log(this.list)
      //       } else if (this.lang === "fr") {
      //         //法语
      //         this.arr.push({
      //           goodsImg: this.$global.imgUrl + item.goodsImg,
      //           goodsId: item.goodsId,
      //           goodsName: item.goodsNamefr,
      //         });
      //         // console.log(this.list)
      //       } else if (this.lang === "ru") {
      //         //俄语
      //         this.arr.push({
      //           goodsImg: this.$global.imgUrl + item.goodsImg,
      //           goodsId: item.goodsId,
      //           goodsName: item.goodsNameru,
      //         });
      //         // console.log(this.list)
      //       } else if (this.lang === "es") {
      //         //西班牙语
      //         this.arr.push({
      //           goodsImg: this.$global.imgUrl + item.goodsImg,
      //           goodsId: item.goodsId,
      //           goodsName: item.goodsNamees,
      //         });
      //         // console.log(this.list)
      //       } else if (this.lang === "ar") {
      //         //阿拉伯语
      //         this.arr.push({
      //           goodsImg: this.$global.imgUrl + item.goodsImg,
      //           goodsId: item.goodsId,
      //           goodsName: item.goodsNamear,
      //         });
      //         // console.log(this.list)
      //       } else if (this.lang === "id") {
      //         //印尼语
      //         this.arr.push({
      //           goodsImg: this.$global.imgUrl + item.goodsImg,
      //           goodsId: item.goodsId,
      //           goodsName: item.goodsNameid,
      //         });
      //         // console.log(this.list)
      //       } else if (this.lang === "tr") {
      //         //土耳其语
      //         this.arr.push({
      //           goodsImg: this.$global.imgUrl + item.goodsImg,
      //           goodsId: item.goodsId,
      //           goodsName: item.goodsNametr,
      //         });
      //         // console.log(this.list)
      //       }
      //     });
      //     this.$router.push({
      //       path: "/Products",
      //       query: {
      //         arr: JSON.stringify(this.arr),
      //       },
      //     });
      //     // search(this.form).then(res=>{
      //     //   // console.log(res)
      //     //   if(res.status==200){
      //     //     // that.list=that.list.concat(res.data.data.records)
      //     //     console.log(that.list,'that.listthat.list')
      //     //     that.list.forEach(item=>{
      //     //       if(this.lang==null){
      //     //         this.arr.push(
      //     //                 {
      //     //                   goodsImg: this.$global.imgUrl+item.goodsImg,
      //     //                   goodsId:item.goodsId,
      //     //                   goodsName:item.goodsNameen,
      //     //                 }
      //     //         )
      //     //         // console.log(this.list)
      //     //       }else if(this.lang==='zh'){ //中文
      //     //         this.arr.push(
      //     //                 {
      //     //                   goodsImg: this.$global.imgUrl+item.goodsImg,
      //     //                   goodsId:item.goodsId,
      //     //                   goodsName:item.goodsNamezh,
      //     //                 }
      //     //         )
      //     //         // console.log(this.list)
      //     //       }else if(this.lang==='en'){ //英文
      //     //         // debugger
      //     //         this.arr.push(
      //     //                 {
      //     //                   goodsImg: this.$global.imgUrl+item.goodsImg,
      //     //                   goodsId:item.goodsId,
      //     //                   goodsName:item.goodsNameen,
      //     //                 }
      //     //         )
      //     //         // console.log(this.list)
      //     //       }else if(this.lang==='fr'){ //法语
      //     //         this.arr.push(
      //     //                 {
      //     //                   goodsImg: this.$global.imgUrl+item.goodsImg,
      //     //                   goodsId:item.goodsId,
      //     //                   goodsName:item.goodsNamefr,
      //     //                 }
      //     //         )
      //     //         // console.log(this.list)
      //     //       }else if(this.lang==='ru'){ //俄语
      //     //         this.arr.push(
      //     //                 {
      //     //                   goodsImg: this.$global.imgUrl+item.goodsImg,
      //     //                   goodsId:item.goodsId,
      //     //                   goodsName:item.goodsNameru,
      //     //                 }
      //     //         )
      //     //         // console.log(this.list)
      //     //       }else if(this.lang==='es') {//西班牙语
      //     //         this.arr.push(
      //     //                 {
      //     //                   goodsImg: this.$global.imgUrl+item.goodsImg,
      //     //                   goodsId:item.goodsId,
      //     //                   goodsName:item.goodsNamees,
      //     //                 }
      //     //         )
      //     //         // console.log(this.list)
      //     //       }else if(this.lang==='ar') {//阿拉伯语
      //     //         this.arr.push(
      //     //                 {
      //     //                   goodsImg: this.$global.imgUrl+item.goodsImg,
      //     //                   goodsId:item.goodsId,
      //     //                   goodsName:item.goodsNamear,
      //     //                 }
      //     //         )
      //     //         // console.log(this.list)
      //     //       }else if(this.lang==='id') {//印尼语
      //     //         this.arr.push(
      //     //                 {
      //     //                   goodsImg: this.$global.imgUrl+item.goodsImg,
      //     //                   goodsId:item.goodsId,
      //     //                   goodsName:item.goodsNameid,
      //     //                 }
      //     //         )
      //     //         // console.log(this.list)
      //     //       }else if(this.lang==='tr') {//土耳其语
      //     //         this.arr.push(
      //     //                 {
      //     //                   goodsImg: this.$global.imgUrl+item.goodsImg,
      //     //                   goodsId:item.goodsId,
      //     //                   goodsName:item.goodsNametr,
      //     //                 }
      //     //         )
      //     //         // console.log(this.list)
      //     //       }
      //     //     })
      //     //     this.$router.push({
      //     //       path:'/Products',
      //     //       query:{
      //     //         arr:JSON.stringify(this.arr)
      //     //       }
      //     //     })
      //     //   }
      //     // })
      //   }
      // });
      // }
    },
    handleLang(command) {
      // debugger
      if (command != window.sessionStorage.getItem("lang")) {
        this.$i18n.locale = command;
        this.imgsrc = require("./../../assets/images/" +
          this.$i18n.locale +
          ".svg");
        window.sessionStorage.setItem("lang", this.$i18n.locale);
        this.resetSetItem("lang", this.$i18n.locale);
      }
    },
    toHome() {
      this.$router.push("/Home");
    },
  },
};
</script>
<style scoped lang="less">
a {
  text-decoration: none; /* 去除默认的下划线 */

  color: #a9a9a9; /* 去除默认的颜色和点击后变化的颜色 */
}
.wechat-con {
  margin: 30px 50px;
  // margin-top: 400px;
  text-align: center;
  position: relative;
  width: 600px;
  .Contact {
    font-size: 40px;
  }
  .wechatred {
    margin-top: 30px;
    height: 600px;
    // width: 350px;
    background: #ce131c;
    border-radius: 30px 30px 0 0;
  }
  .wechatbai {
    height: 300px;
  }
  .erweima {
    position: absolute;
    margin: 30px;
    background-color: #fff;
    width: 530px;
    top: 0;
    border-radius: 25px;
    .tel {
      background: #23d366;
      background-image: url(../../assets/images/whatsapp.png);
      color: #fff;
      margin: 20px 45px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: 17px center;
      background-size: 30px;
      padding-left: 70px;
      font-size: 40px;
      text-align: left;
      height: 50px;
    }
    .saoyisao {
      margin-top: 50px;
    }
  }
}
.dropbtn1 {
  background-color: rgba(76, 175, 80, 0);
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  position: relative;
  left: 13.125rem;
  top: 3.75rem;
}
.dropdown1 {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 10rem;
  height: 96.625rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown1-content a:hover {
  background-color: #f1f1f1;
}

.dropdown1:hover .dropdown-content {
  display: block;
  padding: 20px;
  margin: 7.5rem 0 0 0 !important;
}

.dropdown1:hover .dropbtn1 {
  background-color: rgba(62, 142, 65, 0);
}
// 国旗
.ul-list {
  img {
    width: 44px;
    height: 44px;
    position: relative;
    top: 10px;
  }
  .el-dropdown-menu__item {
    span {
      font-size: 18px;
      /* margin: auto; */
      position: relative;
      top: -1px;
      left: 8px;
    }
  }
}
.slelect-lang {
  .Guoqi {
    width: 60px;
    height: 50px;
    vertical-align: middle;
  }
  // .selected-lang {
  //   img {
  //     width: 60px;
  //     height: 100px;
  //     position: relative;
  //     // right: 0px;
  //     top: -30px;
  //   }
  // }
}

.header {
  width: 100%;
  z-index: 80;
  border-spacing: 0;
  font-size: 14px;
  background: #fff;
  clear: both;
  -webkit-box-shadow: 0px 0px 5px 2px #ddd;
  -moz-box-shadow: 0px 0px 5px 2px #ddd;
  box-shadow: 0px 0px 5px 2px #ddd;
  top: 0;
  position: fixed;
  .titmenu2_iput {
    .el-input__inner {
      border-radius: 206px;
      border-color: #e41759;
    }
    .el-input__suffix {
      color: #e41759;
    }
  }
  //小屏样式
  @media (max-width: 766px) {
    height: 440px;
    // .smallsearch{
    //   width: 200px;
    //   height: 200px;
    //   background-color: pink;
    //   position: relative;
    //   top: 800px;
    // }
    .smallSearchBc {
      width: 100%;
      position: absolute;
      bottom: 0px;
      height: 200px;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      .smallSearch {
        width: 80%;
        /deep/ .el-input__inner {
          font-size: 50px;
          padding-left: 70px;
          height: 100px;
          border-radius: 20px;
          border: 0.5px solid #d0111b;
        }
        /deep/.el-input__suffix {
          right: 50px;
          top: 28px;
        }
        /deep/.el-input__icon {
          font-size: 55px;
        }
      }
    }
    .mailbox1 {
      float: right;
      // background-color: red;
      // height: 150px;
      margin-top: -55px;
      margin-right: 210px;
      img {
        height: 100px;
        width: 100px;
      }
    }
    .slelect-lang {
      padding: 30px 0 160px 0;
      width: 100px;
      height: 10px;
      .selected-lang {
        img {
          width: 80px;
          height: 100px;
          position: relative;
          right: 10px;
          top: -30px;
        }
      }
    }
    ul span {
      display: none;
    }
    .el-icon-caret-bottom {
      width: 100px;
      height: 100px;
    }
    .titmenu {
      margin: 0 40px;
      width: calc(100% - 160px);
      display: flex;
      justify-content: space-between;
      .suoxiaoguoqi {
        position: relative;
        left: 15rem;
        top: 4.2rem;
      }

      .titmenu0 {
        width: 588px;
        .lafs {
          display: none;
        }
        #lafs {
          img {
            width: 100px;
            position: relative;
            left: 1500px;
            top: 75px;
          }
        }
      }
      .titmenu1 {
        width: 259px;
        text-align: center;
        img {
          height: 120px;
          position: relative;
          top: 55px;
          left: 50px;
        }
        span {
          display: none;
        }
      }
      .titmenu2 {
        width: 616px;
        display: flex;
        justify-content: space-between;
        .search {
          display: none;
        }
        .lang {
          display: none;
        }
        .el-icon-arrow-down {
          position: relative;
          top: -55px;
          left: -10px;
        }
        i {
          font-size: 6rem;
        }
      }
      .el-icon-caret-bottom {
        font-size: 2040px;
        color: #e41759;
        position: relative;
        top: -55px;
      }
    }
  }
  //大屏样式
  @media screen and (min-width: 768px) {
    .mailbox1 {
      display: none;
    }
    .smallSearch {
      display: none;
    }
    height: 89px;
    .el-icon-caret-bottom {
      font-size: 24px;
      color: #e41759;
    }
    #lafs {
      display: none;
    }
    .titmenu {
      margin: 0 80px;
      width: calc(100% - 160px);
      display: flex;
      justify-content: space-between;

      .titmenu0 {
        width: 588px;
        .lafs {
          font-size: 22px;
          color: #a9a9a9;
          height: 29px;
          margin-top: 29px;
          span {
            display: inline-block;
            cursor: pointer;
            height: 24px;
            line-height: 29px;
            text-align: left;
            width: 53%;
            &:last-child {
              width: 47%;
            }
            img {
              width: 24px;
              height: 24px;
              position: relative;
              padding-right: 10px;
              top: 5px;
            }
          }
        }
      }

      .titmenu1 {
        width: 259px;
        text-align: center;
        img {
          height: 82px;
          position: relative;
          top: 3px;
        }
      }

      .titmenu2 {
        width: 616px;
        display: flex;
        justify-content: space-between;
        .search {
          width: 448px;
          height: 48px;
          margin: 26px 0;
        }
        .lang {
          // text-align: right;
          // width: 76px;
          // height: 42px;
          // margin: 23px 0;
          height: 60px;
          margin-top: 20px;
        }
        .slelect-lang1 {
          display: none;
        }
      }
    }
  }
}
</style>
<style scoped>
</style>