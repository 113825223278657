<template>
  <div class="contsctUs">
    <Carousel :imgList="imgList" />
    <!-- 联系方式 -->
    <div class="tankuang">
      <ContactUs2 />
    </div>
    <div class="chatus">
      <div class="chatus-item">
        <div class="img">
          <img src="./../../assets/images/chatphone.png" alt="" />
        </div>
        <div class="title">{{ $t("Speakteam") }}</div>
        <div class="cont">
          {{ $t("experienced") }}
        </div>
        <div class="btn" @click="goAnchor('#vital')">{{ $t("CallUs") }}</div>
      </div>
      <div class="chatus-item">
        <div class="img">
          <img src="./../../assets/images/chatxx.png" alt="" />
        </div>
        <div class="title">Whatsapp</div>
        <div class="cont">
          {{ $t("online") }}
        </div>
        <!-- <el-popover placement="top" trigger="click">
          <div class="wechat-con">
            <div class="wechatred"></div>
            <div class="wechatbai"></div>
            <div class="erweima">
              <div class="logo1">
                <img src="./../../assets/images/logo.png" />
              </div>
              <div class="Contact">Contact Us On Whatsapp</div>
              <div class="tel">+86 189 3114 9515</div>
              <div class="Contact">or Scan Code below</div>
              <div class="saoyisao">
                <img src="./../../assets/images/erweima.png" alt="" />
              </div>
            </div>
          </div>
          <div slot="reference" class="btn">{{ $t("CallUs") }}</div>
        </el-popover> -->
        <el-popover placement="top" v-model="visible">
          <!-- <i class="el-icon-close" @click="visible = false"></i> -->
          <div class="PCwechat-con">
            <div class="PCwechatred"></div>
            <div class="wechatbai"></div>
            <div class="PCerweima">
              <div class="PClogo1">
                <img src="../../assets/images/logo.png" />
              </div>
              <div class="PCcontact">
                <span>{{ $t("contactTips") }}</span>
              </div>
              <div class="PCtel">+86 138 1077 7318</div>
              <div class="PCcontact">
                <span>{{ $t("codeTips") }}</span>
              </div>
              <div class="PCsaoyisao">
                <img src="../../assets/images/erweima.png" alt="" />
              </div>
            </div>
          </div>
          <div slot="reference" class="btn">{{ $t("CallUs") }}</div>
        </el-popover>
      </div>
      <div class="chatus-item">
        <div class="img">
          <img src="./../../assets/images/chatwh.png" alt="" />
        </div>
        <div class="title">{{ $t("Requirements") }}</div>
        <div class="cont">
          {{ $t("receive") }}
        </div>
        <div class="btn" type="danger" @click="Requirements = true">
          {{ $t("CallUs") }}
        </div>
      </div>
    </div>

    <!--人物明信片 -->
    <div class="information">
      <h2 id="vital">{{ $t("ContactUs") }}</h2>
      <h3>{{ $t("MoreInformation1") }}</h3>
      <Info />
    </div>

    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />

    <!-- Requirements 弹窗 -->
    <el-dialog
      :append-to-body="true"
      title=""
      :visible.sync="Requirements"
      class="contsctUsmodel"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="logo"></div>
      <div class="loginform">
        <el-form ref="form" :model="form" label-width="0" :rules="rules">
          <el-form-item prop="inquiryName">
            <el-input
              v-model="form.inquiryName"
              :placeholder="$t('name')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="inquiryCompany">
            <el-input
              v-model="form.inquiryCompany"
              :placeholder="$t('Compamy')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="inquiryEmail">
            <el-input
            value=“sales@mizztech.com”
              v-model="form.inquiryEmail"
              :placeholder="$t('Email')"
            >
          <span>Email</span></el-input>
          </el-form-item>
          <!--          <el-form-item>-->
          <!--            <el-input v-model="form.name" :placeholder="$t('Telephone')"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item prop="inquiryMemo">
            <el-input
              type="textarea"
              v-model="form.inquiryMemo"
              :rows="4"
              style="font-size: 18px"
              :placeholder="$t('Message')"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="danger" @click="callUs">{{
              $t("Send")
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";

// 人员列表
import Info from "./../../components/ContactUs/info.vue";

// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
//log
import ContactUs2 from "@/views/ContactUs/ContactUs2";

// 底部信息
import tFooter from "./../../components/footer/footer.vue";
import { xundan } from "../../api/api";
export default {
  components: {
    Company,
    tFooter,
    Info,
    Carousel,
    ContactUs2,
  },
  data() {
    return {
      // imgList: {
      //   url: img1,
      //   className: "fadeInUp animated textLeft",
      //   title: "Contact Us",
      //   xtitle: "Anytime，Anywhere. \n We're here when you need us.",
      // },
      Requirements: false,
      form: {
        inquiryName: "",
        inquiryCompany: "",
        inquiryEmail: "sales@mizztech.com",
        inquiryMemo: "",
      },
      rules: {
        inquiryName: [{ required: true, message: this.$t("Namelimit") }],
        inquiryEmail: [{ required: true, message: this.$t("perfeccionarMessage1") }],
        inquiryCompany: [
          {
            required: true,
            message: this.$t("gslimit"),
          },
        ],
        inquiryMemo: [{ required: true, message: this.$t("Msglimit") }],
      },
    };
  },
  methods: {
    //锚点跳转
    goAnchor(selector) {
      /*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
    //联系我们
    callUs() {
      var inquiryName = /^.{1,60}$/;
      var inquiryCompany = /^.{1,100}$/;
      var inquiryEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      var inquiryMemo = /^.{5,200}$/;

      if (!inquiryName.test(this.form.inquiryName)) {
        this.$message({
          message: this.$t("Namelimit"),
          type: "warning",
        });
      } else if (!inquiryCompany.test(this.form.inquiryCompany)) {
        this.$message({
          message: this.$t("gslimit"),
          type: "warning",
        });
      } else if (!inquiryEmail.test(this.form.inquiryEmail)) {
        this.$message({
          message: this.$t("perfeccionarMessage1"),
          type: "warning",
        });
      } else if (!inquiryMemo.test(this.form.inquiryMemo)) {
        this.$message({
          message: this.$t("Msglimit"),
          type: "warning",
        });
      } else {
        //没登录先登录判断
        let token = localStorage.getItem("token");
        if (token == null) {
          this.$message({
            message: this.$t("loginTips"),
            type: "success",
          });
        } else {
          //调询单接口
          xundan(this.form).then((res) => {
            
            if (res.status == 200) {
              this.$message({
                message: res.data.errMsg,
                type: "success",
              });
              this.Requirements = false; //关闭弹框
            }
          });
        }
      }
    },
  },
  computed: {
    imgList() {
      return {
        url: window.g.contactUsImg,
        className: "fadeInUp animated textLeft",
        title: this.$t("ContactUs"),
        xtitle: this.$t("Anytime"),
      };
    },
  },
};
</script>

<style lang="less">
.contsctUsmodel {
  .el-dialog {
    width: 834px;
    // height: 594px;
    padding-bottom: 50px;
    padding-top: 40px;
    .loginform {
      margin: 33px 89px 0 94px;
    }
    .el-button {
      width: 100%;
    }
    .GetValicode {
      width: 230px;
      float: right;
    }
    .GetValicodeinput {
      width: 278px;
    }
    .wjmm {
      float: right;
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
    }
  }
}
.contsctUs {
  @media screen and (max-width: 766px) {
    .Carousel {
      .el-carousel__container1 {
        position: relative;
        height: 100%;

        .textLeft {
          text-align: left;
        }

        h2 {
          font-size: 60px;
          font-weight: normal;
          color: #fff;
          position: absolute;
          top: 269px;
          left: 10%;
          // padding-bottom: 54px;
          // border-bottom: 2px solid #fff;
        }

        p {
          font-weight: normal;

          font-size: 32px;
          color: #fff;
          position: absolute;
          top: 374px;
          text-align: center;
          width: 80%;
          left: 10%;
          // height: 47px;
          line-height: 70px;
          // font-weight: bold;
          // white-space: pre;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .chatusn {
      margin: 82px 150px;
      display: flex;
      justify-content: space-between;

      .chatus-item {
        // width: 520px;
        // height: 587px;
        background: #fff;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
        border: 1px solid #ffffff;
        border-radius: 30px;
        opacity: 1;

        .img {
          width: 83px;
          height: 77px;
          margin: 84px 218px 54px 219px;

          img {
            width: 83px;
            height: 77px;
          }
        }

        .title {
          height: 37px;
          font-size: 40px;
          color: #000;
          text-align: center;
        }
        // .wechat-con {
        //   /deep/.Contact {
        //     font-size: 45px;
        //     color: #d0111d;
        //   }
        // }
        .cont {
          // line-height: 46px;
          font-size: 32px;
          text-align: center;
          margin: 50px 50px;
          color: #999;
          // height: 151px;
        }

        .btn {
          // height: 73px;
          // width: 206px;
          border-radius: 8px;
          // border: 1px solid #707070;
          font-size: 35px;
          padding: 10px;
          color: #d3323c;
          background: #dedcdd;
          margin: 0 140px;
          text-align: center;
          // cursor: pointer;
          &:hover {
            background: #d0111d;
            color: #fff;
          }
        }
      }
    }
    .information {
      margin: 75px 150px;
      h2 {
        height: 64px;
        font-size: 12rem;
        text-align: left;
      }
      .borderred {
        width: 160px;
        height: 2px;
        background: #d11d26;
        margin-bottom: 60px;
      }
      h3 {
        height: 64px;
        font-size: 48px;
        text-align: left;
        font-weight: 500;
      }

      .information_info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .info_con {
          width: 481px;
          height: 715px;
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
          border: 1px solid #ccc;
          text-align: center;
          opacity: 1;
          .pv0 {
            width: 481px;
            height: 379px;
            img {
              width: 481px;
              height: 379px;
            }
          }
          .pv1 {
            font-size: 24px;
            color: #666;
            font-weight: bold;
            margin-top: 27px;
            height: 31px;
          }
          .pv2 {
            margin-top: 16px;
            font-size: 18px;
            color: #999;
            height: 24px;
          }
          .pv3 {
            padding: 13px 5px 0 5px;
            font-size: 16px;
            color: #999;
            line-height: 22px;
            height: 106px;
          }
          .pv4 {
            margin-top: 13px;
            font-size: 20px;
            color: #333;
            height: 26px;
          }
        }
      }
    }
    .chatus {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    .loginform {
      .el-textarea {
        font-size: 70px;
        .el-textarea__inner {
          font-weight: 600;
        }
      }
    }
    .Carousel {
      .el-carousel__container1 {
        position: relative;
        height: 500px;
        .textLeft {
          text-align: left;
        }

        h2 {
          font-size: 48px;
          color: #fff;
          position: absolute;
          top: 150px;
          left: 10%;
          // padding-bottom: 54px;
          // border-bottom: 2px solid #fff;
        }

        p {
          font-size: 32px;
          color: #fff;
          position: absolute;
          top: 230px;
          text-align: center;
          width: 80%;
          left: 10%;
          line-height: 48px;
          font-weight: bold;
          white-space: pre;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .chatus {
      margin: 82px 150px;
      display: flex;
      justify-content: space-between;

      .chatus-item {
        width: 520px;
        height: 587px;
        background: #fff;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
        border: 1px solid #ffffff;
        border-radius: 30px;
        opacity: 1;

        .img {
          width: 83px;
          height: 77px;
          margin: 84px 218px 54px 219px;

          img {
            width: 83px;
            height: 77px;
          }
        }

        .title {
          height: 37px;
          font-size: 28px;
          color: #000;
          text-align: center;
        }

        .cont {
          line-height: 46px;
          font-size: 24px;
          text-align: center;
          margin: 31px 50px;
          color: #999;
          height: 151px;
        }

        .btn {
          height: 73px;
          width: 206px;
          border-radius: 8px;
          // border: 1px solid #707070;
          font-size: 28px;
          line-height: 73px;
          color: #d3323c;
          background: #dedcdd;
          margin: 5px auto;
          text-align: center;
          cursor: pointer;

          &:hover {
            background: #d0111d;
            color: #fff;
          }
        }
      }
    }
    .information {
      margin: 75px 150px;
      h2 {
        height: 64px;
        font-size: 48px;
        text-align: left;
      }
      .borderred {
        width: 160px;
        height: 2px;
        background: #d11d26;
        margin-bottom: 60px;
      }
      h3 {
        height: 64px;
        font-size: 48px;
        text-align: left;
        font-weight: 500;
      }

      .information_info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .info_con {
          width: 481px;
          // height: 715px;
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
          border: 1px solid #ccc;
          text-align: center;
          opacity: 1;
          .pv0 {
            width: 481px;
            // height: 379px;
            img {
              width: 481px;
              height: 379px;
            }
          }
          .pv1 {
            font-size: 24px;
            color: #666;
            font-weight: bold;
            margin-top: 27px;
            height: 31px;
          }
          .pv2 {
            margin-top: 16px;
            font-size: 18px;
            color: #999;
            // height: 24px;
          }
          .pv3 {
            padding: 13px 5px 0 5px;
            font-size: 16px;
            color: #999;
            margin: 10px 25px;
            // line-height: 22px;
            // height: 106px;
          }
          .pv4 {
            margin: 40px;
            font-size: 20px;
            color: #333;
            // height: 26px;
          }
        }
      }
    }
    .tankuang {
      display: none;
    }
  }
}
</style>

