//在这里我们对axios做一层封装(axios的二次封装)
//1.添加统一的请求配置
//2.添加请求拦截器
//3.添加响应拦截器
//导入axios
import axios from "axios";
//引入语言文件
// import i18n from '../assets/language/index'
// import qs from "qs"; //node内置的模块
//生成Axios的伪实例，instance不是真正new出来的，但是拥有axios实例的所有属性和方法
const instance = axios.create({
    //做统一的配置
    timeout: 21000,
    //配置了通用的请求头
    // headers: { "X-Custom-Header": "foobar" },
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
});
//为实例添加请求拦截器和实例拦截器
// 添加请求拦截器 将请求拦截下来，对当前的请求批量处理，如：添加token，修改请求的参数
instance.interceptors.request.use(
    function(config) {
        // 在发送请求之前做些什么
        //1.在登录成功之后，后端可能会返回给我们一个token，在这里给所有的接口添加token接口
        //2.接口有缓存的话，在这里给我们添加事件戳
        // console.log(config);
        config.headers.token=localStorage.getItem("token")||"token"
        // var lang = window.sessionStorage.getItem('lang');
        // config.headers.append('Accept-Language',lang)
        //修改post请求参数格式：默认是json对象格式{a:xxx,b:yyy},当前服务器能处理的是url-encoding，如a=xxx&b=yyy
        // config.data=qs.stringify(config.data)
        return config;
    },
    function(error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    function(response) {
        // 对响应数据做点什么
        //1.通用的错误处理
        console.log(response);
        if (response.status === 200) {
            return response.data;
        }else{
            return response
        }
    },
    function(error) {
        // 对响应错误做点什么
        // alert("接口报错"+error.message)
        return Promise.reject(error);
    }
);
export default instance;

export function request(config) {
    const token = localStorage.getItem("token")||"token"

    let header = {
        token
    };

    if (config&&config.header) {
        // console.log("对象合并前",header)
        header = {
            ...header,
            //这里下面的属性没把上面的属性值覆盖掉
            ...config.header
        }
        // console.log("对象合并后",header)
    }
    return axios({
            method:config.method||'get',//不传也给个默认值
            url: config.url,
            data: config.data,
            headers:header

        })

}

export function get(url, config = {}) {
    return request({
        url,
        method: 'get',
        ...config
    })
}

export function post(url, body, config = {}) {
    return request({
        url,
        method: 'post',
        data: body,
        ...config
    })
}
