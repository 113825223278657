<!--浮标组件-->
<template>
  <div>
    <el-popover placement="top-end" v-model="visible">
      <!-- <i class="el-icon-close" @click="visible = false"></i> -->
      <div class="PCwechat-con">
        <div class="PCwechatred"></div>
        <div class="wechatbai"></div>
        <div class="PCerweima">
          <div class="PClogo1">
            <img src="../../assets/images/logo.png" />
          </div>
          <div class="PCcontact">
            <span>{{ $t("contactTips") }}</span>
          </div>
          <div class="PCtel">+86 138 1077 7318</div>
          <div class="PCcontact">
            <span>{{ $t("codeTips") }}</span>
          </div>
          <div class="PCsaoyisao">
            <img src="../../assets/images/erweima.png" alt="" />
          </div>
        </div>
      </div>
      <span slot="reference" class="wechat" @click="!visible"></span>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "buoy",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    showChange() {
      console.log(2);
    },
  },
};
</script>

<style lang="less">
@media screen and (min-width: 768px) {
  // .el-icon-close {
  //   float: right;
  //   margin-right: 30px;
  //   margin-top: 30px;
  // }
  .wechat {
    position: absolute;
    width: 97px;
    height: 97px;
    right: 91px;
    bottom: 28px;
    cursor: pointer;
    z-index: 10001;
    background: url(../../assets/images/product.png) no-repeat;
    background-size: contain;
  }

  .PCwechat-con {
    // width: 250px;
    // height: 400px;
    // padding: 50px;
    border-radius: 30px;
    position: relative;
    .PCwechatred {
      height: 300px;
      width: 400px;
      background: #ce131c;
      border-radius: 30px 30px 0 0;
    }

    .wechatbai {
      height: 200px;
    }

    .PCerweima {
      position: absolute;
      width: 350px;
      height: 450px;
      top: 30px;
      left: 25px;
      border-radius: 30px;
      background: #fff;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.16) !important;
      .PClogo1 {
        margin-top: 20px;
        text-align: center;
        img {
          height: 50px;
        }
      }
      .PCcontact {
        text-align: center;
        font-size: 20px;
        color: #000;
      }

      .PCtel {
        // width: 150px;
        // height: 40px;
        background: #23d366;
        color: #fff;
        margin: 20px 20px;
        border-radius: 10px;
        background-image: url(../../assets/images/whatsapp.png);
        background-repeat: no-repeat;
        background-position: 17px center;
        background-size: 26px 26px;
        padding-left: 59px;
        font-size: 18px;
        // text-align: left;
        // line-height: 43px;
      }
      .PCsaoyisao {
        margin-left: 90px;
        img {
          width: 170px;
          height: 170px;
        }
      }
    }
  }
}
</style>
