<template>
  <div class="successfulCase contsctUs">
    <Carousel :imgList="imgList" />
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{
          $t("Home")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item
          @click.native="handleClcik"
          style="cursor: pointer"
          >{{ $t("SuccessfulCases") }}</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="caseList.length == 1">
          {{ caseList[0].casesName }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 新闻页面列表 -->
    <div class="CompanyNews">
      <div class="Newsuls">
        <div class="Newslis" v-for="obj in caseList" :key="obj.casesId">
          <div class="date" v-show="!obj.isShow">
            <p>{{ obj.casesPtime.slice(-2) }}</p>
            <p>{{ obj.casesPtime.slice(0, 7) }}</p>
          </div>
          <div class="con" v-show="!obj.isShow" @click="obj.isShow = true">
            <h3>
              {{ obj.casesName | casesName }}
            </h3>
            <div class="borderde"></div>
            <p>
              {{ obj.casesDescription | casesDescription }}
            </p>
          </div>
          <div class="content" v-show="obj.isShow">
            <h3>
              {{ obj.casesName }}
            </h3>
            <div class="contText" v-html="obj.aboutData"></div>
            <span class="btn" @click="obj.isShow = false" v-show="CloseBtn">{{
              $t("close")
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";
import img1 from "./../../assets/images/banner1.jpg";
import { getcase } from "../../api/api";
//引入moment函数
import moment from "moment";
export default {
  name: "About",
  components: {
    Company,
    tFooter,
    Carousel,
  },
  props: {},
  data() {
    return {
      aboutData: {}, //处理富文本数据
      lang: "", //获取语言
      obj: {}, //案例对象
      params: {
        casesId: "",
      },
      caseList: [],
      CloseBtn: false,
    };
  },
  filters: {
    casesName(val) {
      if (val.length > 50) {
        val = val.slice(0, 50) + "...";
      }
      return val;
    },

    casesDescription(val) {
      if (val.length > 50) {
        val = val.slice(0, 100) + "...";
      }
      return val;
    },
  },
  methods: {
    handleClcik() {
      (this.CloseBtn = true),
        (this.params = {
          casesId: "",
        });
      // this.getcase(this.params);
      getcase(this.params).then((res) => {
        if (res.status == 200) {
          this.caseList = [];
          var list = res.data.records;
          list.forEach((item) => {
            var obj = {
              isShow: false,
            };
            if (this.lang == null) {
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNameen;
              // this.obj.casesMemo=item.casesMemoen
              obj.aboutData = item.casesMemoen.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionen;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "zh") {
              //中文
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNamezh;
              // this.obj.casesMemo=item.casesMemozh
              obj.aboutData = item.casesMemozh.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionzh;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
              console.log(obj);
            } else if (this.lang === "en") {
              //英文
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNameen;
              // this.obj.casesMemo=item.casesMemoen
              obj.aboutData = item.casesMemoen.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionen;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "fr") {
              //法语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNamefr;
              // this.obj.casesMemo=item.casesMemofr
              obj.aboutData = item.casesMemofr.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionfr;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "ru") {
              //俄语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNameru;
              // this.obj.casesMemo=item.casesMemoru
              obj.aboutData = item.casesMemoru.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionru;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "es") {
              //西班牙语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNamees;
              // this.obj.casesMemo=item.casesMemoes
              obj.aboutData = item.casesMemoes.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptiones;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "ar") {
              //阿拉伯语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNamear;
              // this.obj.casesMemo=item.casesMemoar
              obj.aboutData = item.casesMemoar.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionar;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "id") {
              //印尼语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNameid;
              // this.obj.casesMemo=item.casesMemoid
              obj.aboutData = item.casesMemoid.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionid;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "tr") {
              //土耳其语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNametr;
              // this.obj.casesMemo=item.casesMemotr
              obj.aboutData = item.casesMemotr.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptiontr;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            }
          });
        }
      });
    },
    //案例详情
    getcase(params) {
      getcase(params).then((res) => {
        if (res.status == 200) {
          this.caseList = [];
          var list = res.data.records;
          list.forEach((item) => {
            var obj = {
              isShow: true,
            };
            if (this.lang == null) {
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNameen;
              // this.obj.casesMemo=item.casesMemoen
              obj.aboutData = item.casesMemoen.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionen;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "zh") {
              //中文
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNamezh;
              // this.obj.casesMemo=item.casesMemozh
              obj.aboutData = item.casesMemozh.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionzh;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
              console.log(obj);
            } else if (this.lang === "en") {
              //英文
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNameen;
              // this.obj.casesMemo=item.casesMemoen
              obj.aboutData = item.casesMemoen.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionen;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "fr") {
              //法语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNamefr;
              // this.obj.casesMemo=item.casesMemofr
              obj.aboutData = item.casesMemofr.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionfr;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "ru") {
              //俄语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNameru;
              // this.obj.casesMemo=item.casesMemoru
              obj.aboutData = item.casesMemoru.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionru;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "es") {
              //西班牙语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNamees;
              // this.obj.casesMemo=item.casesMemoes
              obj.aboutData = item.casesMemoes.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptiones;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "ar") {
              //阿拉伯语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNamear;
              // this.obj.casesMemo=item.casesMemoar
              obj.aboutData = item.casesMemoar.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionar;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "id") {
              //印尼语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNameid;
              // this.obj.casesMemo=item.casesMemoid
              obj.aboutData = item.casesMemoid.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptionid;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            } else if (this.lang === "tr") {
              //土耳其语
              obj.casesMedia = this.$global.imgUrl + item.casesMedia;
              obj.casesId = item.casesId;
              obj.casesName = item.casesNametr;
              // this.obj.casesMemo=item.casesMemotr
              obj.aboutData = item.casesMemotr.replace(
                /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                (match, p1) => {
                  return `<img src='${
                    p1.indexOf("http") > -1 ? p1 : this.$global.imgUrl + p1
                  }' />`;
                }
              );
              obj.casesDescription = item.casesDescriptiontr;
              obj.casesPtime = moment(item.casesPtime).format("YYYY-MM-DD");
              this.caseList.push(obj);
            }
          });
        }
      });
    },
    toDetails() {
      this.$router.push("/newsdeail");
    },
  },
  computed: {
    imgList() {
      return {
        url: img1,
        className: "fadeInUp animated textLeft",
        title: this.$t("SuccessfulCases"),
        xtitle: "",
      };
    },
  },

  mounted() {
    this.params.casesId = this.$route.query.casesId;

    //调用案例详情
    this.getcase(this.params);
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      //语言改变 重新去请求
      this.getcase(this.params);
    });
  },
};
</script>

<style lang="less">
.successfulCase {
  .el-breadcrumb__item {
    float: none;
  }
  @media screen and (max-width: 766px) {
    .CompanyNews {
      margin: 0 156px;
      .Newsuls {
        .Newslis {
          margin: 150px 0;
          // height: 360px;
          display: flex;
          .date {
            font-size: 45px;
            width: 250px;
            height: 250px;
            background: #d3232d;
            color: #fff;
            text-align: center;
            p {
              margin-top: 30px;
              // margin: 29px 0 0 0;
              // width: 100%;
              // &:first-child {
              //   margin: 29px 0 0 0;
              //   font-size: 48px;
              //   text-align: center;
              //   height: 64px;
              //   line-height: 64px;
              // }
              // &:last-child {
              //   font-size: 24px;
              //   text-align: center;
              //   height: 31px;
              //   margin: 5px 0 0 0;
              //   line-height: 31px;
              // }
            }
          }
          .con {
            flex: 1;
            margin-left: 20px;
            cursor: pointer;
            h3 {
              font-size: 45px;
              color: #333;
              margin: 0;
              &:hover {
                color: #d3232d;
              }
            }
            .borderde {
              width: 100px;
              height: 3px;
              background: #d4232d;
              margin-top: 30px;
            }
            p {
              // margin: 30px 0 0 0;
              font-size: 28px;
              color: #666;
              margin-top: 24px;
              height: 80px;
              // line-height: 37px;
              &:hover {
                color: #d3232d;
              }
            }
          }
          .content {
            .contText {
              width: 1600px;
              // background-color: pink;
            }
            h3 {
              font-size: 70px;
              color: #333;
              margin: 40px 0;
              // height: 43px;
            }
            p {
              margin: 0;
              font-size: 35px;
              color: #666;
              margin-top: 30px;
              line-height: 60px;
            }
            img {
              height: 814px;
              width: 100%;
              margin: 60px 0;
            }
            .btn {
              display: block;
              text-align: center;
              background: #d01f27;
              border: 1px solid #000;
              border-radius: 5px;
              font-size: 50px;
              color: #fff;
              float: right;
              margin: 40px;
              padding: 10px 20px;
              cursor: pointer;
            }
          }
        }
      }
      .pagination {
        margin: 100px auto;
        text-align: center;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .CompanyNews {
      margin: 0 156px;
      .Newsuls {
        .Newslis {
          margin: 80px 0;
          // height: 360px;
          display: flex;
          .date {
            width: 157px;
            height: 167px;
            background: #d3232d;
            color: #fff;
            p {
              margin: 29px 0 0 0;
              width: 100%;
              &:first-child {
                margin: 29px 0 0 0;
                font-size: 48px;
                text-align: center;
                height: 64px;
                line-height: 64px;
              }
              &:last-child {
                font-size: 24px;
                text-align: center;
                height: 31px;
                margin: 5px 0 0 0;
                line-height: 31px;
              }
            }
          }
          .con {
            flex: 1;
            margin-left: 20px;
            cursor: pointer;
            h3 {
              font-size: 32px;
              color: #333;
              margin: 0;
              height: 43px;
              &:hover {
                color: #d3232d;
              }
            }
            .borderde {
              width: 50px;
              height: 3px;
              background: #d4232d;
              margin-top: 20px;
            }
            p {
              margin: 30px 0 0 0;
              font-size: 28px;
              color: #666;
              margin-top: 24px;
              height: 87px;
              line-height: 37px;
              &:hover {
                color: #d3232d;
              }
            }
          }
          .content {
            .contText {
              width: 1600px;
            }
            h3 {
              font-size: 42px;
              color: #333;
              margin: 0 0 70 0;
              height: 43px;
            }
            p {
              margin: 0;
              font-size: 28px;
              color: #666;
              margin-top: 24px;
              line-height: 46px;
            }
            img {
              height: 814px;
              width: 100%;
              margin: 60px 0;
            }
            .btn {
              display: block;
              text-align: center;
              width: 189px;
              height: 67px;
              background: #d01f27;
              border: 1px solid #000;
              border-radius: 5px;
              font-size: 32px;
              line-height: 67px;
              color: #fff;
              float: right;
              margin-top: 40px;
              cursor: pointer;
            }
          }
        }
      }
      .pagination {
        margin: 100px auto;
        text-align: center;
      }
    }
  }
}
</style>
