<template>
  <div class="mainAccessories">
    <div
      class="three0"
      @click="jumpDetail(index)"
      v-for="(item, index) in list"
      :key="index"
    >
      <img :src="item.goodsImg" />
      <div class="three1">{{ item.goodsVision }}</div>
      <div class="three2">{{ item.goodsName | namelen }}</div>
    </div>
  </div>
</template>
<script>
import { getProduct } from "../../../api/api";

export default {
  data() {
    return {
      lang: "", //获取语言
      params: {
        //参数
        current: 1,
        size: 3,
      },
      list: [], //产品数据
    };
  },
  filters: {
    namelen(val) {
      // if (val.length > 25) {
      //   val = val.slice(0, 15) + "...";
      // }
      return val;
    },
  },
  methods: {
    //获取商品列表
    getList() {
      getProduct(this.params).then((res) => {
        
        if (res.status == 200) {
          this.list.length = 0;
          // debugger
          if (res.data.records.length == 0) {
            this.nullTip = true;
          } else {
            this.nullTip = false;
          }
          res.data.records.forEach((item) => {
            if (this.lang == null) {
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameen,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "zh") {
              //中文
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamezh,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "en") {
              //英文
              // debugger
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameen,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "fr") {
              //法语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamefr,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "ru") {
              //俄语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameru,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "es") {
              //西班牙语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamees,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "ar") {
              //阿拉伯语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamear,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "id") {
              //印尼语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameid,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "tr") {
              //土耳其语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNametr,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            }
          });
        }
      });
    },
    jumpDetail(index) {
      console.log(index);
      console.log(this.list[index].goodsId);
      this.$router.push({
        path: "/ProductDetails",
        query: {
          id: this.list[index].goodsId,
        },
      });
    },
  },
  created() {
    //调用商品列表
    this.getList();
  },
  mounted() {
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      console.log(this.lang);
      //语言改变 重新去请求
      this.getList();
    });
  },
};
</script>
<style lang="less">
.mainAccessories {
  display: flex;
  flex-direction: column;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media screen and (max-width: 766px) {
    padding-top: 40px;
    height: 920px;
    border-bottom: 1px solid #ddd;
    margin: 0 110px;
    .three0 {
      width: 547px;
      position: relative;
      height: 420px;
      color: #999;
      img {
        margin-left: 10px;
        width: 520px;
        height: 520px;
      }
      .three1 {
        width: 100%;
        height: 80px;
        font-size: 70px;
        margin-top: 50px;
        text-align: center;
        overflow: hidden;
        color: #000;
      }
      .three2 {
        color: #989aa5;
        margin-top: 10px;
        width: 90%;
        height: 35px;
        font-size: 70px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  @media screen and (min-width: 768px) {
    padding-top: 40px;
    height: 620px;
    border-bottom: 1px solid #ddd;
    margin: 0 110px;
    .three0 {
      width: 547px;
      position: relative;
      height: 420px;
      color: #999;
      img {
        margin-left: 63.5px;
        width: 420px;
        height: 420px;
      }
      .three1 {
        width: 100%;
        height: 35px;
        font-size: 24px;
        margin-top: 20px;
        text-align: center;
        overflow: hidden;
        color: #000;
      }
      .three2 {
        color: #989aa5;
        margin-top: 10px;
        width: 90%;
        height: 35px;
        font-size: 19px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}
</style>
