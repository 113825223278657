<template>
  <div class="ourNews">
    <div class="mp promt">{{ $t("OurNews") }}</div>
    <div class="mp pro" id="mppro">
      <div
        class="prov"
        id="prov1"
        v-for="(item, index) in list"
        :key="index"
        @click="jumpDetail(index, list)"
      >
        <div class="prov0" id="prov11">
          <div class="prov00" id="prov011">
            <img
              style="transform: scale(1)"
              :src="item.articleMedia"
              :data-original="item.articleMedia"
            />
          </div>
          <div class="prov01">
            {{ item.articleTitle }}
          </div>
        </div>
        <div class="prov1">
          {{ item.articleResume }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { article1 } from "../../../api/api";
export default {
  data() {
    return {
      form: {
        current: 1,
        size: 4,
      }, //新闻参数
      list: [], //文章数据
      lang: "", //获取语言
    };
  },
  filters: {
    articleResume(val) {
      if (val.length > 110) {
        val = val.slice(0, 110) + "...";
      }
      return val;
    },
  },
  methods: {
    //跳详情
    jumpDetail(index, list) {
      let articleId = this.list[index].articleId;
      this.$router.push({
        path: "/newsdeail",
        query: {
          articleId: articleId,
          list: list,
          index: index,
          state: 1,
        },
      });
    },
    //获取文章
    getArticle() {
      this.list = [];
      console.log('11111');
      article1(this.form).then((res) => {
        if (res.status == 200) {
          // this.list.length = 0;
          res.data.records.forEach((item) => {
            if (this.lang == null) {
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitleen,
                articleResume: item.articleResumeen,
              });
            } else if (this.lang === "zh") {
              //中文
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitlezh,
                articleResume: item.articleResumezh,
              });
              // console.log(this.list)
            } else if (this.lang === "en") {
              //英文
              // debugger
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitleen,
                articleResume: item.articleResumeen,
              });
              // console.log(this.list)
            } else if (this.lang === "fr") {
              //法语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitlefr,
                articleResume: item.articleResumefr,
              });
              // console.log(this.list)
            } else if (this.lang === "ru") {
              //俄语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitleru,
                articleResume: item.articleResumeru,
              });
              // console.log(this.list)
            } else if (this.lang === "es") {
              //西班牙语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitlees,
                articleResume: item.articleResumees,
              });
              // console.log(this.list)
            } else if (this.lang === "ar") {
              //阿拉伯语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitlear,
                articleResume: item.articleResumear,
              });
              // console.log(this.list)
            } else if (this.lang === "id") {
              //印尼语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitleid,
                articleResume: item.articleResumeid,
              });
              // console.log(this.list)
            } else if (this.lang === "tr") {
              //土耳其语
              this.list.push({
                articleMedia: this.$global.imgUrl + item.articleMedia,
                articleId: item.articleId,
                articleTitle: item.articleTitletr,
                articleResume: item.articleResumetr,
              });
              // console.log(this.list)
            }
          });
        }
      });
    },
  },

  mounted() {
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      //语言改变 重新去请求
      this.getArticle();
    });
    this.getArticle();
  },
};
</script>
<style lang="less">
.ourNews {
  @media screen and (max-width: 766px) {
    // height: 730px;
    margin: 0;
    padding: 0;
    .promt {
      text-align: center;
      font-size: 8.375rem;
      height: 66px;
      line-height: 66px;
      padding: 40px 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    #mppro {
      font-size: 4rem;
      margin-top: 50px;
      margin-bottom: 80px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      background: #eee;
      // padding: 50px;
    }
    #prov1 {
      margin: 50px;
      padding: 50px;
      width: 800px;
      padding: 20px 20px 50px 20px;
      background-color: #fff;
    }
    .prov1 {
      margin-top: 50px;
      // background: #666;
    }
    .prov01 {
      color: #007aff;
    }
    #prov2 {
      width: 800px;
      border-style: solid;
      padding: 12px;
    }
    #prov3 {
      width: 800px;
      border-style: solid;
      padding: 12px;
    }

    #prov4 {
      width: 800px;
      border-style: solid;
      padding: 12px;
    }
  }
  @media screen and (min-width: 768px) {
    // height: 730px;
    margin: 0 110px;
    .promt {
      text-align: center;
      font-size: 38px;
      font-weight: 600;
      height: 66px;
      line-height: 66px;
      padding: 40px 0;
    }
    .pro {
      display: flex;
      justify-content: space-between;
      background: #eee;
      padding: 36px 0 40px 0;
      .prov {
        width: 400px;
        height: 582px;
        background: #fff;
        box-sizing: border-box;
        overflow: hidden;
        &:hover {
          cursor: pointer;
          // .prov1 {
          //   color: #007aff;
          // }
          .prov01 {
            color: #007aff;
          }
        }
        .prov0 {
          width: 100%;
          height: 300px;
          .prov00 {
            width: 100%;
            height: 170px;
            &:hover {
              transform: scale(1.1);
              transition: all 0.5s ease 0s;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .prov01 {
          // height: 66px;
          // line-height: 20px;
          margin: 26px;
          overflow: hidden;
          color: #666;
          font-size: 20px;
        }
        .prov1 {
          clear: both;
          margin: 0 26px;
          // line-height: 22px;
          color: #999;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
