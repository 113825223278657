<template>
  <div class="MaintenanceHlep contsctUs">
    <Carousel :imgList="imgList" />
    <div class="contents">
      <p>{{$t('Mizztechmaintenance')}}</p>
      <h3>·&nbsp;&nbsp;{{$t('Equipment')}}</h3>
      <h3>·&nbsp;&nbsp;{{$t('remoteGuidance')}}</h3>
      <h3>·&nbsp;&nbsp;{{$t('stock')}}</h3>
      <p>
        {{$t('facilitate')}}
      </p>
      <h3>·&nbsp;&nbsp;{{$t('warranty')}}</h3>
      <p>
       {{$t('newpart')}}
      </p>
      <h3>·&nbsp;&nbsp;{{$t('accountmanagers')}}</h3>
      <p>
    {{$t('kept')}}
      </p>
    </div>
    <div class="CommonProblem">
      <h2>·&nbsp;&nbsp;{{$t('CommonProblem')}}</h2>
      <div class="Problem">
        <el-collapse accordion>
          <el-collapse-item v-for="item in problemList" :key="item.title" :title="$t(item.title)">
            <div>
              <p >{{$t(item.des)}}</p>
              <p v-for = '(sitem,index) in item.answer' :key="index">
               {{$t(sitem)}}
              </p>
              </div>
           </el-collapse-item>
          <!-- <el-collapse-item>
            <template slot="title">{{$t('PumpOperation')}}</template>
            <div>
              <p>{{$t('Preparation')}}</p>
              1. {{$t('whether')}}
              <br />
              2. {{$t('exhaust')}}
            </div>
          </el-collapse-item>
          <el-collapse-item :title="$t('PumpMaintenance')"> </el-collapse-item>
          <el-collapse-item :title="$t('ProcessChangeImplications')">
          </el-collapse-item>
          <el-collapse-item
            :title="$t('characteristics')"
          >
          </el-collapse-item>
          <el-collapse-item :title="$t('properties')">
          </el-collapse-item>
          <el-collapse-item :title="$t('Extending')">
          </el-collapse-item> -->
        </el-collapse>
      </div>
    </div>
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";

export default {
  name: "About",
  components: {
    Company,
    tFooter,
    Carousel,
  },
  props: {},
  data() {
    return {
      // imgList: {
      //   url: img1,
      //   className: "fadeInUp animated textLeft",
      //   title: "Free Maintenance Hlep",
      //   xtitle:
      //     "Provide maintenance refurbishment \n services for customers' equipment",
      // },
      problemList:window.g.problemList
    };
  },
  methods: {},
  computed:{
    imgList(){
      return{
        url: window.g.helpImg,
        className: "fadeInUp animated textLeft",
        title: this.$t('FreeMaintenanceHelp'),
        xtitle: this.$t('maintenanceXtitle'),
      }
    }
  },
  created() {},
};
</script>

<style lang="less">
.MaintenanceHlep {
  @media screen and (max-width: 766px) {
    .contents {
      margin: 50px 156px;
      p {
        font-size: 50px;
        color: #333;
        line-height: 80px;
        margin: 20px 0;
      }
      h3 {
        color: #333;
        font-size: 100px;
        margin: 30px 0 0 0;
      }
    }
    .CommonProblem {
      h2 {
        font-size: 100px;
        height: 74px;
        margin: 0 0 50px 0;
      }
      margin: 0 156px;
      .Problem {
        margin-top: 90px;
        .el-collapse {
          border: 1px solid #000;
          border-radius: 20px;
          .el-collapse-item {
            .el-collapse-item__content {
              color: #333;
              line-height: 80px;
              font-size: 80px;
              margin: 21px 40px;
              padding: 0;
            }
            .el-collapse-item__header {
              border-bottom: 1px solid #000;
              height: 200px;
              line-height: 99px;
              font-size: 80px;
              padding-left: 40px;
            }
            .is-active {
              color: #d0111b;
            }
            .el-collapse-item__wrap {
              border-bottom: 1px solid #000;
            }
            &:first-child {
              .el-collapse-item__header {
                border-radius: 20px 20px 0 0;
              }
            }
            &:last-child {
              margin: 0;
              .el-collapse-item__header {
                border-radius: 0 0 20px 20px;
                border-bottom: none;
              }
              .el-collapse-item__wrap {
                border-radius: 0 0 20px 20px;
                border-bottom: none;
              }
            }
            &.is-active:last-child {
              margin: 0;
              .el-collapse-item__header {
                border-radius: 0;
                border-bottom: 1px solid #000;
              }
            }
          }
        }

        margin-bottom: 150px;
        // border-radius: 20px;
        // border: 1px solid #000;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .contents {
      margin: 50px 156px;
      p {
        font-size: 28px;
        color: #333;
        line-height: 37px;
        margin: 20px 0;
      }
      h3 {
        color: #333;
        font-size: 36px;
        margin: 30px 0 0 0;
      }
    }
    .CommonProblem {
      h2 {
        font-size: 48px;
        height: 64px;
        margin: 0 0 50px 0;
      }
      margin: 0 156px;
      .Problem {
        .el-collapse {
          border: 1px solid #000;
          border-radius: 20px;
          .el-collapse-item {
            .el-collapse-item__content {
              color: #333;
              line-height: 37px;
              font-size: 28px;
              margin: 21px 40px;
              padding: 0;
            }
            .el-collapse-item__header {
              border-bottom: 1px solid #000;
              height: 99px;
              line-height: 99px;
              font-size: 28px;
              padding-left: 40px;
            }
            .is-active {
              color: #d0111b;
            }
            .el-collapse-item__wrap {
              border-bottom: 1px solid #000;
            }
            &:first-child {
              .el-collapse-item__header {
                border-radius: 20px 20px 0 0;
              }
            }
            &:last-child {
              margin: 0;
              .el-collapse-item__header {
                border-radius: 0 0 20px 20px;
                border-bottom: none;
              }
              .el-collapse-item__wrap {
                border-radius: 0 0 20px 20px;
                border-bottom: none;
              }
            }
            &.is-active:last-child {
              margin: 0;
              .el-collapse-item__header {
                border-radius: 0;
                border-bottom: 1px solid #000;
              }
            }
          }
        }

        margin-bottom: 150px;
        // border-radius: 20px;
        // border: 1px solid #000;
      }
    }
  }
}
</style>

