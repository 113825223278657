<template>
  <div class="newsDeatils contsctUs">
    <Carousel :imgList="imgList" />
    <!-- 面包屑导航 -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{
          $t("Home")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/news' }">{{
          $t("News")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ obj.articleTitle | arcName }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="contents">
      <div class="img">
        <div class="date">
          <!-- <p>{{ obj.articleDealtime | time }}</p>
          <p>{{ obj.articleDealtime | time1 }}</p> -->
          <p>{{ this.time | time }}</p>
          <p>{{ this.time | time1 }}</p>
        </div>
        <img :src="obj.articleMedia" alt="" />
      </div>
      <h3>
        {{ obj.articleTitle }}
      </h3>
      <p v-html="obj.articleContent"></p>
    </div>
    <div class="share">
      <img src="./../../assets/images/201.png" alt="" />
      <span>SHARE:</span>
      <img src="./../../assets/images/203.png" alt="" @click="shareGoogle" />
      <img src="./../../assets/images/204.png" alt="" @click="shareWeibo" />
      <img src="./../../assets/images/205.png" alt="" @click="shareTwitter" />
      <img src="./../../assets/images/202.png" alt="" @click="shareFacebook" />
    </div>
    <div class="fy">
      <div @click="previous">
        <i class="el-icon-arrow-left"></i>{{ $t("Previous") }}
      </div>
      <div @click="next">
        {{ $t("Next") }}<i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";
import img1 from "./../../assets/images/banner1.jpg";
import { article, getArticleClick } from "../../api/api";
//引入moment函数
import moment from "moment";
export default {
  name: "About",
  components: {
    Company,
    tFooter,
    Carousel,
  },
  props: {},
  data() {
    return {
      articleContent: "<h1>123</h1>",
      time: "",
      total: "",
      list: [], //新闻列表数据
      index: "",
      form: {
        articleId: "",
      },
      params: {
        current: 1,
        size: 5,
      },
      obj: {}, //详情对象
      // imgList: {
      //   url: img1,
      //   className: "fadeInUp animated textLeft",
      //   title: "News",
      //   xtitle: "",
      // },
    };
  },
  filters: {
    time(val) {
      if (val != "" || val != undefined) {
        val = val.slice(-2);
      }
      return val;
    },
    time1(val) {
      if (val != "" || val != undefined) {
        val = val.slice(0, 7);
      }
      return val;
    },
    arcName(val) {
      if (val.length > 100) {
        val = val.slice(0, 100) + "...";
      }
      return val;
    },
  },
  methods: {
    // 获取文章浏览
    getArticleId() {
      if (this.$cookies.get("ArticleId") == null) {
        console.log(this.form.articleId, "this.form.articleId");
        // 调用接口
        getArticleClick(this.form.articleId)
          .then((res) => {
            console.log(res, "文章ID");
          })
          .catch((err) => {
            console.log(err, "文章ID");
          });
        this.$cookies.set("ArticleId", false);
      }
    },
    //点击分享google+
    shareGoogle() {
      this.message = String(location.href); //获取页面地址
      var url =
        "https://plus.google.com/sharer.php?url=" +
        encodeURIComponent(this.message);
      // this.$router.replace(url.split('/#/')[1])
      window.open(url);
    },
    //点击分享微博
    shareWeibo() {
      this.message = String(location.href); //获取页面地址
      var url =
        "https://service.weibo.com/share/share.php?url=" +
        encodeURIComponent(this.message);
      // this.$router.replace(url.split('/#/')[1])
      window.open(url);
      // count=表示是否显示当前页面被分享数量(1显示)(可选，允许为空)
      // &url=将页面地址转成短域名，并显示在内容文字后面。(可选，允许为空)
      // &appkey=用于发布微博的来源显示，为空则分享的内容来源会显示来自互联网。(可选，允许为空)
      // &title=分享时所示的文字内容，为空则自动抓取分享页面的title值(可选，允许为空)
      // &pic=自定义图片地址，作为微博配图(可选，允许为空)
      // &ralateUid=转发时会@相关的微博账号(可选，允许为空)
      // &language=语言设置(zh_cn|zh_tw)(可选)
    },
    //点击分享到推特
    shareTwitter() {
      function popupwindow(url, title) {
        return window.open(
          "https://twitter.com/intent/tweet?url=" +
            encodeURIComponent(url) +
            "&text=" +
            encodeURIComponent(title),
          "_blank",
          "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
        );
      }
      this.share_url = String(location.href); //获取页面地址
      popupwindow(this.share_url, this.share_title);
    },
    //点击分享到脸书
    shareFacebook() {
      function popupwindow(url, title) {
        return window.open(
          "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(url),
          //  +
          // +"&t=" +
          // encodeURIComponent(title),
          "_blank",
          "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
        );
      }
      this.share_url = String(location.href); //获取页面地址
      popupwindow(this.share_url, this.share_title);
    },
    //新闻列表
    articleList(params, callback) {
      article(params, callback).then((res) => {
        if (res.status == 200) {
          this.list = res.data.records;
          // return callback(res);
        }
      });
    },
    //点击上一条
    previous() {
      if (this.params.current == 1 && this.index == 0) {
        this.$message({
          message: this.$t("one"),
          type: "warning",
        });
      } else {
        this.index = Number(this.index) - 1;
        console.log(this.index, "this.index");
        console.log(this.params.current, "this.params.current");

        //超过当前页面数据，则刷新新的数据。
        if (this.index == -1) {
          this.index = 4;
          this.params.current = Number(this.params.current) - 1;
          // this.articleList(this.params, function (res) {
          //   let that = this;
          //   that.article({
          //     articleId: res.data.records[this.index].articleId,
          //   });
          // });
          this.articleList(this.params);
          return;
        }

        this.form.articleId = this.list[this.index].articleId;
        this.article(this.form);
      }
    },
    //点击下一条
    next() {
      if (
        this.params.current * Number(this.params.size) -
          (Number(this.params.size) - (Number(this.index) + 1)) ==
        this.total
      ) {
        this.$message({
          message: this.$t("last"),
          type: "warning",
        });
      } else {
        this.index = Number(this.index) + 1;
        console.log(this.index, "this.index");
        console.log(this.params.size, "this.params.size");
        //超过当前页面数据，则刷新新的数据。
        if (this.index > this.params.size - 1) {
          this.index = 0;
          this.params.current = Number(this.params.current) + 1;
          // this.articleList(this.params, function (res) {
          //   this.article({
          //     articleId: res.data.records[this.index].articleId,
          //   });
          // });
          this.articleList(this.params);
          return;
        }

        this.form.articleId = this.list[this.index].articleId;
        this.article(this.form);
      }
    },
    //调用新闻详情
    // debugger
    article(params) {
      article(params).then((res) => {
        console.log(res, "rrrrrrrrrrrrrr");
        if (res.status == 200) {
          // this.params.current = res.data.current;
          // this.params.size = res.data.size;
          // this.total = res.data.total;

          this.obj = {};
          if (this.lang == null) {
            this.obj.articleMedia =
              this.$global.imgUrl + res.data.records[0].articleMedia;
            this.obj.articleId = res.data.records[0].articleId;
            this.obj.articleTitle = res.data.records[0].articleTitleen;
            this.obj.articleResume = res.data.records[0].articleResumeen;
            this.obj.articleDescription =
              res.data.records[0].articleDescriptionen;
            this.obj.articleContent = res.data.records[0].articleContenten;
            this.obj.articleDealtime = moment(
              res.data.records[0].articleDotime
            ).format("YYYY-MM-DD");
          } else if (this.lang === "zh") {
            //中文
            this.obj.articleMedia =
              this.$global.imgUrl + res.data.records[0].articleMedia;
            this.obj.articleId = res.data.records[0].articleId;
            this.obj.articleTitle = res.data.records[0].articleTitlezh;
            this.obj.articleResume = res.data.records[0].articleResumezh;
            this.obj.articleDescription =
              res.data.records[0].articleDescriptionzh;
            this.obj.articleContent = res.data.records[0].articleContentzh;
            this.obj.articleDealtime = moment(
              res.data.records[0].articleDotime
            ).format("YYYY-MM-DD");
          } else if (this.lang === "en") {
            //英文
            this.obj.articleMedia =
              this.$global.imgUrl + res.data.records[0].articleMedia;
            this.obj.articleId = res.data.records[0].articleId;
            this.obj.articleTitle = res.data.records[0].articleTitleen;
            this.obj.articleResume = res.data.records[0].articleResumeen;
            this.obj.articleDescription =
              res.data.records[0].articleDescriptionen;
            this.obj.articleContent = res.data.records[0].articleContenten;
            this.obj.articleDealtime = moment(
              res.data.records[0].articleDotime
            ).format("YYYY-MM-DD");
          } else if (this.lang === "fr") {
            //法语
            this.obj.articleMedia =
              this.$global.imgUrl + res.data.records[0].articleMedia;
            this.obj.articleId = res.data.records[0].articleId;
            this.obj.articleTitle = res.data.records[0].articleTitlefr;
            this.obj.articleResume = res.data.records[0].articleResumefr;
            this.obj.articleDescription =
              res.data.records[0].articleDescriptionfr;
            this.obj.articleContent = res.data.records[0].articleContentfr;
            this.obj.articleDealtime = moment(
              res.data.records[0].articleDotime
            ).format("YYYY-MM-DD");
          } else if (this.lang === "ru") {
            //俄语
            this.obj.articleMedia =
              this.$global.imgUrl + res.data.records[0].articleMedia;
            this.obj.articleId = res.data.records[0].articleId;
            this.obj.articleTitle = res.data.records[0].articleTitleru;
            this.obj.articleResume = res.data.records[0].articleResumeru;
            this.obj.articleDescription =
              res.data.records[0].articleDescriptionru;
            this.obj.articleContent = res.data.records[0].articleContentru;
            this.obj.articleDealtime = moment(
              res.data.records[0].articleDotime
            ).format("YYYY-MM-DD");
          } else if (this.lang === "es") {
            //西班牙语
            this.obj.articleMedia =
              this.$global.imgUrl + res.data.records[0].articleMedia;
            this.obj.articleId = res.data.records[0].articleId;
            this.obj.articleTitle = res.data.records[0].articleTitlees;
            this.obj.articleResume = res.data.records[0].articleResumees;
            this.obj.articleDescription =
              res.data.records[0].articleDescriptiones;
            this.obj.articleContent = res.data.records[0].articleContentes;
            this.obj.articleDealtime = moment(
              res.data.records[0].articleDotime
            ).format("YYYY-MM-DD");
          } else if (this.lang === "ar") {
            //阿拉伯语
            this.obj.articleMedia =
              this.$global.imgUrl + res.data.records[0].articleMedia;
            this.obj.articleId = res.data.records[0].articleId;
            this.obj.articleTitle = res.data.records[0].articleTitlear;
            this.obj.articleResume = res.data.records[0].articleResumear;
            this.obj.articleDescription =
              res.data.records[0].articleDescriptionar;
            this.obj.articleContent = res.data.records[0].articleContentar;
            this.obj.articleDealtime = moment(
              res.data.records[0].articleDotime
            ).format("YYYY-MM-DD");
          } else if (this.lang === "id") {
            //印尼语
            this.obj.articleMedia =
              this.$global.imgUrl + res.data.records[0].articleMedia;
            this.obj.articleId = res.data.records[0].articleId;
            this.obj.articleTitle = res.data.records[0].articleTitleid;
            this.obj.articleResume = res.data.records[0].articleResumeid;
            this.obj.articleContent = res.data.records[0].articleContentid;
            this.obj.articleDealtime = moment(
              res.data.records[0].articleDotime
            ).format("YYYY-MM-DD");
          } else if (this.lang === "tr") {
            //土耳其语
            this.obj.articleMedia =
              this.$global.imgUrl + res.data.records[0].articleMedia;
            this.obj.articleId = res.data.records[0].articleId;
            this.obj.articleTitle = res.data.records[0].articleTitletr;
            this.obj.articleResume = res.data.records[0].articleResumetr;
            this.obj.articleContent = res.data.records[0].articleContenttr;
            this.obj.articleDescription =
              res.data.records[0].articleDescriptiontr;
            this.obj.articleDealtime = moment(
              res.data.records[0].articleDotime
            ).format("YYYY-MM-DD");
          }
          this.time = this.obj.articleDealtime;
        }
      });
    },
  },
  computed: {
    imgList() {
      return {
        url: img1,
        className: "fadeInUp animated textLeft",
        title: this.$t("News"),
        xtitle: "",
      };
    },
  },
  mounted() {
    this.getArticleId();
    this.total = this.$route.query.total; //数据总条数
    this.params.current = this.$route.query.current; //第几页的数据
    this.params.size = this.$route.query.size; //每页获取多少条

    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      //语言改变 重新去请求
      this.article(this.form);
      this.articleList(this.params);
    });
  },
  created() {
    this.form.articleId = this.$route.query.articleId;
    this.params.current = this.$route.query.current; //第几页的数据
    this.params.size = this.$route.query.size; //每页获取多少条
    this.index = this.$route.query.index;
    this.article(this.form); //详情接口
    this.articleList(this.params); //列表接口
  },
};
</script>

<style lang="less">
.newsDeatils {
  @media screen and (max-width: 766px) {
    .breadcrumb .el-breadcrumb {
      // font-size: 30px;
      .el-breadcrumb__item {
        float: none;
      }
    }
    .contents {
      margin: 0 156px;
      border: 1px solid #fff;
      .img {
        height: 824px;
        position: relative;
        border: 1px solid #fff;
        img {
          width: 1450px;
          height: 724px;
          border: 1px solid rgb(255, 255, 255);
          z-index: 1;
        }
        .date {
          position: absolute;
          width: 220px;
          height: 220px;
          z-index: 11;
          background: #d3232d;

          p {
            width: 100%;
            color: #fff;

            &:first-child {
              margin: 60px 0 0 0;
              font-size: 80px;
              text-align: center;
              height: 64px;
              line-height: 64px;
            }
            &:last-child {
              font-size: 50px;
              text-align: center;
              height: 31px;
              margin: 20px 0 0 0;
              line-height: 31px;
            }
          }
        }
      }
      h3 {
        font-size: 60px;
        height: 240px;
        color: #333;
        margin-top: 58px;
      }
      p {
        font-size: 80px;
        line-height: 100px;
        color: #999;
      }
    }
    .fy {
      margin: 200px;
      // height: 200px;
      border: 1px solid #707070;
      display: flex;
      div {
        flex: 1;
        height: 200px;
        text-align: center;
        font-size: 100px;
        color: #333;
        line-height: 200px;
        cursor: pointer;
        &:first-child {
          border-right: 1px solid #707070;
        }
      }
    }
    .share {
      height: 200px;
      margin: 62px 156px 0 156px;
      text-align: right;
      img {
        &:first {
          margin-right: 30px;
          cursor: nonr;
        }
        width: 100px;
        height: 100px;
        margin: 0 9px;
        vertical-align: middle;
        cursor: pointer;
      }
      span {
        font-size: 32px;
        line-height: 38px;
        color: #333;
        padding-right: 8px;
        vertical-align: middle;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .breadcrumb {
      .el-breadcrumb__item {
        float: none;
      }
    }
    .contents {
      margin: 0 156px;
      border: 1px solid #fff;
      .img {
        height: 724px;
        position: relative;
        border: 1px solid #fff;
        img {
          width: 1450px;
          height: 724px;
          border: 1px solid rgb(255, 255, 255);
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
        .date {
          position: absolute;
          width: 120px;
          height: 120px;
          z-index: 11;
          background: #d3232d;

          p {
            width: 100%;
            color: #fff;

            &:first-child {
              margin: 6px 0 0 0;
              font-size: 48px;
              text-align: center;
              height: 64px;
              line-height: 64px;
            }
            &:last-child {
              font-size: 24px;
              text-align: center;
              height: 31px;
              margin: 5px 0 0 0;
              line-height: 31px;
            }
          }
        }
      }
      h3 {
        font-size: 32px;
        height: 43px;
        color: #333;
        margin-top: 58px;
      }
      p {
        font-size: 24px;
        line-height: 38px;
        color: #999;
      }
    }
    .fy {
      margin: 60px 156px 70px 156px;
      height: 100px;
      border: 1px solid #707070;
      display: flex;
      div {
        flex: 1;
        height: 100px;
        text-align: center;
        font-size: 32px;
        color: #333;
        line-height: 100px;
        cursor: pointer;
        &:first-child {
          border-right: 1px solid #707070;
        }
      }
    }
    .share {
      height: 38px;
      margin: 62px 156px 0 156px;
      text-align: right;
      img {
        &:first {
          margin-right: 30px;
          cursor: nonr;
        }
        width: 38px;
        height: 38px;
        margin: 0 9px;
        vertical-align: middle;
        cursor: pointer;
      }
      span {
        font-size: 32px;
        line-height: 38px;
        color: #333;
        padding-right: 8px;
        vertical-align: middle;
      }
    }
  }
}
</style>

