import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
// 联系我们
import ContactUs from '../views/ContactUs/ContactUs.vue'
//关于我们
import About from '../views/About/About.vue'
// 新闻页
import News from '../views/News/News.vue'
// 新闻详情
import NewDetail from '../views/News/NewDetail.vue'
// 工程服务
import MaintenanceHlep from '../views/MaintenanceHlep/MaintenanceHlep.vue'
// 产品列表
import Products from '../views/Product/Products.vue'
import ProductDetails from '../views/Product/ProductDetails.vue'

// 工程服务
import EngineeringService from '../views/EngineeringService/EngineeringService.vue'
// 工程服务子页面1
import Manufacturing from '../views/EngineeringService/Manufacturing.vue'
import DataAcquisition from '../views/EngineeringService/DataAcquisition.vue'
import Quotation from '../views/EngineeringService/Quotation.vue'

// 成功案例
import SuccessfulCases from '../views/SuccessfulCases/SuccessfulCases.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index
  },
  {
    path: '/Home',
    name: 'Home',
    component: Index
  },
  // 联系我们
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs
  },
   // 关于我们
  {
    path: '/About',
    name: 'About',
    component: About
  },
  // 新闻页
  {
    path: '/news',
    name: 'News',
    component: News
  },
   // 新闻页详情
  {
    path: '/newsdeail',
    name: 'newsdeail',
    component: NewDetail
  },
  // 工程服务
  {
    path: '/MaintenanceHlep',
    name: 'MaintenanceHlep',
    component: MaintenanceHlep
  },
  // 产品列表
  {
    path: '/Products',
    name: 'Products',
    component: Products
  },
  // 产品详情
  {
    path: '/ProductDetails',
    name: 'ProductDetails',
    component: ProductDetails
  },
  
  //工程服务
  {
    path: '/EngineeringService',
    name: 'EngineeringService',
    component:EngineeringService
  },
  // 工程服务子页面1
  {
    path: '/Manufacturing',
    name: 'Manufacturing',
    component:Manufacturing
  },
  {
    path: '/DataAcquisition',
    name: 'DataAcquisition',
    component:DataAcquisition
  },
  {
    path: '/Quotation',
    name: 'Quotation',
    component:Quotation
  },
  // 成功案例  
  {
    path: '/SuccessfulCases',
    name: 'SuccessfulCases',
    component:SuccessfulCases
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  routes
})
export default router
