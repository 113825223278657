<template>
  <div class="company" id="company">
    <div class="company-con" id="company-con">
      <div class="companyjs" id="companyjs">
        <img id="imgLog" src="./../../assets/images/logos.png" alt="" />
        <p>{{ $t("Introduction") }}</p>
      </div>
      <div class="companylink" id="companylink">
        <div class="titles">{{ $t("OurInternationalNetwork") }}</div>
        <div class="uls" id="uls">
          <p class="lis" @click="goAbout">
            <i class="el-icon-arrow-right"></i>{{ $t("AboutUs") }}
          </p>
          <p class="lis" @click="goAbout">
            <i class="el-icon-arrow-right"></i>{{ $t("OurTeam") }}
          </p>
          <p class="lis" @click="goProducts">
            <i class="el-icon-arrow-right"></i>{{ $t("MainProducts") }}
          </p>
          <p class="lis" @click="goEngineeringService">
            <i class="el-icon-arrow-right"></i>{{ $t("EngineeringService") }}
          </p>
          <p class="lis" @click="goMaintenanceHlep">
            <i class="el-icon-arrow-right"></i>{{ $t("FreeMaintenanceHelp") }}
          </p>
          <p class="lis" @click="gocontactUs">
            <i class="el-icon-arrow-right"></i>{{ $t("ContactUs") }}
          </p>
        </div>
      </div>
      <div class="companylink" id="companylinkTwo">
        <div class="titles">{{ $t("Contactinfo") }}</div>
        <div class="Contant-us">{{ $t("Contactusviaemailorphone") }}</div>
        <div class="uls">
          <p class="lis">
            <span class="iconfont dingwei" id="iconfont dingwei"> </span>
            <span class="address"> {{ $t("address") }}</span>
          </p>
          <p class="lis">
            <span
              class="iconfont phone"
              id="iconfont phone"
              style="vertical-align: text-bottom"
            ></span>
            <span>+86 138 1077 7318</span>
          </p>
          <p class="lis">
            <span
              class="iconfont email"
              id="iconfont email"
              style="vertical-align: text-bottom"
            ></span>
            <span>sales@mizztech.com</span>
          </p>
        </div>
      </div>
      <div class="companylink" id="companylinkThree">
        <div class="titles" id="titles">{{ $t("OurPartnerNetwork") }}</div>
        <img src="./../../assets/images/ditu1.png" alt="" id="dTimg" />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/yiDong.css";
export default {
  name: "Company",
  props: {},
  data() {
    return {
     
    };
  },
  methods: {
    gocontactUs() {
      this.$router.push("/contactUs");
    },
    goProducts() {
      this.$router.push("/Products");
    },
    goAbout() {
      this.$router.push("/About");
    },
    goMaintenanceHlep() {
      this.$router.push("/MaintenanceHlep");
    },
    goEngineeringService() {
      this.$router.push("/EngineeringService");
    },
  },
  created() {},
};
</script>

<style lang="less">
@media screen and (max-width: 766px) {
  .company {
    padding: 100px;
    position: relative;
    background: #000;
    color: #fff;
    font-size: 50px;
    .companyjs {
      
      img {
        width: 350px;
        height: 120px;
      }
      p {
        width: 60%;
        // font-size: 35px;
      }
    }
    #companylink {
      margin-top: 80px;
      display: inline-block;
      margin-left: 50px;
    }
    #companylinkTwo {
      display: inline-block;
      margin-left: 200px;
    }
    #companylinkThree {
      position: absolute;
      right: 100px;
      top: 200px;
      .titles {
        margin-bottom: 50px;
      }
      img {
        width: 500px;
        object-fit: cover;
      }
    }
    .address {
      display: inline-block;
      width: 500px;
    }
    .uls {
      .lis {
        .email {
          background: url(./../../assets/images/email.png) no-repeat;
          background-size: cover;
          width: 50px;
          height: 50px;
          float: left;
          margin-left: -60px;
          vertical-align: top;
        }
        .phone {
          background: url(./../../assets/images/phone.png) no-repeat;
          background-size: cover;
          width: 50px;
          height: 50px;
          float: left;
          margin-left: -60px;
          vertical-align: top;
        }
        .dingwei {
          background: url(./../../assets/images/dingwei.png) no-repeat;
          background-size: cover;
          width: 50px;
          height: 50px;
          float: left;
          margin-left: -60px;
          vertical-align: top;
        }
      }
    }
    // .company-con {
    //   padding-top: 67px;
    //   margin: 0 110px;
    //   .companyjs {
    //     width: 507px;
    //     font-size: 22px;
    //     img {
    //       height: 59px;
    //     }
    //     p {
    //       font-size: 18px;
    //       font-family: Microsoft YaHei;
    //       line-height: 35px;
    //       color: #fff;
    //       text-align: left;
    //       margin-top: 29px;
    //     }
    //   }
    //   .companylink {
    //     color: #fff;
    //     width: 300px;
    //     img {
    //       height: 149px;
    //     }
    //     .titles {
    //       font-size: 22px;
    //       text-align: left;
    //       height: 29px;
    //     }
    //     .Contant-us {
    //       margin-top: 20px;
    //       font-size: 18px;
    //       color: #fff;
    //     }
    //     .uls {
    //       margin-top: 50px;
    //       .lis {
    //         font-size: 18px;
    //         color: #fff;
    //         line-height: 40px;
    //         cursor: pointer;
    //         position: relative;
    //         // i {
    //         //   padding-right: 800px;
    //         // }
    //         .iconfont {
    //           display: inline-block;
    //           width: 28px;
    //           height: 28px;
    //           margin-right: 5px;
    //           position: relative;
    //           top: 4px;
    //         }
    //         .email {
    //           background: url(./../../assets/images/email.png) no-repeat;
    //           background-size: cover;
    //         }
    //         .phone {
    //           background: url(./../../assets/images/phone.png) no-repeat;
    //           background-size: cover;
    //         }
    //         .dingwei {
    //           background: url(./../../assets/images/dingwei.png) no-repeat;
    //           background-size: cover;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
@media screen and (min-width: 768px) {
  .company {
    width: 100%;
    background: #000;
    .company-con {
      padding: 65px 0px;
      margin: 0 110px;
      display: flex;
      justify-content: space-between;
      .companyjs {
        width: 507px;
        font-size: 22px;
        img {
          height: 59px;
        }
        p {
          font-size: 18px;
          font-family: Microsoft YaHei;
          line-height: 35px;
          color: #fff;
          text-align: left;
          margin-top: 29px;
        }
      }
      .companylink {
        color: #fff;
        width: 300px;
        img {
          height: 149px;
          margin-top: 70px;
        }
        .titles {
          font-size: 22px;
          text-align: left;
          height: 29px;
        }
        .Contant-us {
          margin-top: 20px;
          font-size: 18px;
          color: #fff;
        }
        .uls {
          margin-top: 50px;
          .lis {
            font-size: 18px;
            color: #fff;
            line-height: 40px;
            cursor: pointer;
            position: relative;
            // i {
            //   padding-right: 800px;
            // }
            .iconfont {
              display: inline-block;
              width: 28px;
              height: 28px;
              margin-right: 5px;
              position: relative;
              top: 4px;
            }
            .email {
              background: url(./../../assets/images/email.png) no-repeat;
              background-size: cover;
            }
            .phone {
              background: url(./../../assets/images/phone.png) no-repeat;
              background-size: cover;
            }
            .dingwei {
              background: url(./../../assets/images/dingwei.png) no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }
  }
}
</style>
