<template>
  <div class="through">
    <h3>{{ $t("MoreProducts") }}</h3>
    <div class="throughUl1">
      <div class="throughli1 bg31">
        <div class="con"></div>
        <div class="bg"></div>
        <div class="title">
          <p>{{ $t("GyratoryCrushers") }}</p>
          <span class="btn" @click="jumpDetail(63)">{{ $t("MORE") }}</span>
        </div>
      </div>
      <div class="throughli1 bg21">
        <div class="con"></div>
        <div class="bg"></div>
        <div class="title">
          <p>{{ $t("ImpactCrusher") }}</p>
          <span class="btn" @click="jumpDetail(69)">{{ $t("MORE") }}</span>
        </div>
      </div>
      <div class="throughli1 bg11">
        <div class="con"></div>
        <div class="bg"></div>
        <div class="title">
          <p>{{ $t("VerticalShaftImpactCcrusher") }}</p>
          <span class="btn" @click="jumpDetail(26)">{{ $t("MORE") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    jumpDetail(id) {
      this.$router.push({
        path: "/ProductDetails",
        query: {
          id: id,
          crusher: 1,
        },
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less">
@media screen and (max-width: 766px) {
  .through {
    margin: 50px 156px;
    h3 {
      font-size: 40px;
    }
    .title {
      p {
        font-size: 20px;
        color: red;
      }
      .btn{
        color: red;
      }
    }
  }
}
</style>
