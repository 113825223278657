import request from "../utils/request"
import { get, post } from "../utils/request"
// import { baseUrl } from "../../public/global";

//本地代理的常量拼串
// const api = "/api"
const api = window.g.baseUrl
//获取产品信息传参(get)
export function GetProductInfoPrams(cateId) {
    return request.get(api + `/miniApi/getProductList?cateId=${cateId}`)
}

//注册
export function register(param) {
    // let param = new URLSearchParams();
    // param.append("usersEmail", usersEmail)
    // param.append("userPwd", userPwd)
    const url = api + `/home/register`
    return post(url, param, { 'Content-Type': 'application/json' },)
}

//登录
export function login(param) {
    const url = api + `/home/login`
    return post(url, param)
}

//发送验证码
export function sendEmail(email) {
    let param = new URLSearchParams();
    param.append("email", email)
    const url = api + `/home/sendEmail/${email}`
    return post(url, param)
}

//忘记密码
export function forgetPassword(param) {
    const url = api + `/home/forgetPassword`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//首页轮播图
export function loop(param) {
    const url = api + `/Bill/getbill`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//文章接口
export function getarticle(param) {
    const url = api + `/article/getarticle`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//分类接口
export function getSort() {
    const url = api + `/kindgoods/getKindGoodsTree`
    return get(url)
}

//首页商品列表
export function getProduct(param) {
    const url = api + `/neirong/getIndexGoods`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//商品列表
export function getProduct1(param) {
    const url = api + `/neirong/getGoods`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//根据id回显（详情）
export function getDetail(id) {
    const url = api + `/neirong/getById/${id}`
    return get(url)
}
// 商品点击浏览
export function getGoodsClick(goodsId) {
    const url = api + `neirong/updateGoodsViewcount?goodsId=${goodsId}`
    return get(url)
}
// 文章点击浏览
export function getArticleClick(articleId) {
    const url = api + `article/updateArticleViewcount?articleId=${articleId}`
    return get(url)
}
//添加评论接口
export function addSppl(param) {
    const url = api + `/sppl/addSppl`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//查询评论接口
export function getsppl(param) {
    const url = api + `/neirong/getGoodsReview`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//询单接口
export function xundan(param) {
    const url = api + `/xundan/add`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//文章接口
export function article(param) {
    const url = api + `/article/getarticle`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//文章接口
export function article1(param) {
    const url = api + `/article/getIndexArticle`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//搜索接口
export function search(param) {
    const url = api + `/neirong/getByCondition`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//案例列表接口
export function getcase(param) {
    const url = api + `/case/getcase`
    return post(url, param, { 'Content-Type': 'application/json' },)
}
//首页案例列表接口
export function getcase1(param) {
    const url = api + `/case/getIndexCase`
    return post(url, param, { 'Content-Type': 'application/json' },)
}