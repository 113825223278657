<template>
  <div class="EngineeringService contsctUs">
    <Carousel :imgList="imgList" />
    <div class="contents">
      <h3>{{$t('RapidManufacturing')}}</h3>
      <p>
      {{1}}{{'.'}}{{$t('printing')}}
      </p>
      <p>
    {{2}}{{'.'}}{{$t('technology')}}
      </p>
      <p>
        {{3}}{{'.'}}{{$t('conventional')}}
      </p>
      <div class="img">
        <img src="./../../assets/images/413.png" alt="" />
        <img src="./../../assets/images/324.png" alt="" />
      </div>
    </div>
    <mainProduct />
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";
// 主要产品
import mainProduct from "./../../components/EngineeringService/mainproduct.vue";
import img1 from './../../assets/images/banner1.jpg'

export default {
  name: "About",
  components: {
    Company,
    tFooter,
    Carousel,
    mainProduct
  },
  props: {},
  data() {
    return {
      // imgList: {
      //   url: img1,
      //   className: "fadeInUp animated textLeft",
      //   title: "Engineering Service",
      //   xtitle: "Provide one-stop solution and complete \n supporting service",
      // },
    };
  },
  methods: {},
  computed: {
    imgList() {
      return {
        url: img1,
        className: "fadeInUp animated textLeft",
        title: this.$t('EngineeringService'),
        xtitle: this.$t('engineeXtitle'),
      }
    }
  },
  created() {},
};
</script>

<style lang="less">
.EngineeringService {
  @media screen and (max-width: 766px) {
    .contents {
      .img {
        display: flex;
        justify-content: space-between;
        img {
          width: 770px;
          height: 480px;
        }
      }
    }
    .throughUl1 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .throughli1 {
        width: 515px;
        height: 550px;
        margin-bottom: 70px;
        overflow: hidden;
        position: relative;
        &:hover {
          .bg {
            opacity: 0.2;
          }
          transition: all 0.5s ease 0s;
          transform: scale(1.1);
        }
        .bg {
          width: 515px;
          height: 550px;
          position: relative;
          top: -550px;
          left: 0;
          background: #000;
          opacity: 0.4;
          z-index: 2;
        }
        .title {
          position: absolute;
          bottom: 206px;
          z-index: 3;
          p {
            // margin: 205px 69px 0 65px;
            margin: 0;
            font-size: 48px;
            // height: 127px;
            color: #fff;
            text-align: center;
            z-index: 11;
          }
          .btn {
            width: 195px;
            height: 60px;
            margin: 0;
            margin: 32px 169px 0 151px;
            border: 1px solid #d01f27;
            display: block;
            border-radius: 10px;
            color: #fff;
            font-size: 40px;
            line-height: 60px;
            text-align: center;
            cursor: pointer;
            &:hover {
              border: 1px solid #d01f27;
              background: #d01f27;
            }
          }
        }
        .con {
          height: 550px;
          margin: 0;
        }
      }
      .bg31 {
        background: url(./../../assets/images/334.jpg) 0 0 no-repeat;
        background-size: contain;
      }
      .bg21 {
        background: url(./../../assets/images/332.jpg) 0 0 no-repeat;
        background-size: contain;
      }
      .bg11 {
        background: url(./../../assets/images/333.jpg) 0 0 no-repeat;
        background-size: contain;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .contents {
      .img {
        display: flex;
        justify-content: space-between;
        img {
          width: 770px;
          height: 480px;
        }
      }
    }
    .throughUl1 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .throughli1 {
        width: 515px;
        height: 550px;
        margin-bottom: 70px;
        overflow: hidden;
        position: relative;
        &:hover {
          .bg {
            opacity: 0.2;
          }
          transition: all 0.5s ease 0s;
          transform: scale(1.1);
        }
        .bg {
          width: 515px;
          height: 550px;
          position: relative;
          top: -550px;
          left: 0;
          background: #000;
          opacity: 0.4;
          z-index: 2;
        }
        .title {
          position: absolute;
          bottom: 206px;
          z-index: 3;
          p {
            // margin: 205px 69px 0 65px;
            margin: 0;
            font-size: 48px;
            // height: 127px;
            color: #fff;
            text-align: center;
            z-index: 11;
          }
          .btn {
            width: 195px;
            height: 60px;
            margin: 0;
            margin: 32px 169px 0 151px;
            border: 1px solid #d01f27;
            display: block;
            border-radius: 10px;
            color: #fff;
            font-size: 22px;
            line-height: 60px;
            text-align: center;
            cursor: pointer;
            &:hover {
              border: 1px solid #d01f27;
              background: #d01f27;
            }
          }
        }
        .con {
          height: 550px;
          margin: 0;
        }
      }
      .bg31 {
        background: url(./../../assets/images/334.jpg) 0 0 no-repeat;
        background-size: contain;
      }
      .bg21 {
        background: url(./../../assets/images/332.jpg) 0 0 no-repeat;
        background-size: contain;
      }
      .bg11 {
        background: url(./../../assets/images/333.jpg) 0 0 no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>

