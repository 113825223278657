<template>
  <div class="mainProduct">
    <div class="mp bestt">
      {{ $t("MainProducts") }}
    </div>

    <div class="mp best" v-if="sortList.length > 0">
      <div class="best0">
        <div class="best00">
          <img
            src="./../../../assets/images/flsb1.png"
            style="transition: all 0.5s ease 0s; transform: scale(1)"
          />
        </div>
        <div class="bestmask"></div>
        <div class="best01">
          <div class="best02">{{ sortList[0].kindName }}</div>
          <div class="best03">
            <span class="more" @click="jumpProduct(sortList[0].kindCode)">{{
              $t("FindMore")
            }}</span>
          </div>
        </div>
      </div>
      <div class="best0">
        <div class="best00">
          <img
            src="./../../../assets/images/flsb2.png"
            style="transition: all 0.5s ease 0s; transform: scale(1)"
          />
        </div>
        <div class="bestmask"></div>
        <div class="best01">
          <div class="best02">{{ sortList[1].kindName }}</div>
          <div class="best03">
            <span class="more" @click="jumpProduct(sortList[1].kindCode)">{{
              $t("FindMore")
            }}</span>
          </div>
        </div>
      </div>
      <div class="best0">
        <div class="best00">
          <img
            src="./../../../assets/images/flsb3.png"
            style="transition: all 0.5s ease 0s; transform: scale(1)"
          />
        </div>
        <div class="bestmask"></div>
        <div class="best01">
          <div class="best02">{{ sortList[2].kindName }}</div>
          <div class="best03">
            <span class="more" @click="jumpProduct(sortList[2].kindCode)">{{
              $t("FindMore")
            }}</span>
          </div>
        </div>
      </div>
      <div class="best0">
        <div class="best00">
          <img
            src="./../../../assets/images/flsb5.png"
            style="transition: all 0.5s ease 0s; transform: scale(1)"
          />
        </div>
        <div class="bestmask"></div>
        <div class="best01">
          <div class="best02">{{ sortList[3].kindName }}</div>
          <div class="best03">
            <span class="more" @click="jumpProduct(sortList[3].kindCode)">{{
              $t("FindMore")
            }}</span>
          </div>
        </div>
      </div>
      <div class="best0">
        <div class="best00">
          <img
            src="./../../../assets/images/flsb4.png"
            style="transition: all 0.5s ease 0s; transform: scale(1)"
          />
        </div>
        <div class="bestmask"></div>
        <div class="best01">
          <div class="best02">{{ sortList[4].kindName }}</div>
          <div class="best03">
            <span class="more" @click="jumpProduct(sortList[4].kindCode)">{{
              $t("FindMore")
            }}</span>
          </div>
        </div>
      </div>
      <div class="best0">
        <div class="best00">
          <img
            src="./../../../assets/images/flsb6.png"
            style="transition: all 0.5s ease 0s; transform: scale(1)"
          />
        </div>
        <div class="bestmask"></div>
        <div class="best01">
          <div class="best02">{{ sortList[5].kindName }}</div>
          <div class="best03">
            <span class="more" @click="jumpProduct(sortList[5].kindCode)">{{
              $t("FindMore")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSort } from "../../../api/api";

export default {
  data() {
    return {
      sortList: [], //一级分类数组
      lang: "", //语言
    };
  },
  methods: {
    //获取分类
    getSort() {
      getSort().then((res) => {
        if (res.status == 200) {
          this.sortList = [];
          if (this.lang == null) {
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNameen,
              });
            });
          } else if (this.lang === "zh") {
            //中文
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNamezh,
              });
            });
            } else if (this.lang === "en") {
            //英文
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNameen,
              });
            });
          } else if (this.lang === "fr") {
            //法语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNamefr,
              });
            });
          } else if (this.lang === "ru") {
            //俄语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNameru,
              });
            });
          } else if (this.lang === "es") {
            //西班牙语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNamees,
              });
            });
          } else if (this.lang === "ar") {
            //阿拉伯语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNamear,
              });
            });
          } else if (this.lang === "id") {
            //印尼语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNameid,
              });
            });
          } else if (this.lang === "tr") {
            //土耳其语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNametr,
              });
            });
          }
        }
      });
    },
    //跳转产品页
    jumpProduct(kindCode, index) {
      console.log(index, "index");
      console.log(kindCode, "kindCodekindCode");
      this.$router.push({
        path: "/Products",
        query: {
          kindCode: kindCode,
        },
      });
    },
  },
  mounted() {
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      console.log(this.lang);
      //语言改变 重新去请求
      //获取分类
      this.getSort();
    });
    this.getSort(); //获取分类
  },
};
</script>
<style lang="less">
.mainProduct {
  @media (max-width: 766px) {
    clear: both;
    margin: 0 110px;
    position: relative;
    .bestt {
      text-align: center;
      font-size: 8.375rem;
      height: 66px;
      line-height: 66px;
      padding: 200px 0;
    }
    .best {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      .best0 {
        // height: 600px;
        width: 800px;
        margin-top: 50px;
        overflow: hidden;
        text-align: center;
        margin-bottom: 23px;
        &:hover {
          .bestmask {
            opacity: 0.2;
          }
          .best00 {
            transition: all 0.5s ease 0s;
            transform: scale(1.1);
          }
        }
        cursor: pointer;
        .best00 {
          height: 280px;
        }
        .best00 img {
          height: 400px;
        }
        .bestmask {
          height: 100%;
          z-index: 2;
          position: relative;
          top: -280px;
          background: #000;
          opacity: 0.6;
        }
        img {
          width: 100%;
        }
        // 框里面的字体
        .best01 {
          z-index: 3;
          position: relative;
          top: -700px;
          .best02 {
            font-size: 100px;
            height: 200px;
            color: #fff;
            font-weight: 500;
          }
          // see more 字母上的特效
          .best03 {
            width: 100%;
            height: 10px;
            text-align: center;
            font-size: 5rem;
            position: relative;
            top: 50px;
            .more {
              &:hover {
                background: #d0111b;
                border: 1px solid #d0111b;
                color: #fff;
              }
              width: 500px;
              height: 100px;
              display: block;
              border: 1px solid #ddd;
              background-color: #ddd;
              color: #333;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    // height: 565px;
    clear: both;
    margin: 0 110px;
    position: relative;
    // .wechat {
    //   position: absolute;
    //   width: 97px;
    //   height: 97px;
    //   right: 0;
    //   top: 28px;
    //   cursor: pointer;
    //   background: url(./../../../assets/images/product.png) no-repeat;
    //   background-size: contain;
    // }

    .bestt {
      text-align: center;
      font-size: 38px;
      font-weight: 600;
      height: 66px;
      line-height: 66px;
      padding: 40px 0;
    }
    .best {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .best0 {
        width: 541px;
        height: 280px;
        overflow: hidden;
        text-align: center;
        margin-bottom: 23px;
        &:hover {
          .bestmask {
            opacity: 0.2;
          }
          .best00 {
            transition: all 0.5s ease 0s;
            transform: scale(1.1);
          }
          .best01 .more {
            background: #d0111b;
            border: 1px solid #d0111b;
            color: #fff;
          }
        }
        cursor: pointer;
        .best00 {
          height: 280px;
        }
        .best00 img {
          height: 280px;
        }
        .bestmask {
          height: 100%;
          z-index: 2;
          position: relative;
          top: -280px;
          background: #000;
          opacity: 0.6;
        }
        .best01 {
          z-index: 3;
          position: relative;
          top: -450px;
          .best02 {
            font-size: 40px;
            height: 60px;
            color: #fff;
            font-weight: 500;
          }
          .best03 {
            width: 100%;
            height: 40px;
            text-align: center;
            font-size: 24px;
          }
        }
        .more {
          font-size: 20px;
          width: 182px;
          height: 31px;
          display: block;
          border: 1px solid #ddd;
          background-color: #ddd;
          color: #333;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
