<template>
  <div class="Technal">
    <div class="mp promt">
      {{ $t("EngneeringTechnicalSolutions") }}
    </div>
    <div class="mp prom">
      <div class="prom0" v-for="(item, index) in technialList" :key="index">
        <img :src="item.url" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    technialList: Array,
  },
  data() {
    return {};
  },
  watch() {
    this.img();
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less">
.Technal {
  @media screen and (max-width: 766px) {
    // height: 696px;
    margin: 100px 110px;
    .promt {
      text-align: center;
      font-size: 8.375rem;
      height: 26.375rem;
      padding: 140px 0;
    }
    .prom {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .prom0 {
        width: 820px;
        height: 582px;
        img {
          width: 820px;
          height: 582px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    // height: 696px;
    margin: 0 110px;
    .promt {
      text-align: center;
      font-size: 38px;
      font-weight: 600;
      height: 66px;
      padding: 40px 0;
    }

    .prom {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .prom0 {
        width: 820px;
        height: 582px;

        img {
          width: 820px;
          height: 582px;
        }
      }
    }
  }
}
</style>
