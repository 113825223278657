<template>
  <!-- 轮播图 -->
  <div class="Carousel">
    <div class="el-carousel__container1">
      <h2 :class="activeIndex == 0 ? imgList.className : ''">{{ imgList.title }}
      </h2>
      <p
        v-if="imgList.xtitle"
        :class="activeIndex == 0 ? imgList.className : ''"
      >{{ imgList.xtitle }}
      </p>
      <img :src="imgList.url" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    imgList: Object,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
