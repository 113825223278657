<template>
  <div class="EngineeringService contsctUs">
    <Carousel :imgList="imgList" />
    <div class="contents">
      <p>{{$t('mizztechDescription')}}</p>
      <p>{{$t('mizztechDescription2')}}</p>
    </div>
    <div class="through">
      <h3>{{$t('Through')}}</h3>
      <div class="throughUl">
        <div class="throughli bg1">
          <p>{{$t('RapidQuotation')}}</p>
          <span class="btn" @click="toQuotation()">{{$t('MORE')}}</span>
        </div>
        <div class="throughli bg2">
          <p>{{$t('DataAcquisition')}}</p>
          <span class="btn" @click="toDataAcquisition()">{{$t('MORE')}}</span>
        </div>
        <div class="throughli bg3">
          <p>{{$t('RapidManufacturing')}}</p>
          <span class="btn" @click="toManufacturing()">{{$t('MORE')}}</span>
        </div>
      </div>
    </div>
    <div class="contents conbottom">
      <h3>{{$t('Quailty')}}</h3>
      <h3>{{$t('Warman')}}</h3>
      <p>·&nbsp;&nbsp;{{$t('Provide')}}<br />
        ·&nbsp;&nbsp;{{$t('Materials')}}<br />
        ·&nbsp;&nbsp;{{$t('Availability')}}<br />
        ·&nbsp;&nbsp;{{$t('pressureBearing')}} <br />
        ·&nbsp;&nbsp;{{$t('Global')}}<br />
        ·&nbsp;&nbsp;{{$t('Significant')}}<br />
      </p>
    </div>
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";

export default {
  name: "About",
  components: {
    Company,
    tFooter,
    Carousel,
  },
  props: {},
  data() {
    return {
      // imgList: {
      //   url: img1,
      //   className: "fadeInUp animated textLeft",
      //   title: "Engineering Service",
      //   xtitle: "Provide one-stop solution and complete \n supporting service",
      // },
    };
  },
  methods: {
    toManufacturing() {
      this.$router.push("/Manufacturing");
    },
    toDataAcquisition() {
      this.$router.push("/DataAcquisition");
    },
    toQuotation() {
      this.$router.push("/Quotation");
    },
  },
  computed:{
    imgList(){
      return{
        url: window.g.engineerImg,
        className: "fadeInUp animated textLeft",
        title:this.$t('EngineeringService'),
        xtitle:this.$t('engineeXtitle'),
      }
    }
  },
  created() {},
};
</script>

<style lang="less">
.EngineeringService {
  @media screen and (max-width: 766px) {
    .contents {
      margin: 150px 156px;
      p {
        font-size: 50px;
        color: #333;
        line-height: 100px;
        margin: 20px 0;
      }
      h3 {
        font-size: 70px;
        height: 164px;
        color: #000;
        margin: 0;
      }
    }
    .through {
      margin: 0 100px;
      h3 {
        // padding-top: 20px;
        font-size: 48px;
        height: 64px;
        color: #000;
      }
      .throughUl {
        display: flex;
        justify-content: space-between;
        .throughli {
          width: 515px;
          height: 720px;
          p {
            margin: 219px 69px 0 65px;
            font-size: 50px;
            height: 127px;
            color: #fff;
            text-align: center;
          }
          .btn {
            // width: 195px;
            // height: 60px;
            margin: 40px 120px;
            padding: 10px;
            border: 1px solid #d01f27;
            display: block;
            border-radius: 10px;
            color: #fff;
            font-size: 45px;
            // line-height: 60px;
            text-align: center;
            cursor: pointer;
            &:hover {
              border: 1px solid #d01f27;
              background: #d01f27;
            }
          }
        }
        .bg3 {
          background: url(./../../assets/images/316.jpg) 0 0 no-repeat;
          background-size: cover;
        }
        .bg2 {
          background: url(./../../assets/images/320.jpg) 0 0 no-repeat;
          background-size: cover;
          p {
            margin: 250px 69px 0 65px;
            font-size: 48px;
            height: 96px;
            color: #fff;
            text-align: center;
          }
        }
        .bg1 {
          background: url(./../../assets/images/331.jpg) 0 0 no-repeat;
          background-size: cover;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .contents {
      margin: 50px 156px;
      p {
        font-size: 40px;
        color: #333;
        margin: 20px 0;
      }
      h3 {
        font-size: 48px;
        height: 64px;
        color: #000;
        margin: 0;
      }
    }
    .through {
      margin: 0 156px;
      h3 {
        // padding-top: 20px;
        font-size: 48px;
        height: 64px;
        color: #000;
      }
      .throughUl {
        display: flex;
        justify-content: space-between;
        .throughli {
          width: 515px;
          height: 720px;
          p {
            margin: 219px 69px 0 65px;
            font-size: 40px;
            height: 127px;
            color: #fff;
            text-align: center;
          }
          .btn {
            width: 195px;
            height: 60px;
            margin: 58px 169px 0 151px;
            border: 1px solid #d01f27;
            display: block;
            border-radius: 10px;
            color: #fff;
            font-size: 22px;
            line-height: 60px;
            text-align: center;
            cursor: pointer;
            &:hover {
              border: 1px solid #d01f27;
              background: #d01f27;
            }
          }
        }
        .bg3 {
          background: url(./../../assets/images/316.jpg) 0 0 no-repeat;
          background-size: cover;
        }
        .bg2 {
          background: url(./../../assets/images/320.jpg) 0 0 no-repeat;
          background-size: cover;
          // p {
          //   margin: 250px 69px 0 65px;
          //   font-size: 48px;
          //   height: 96px;
          //   color: #fff;
          //   text-align: center;
          // }
        }
        .bg1 {
          background: url(./../../assets/images/331.jpg) 0 0 no-repeat;
          background-size: cover;
        }
      }
    }
  }
}
</style>

