<template>
  <div class="EngineeringService contsctUs">
    <Carousel :imgList="imgList" />
    <div class="contents">
      <h3>{{ $t("RapidQuotation") }}</h3>
      <p>
        {{ $t("sales") }}
      </p>
      <p>
        {{ $t("specifications") }}
      </p>
    </div>
    <!-- 表单部分输入 -->
    <div id="capture">
      <div class="forms">
        <el-form label-position="left" :model="formInline">
          <div class="Customer">
            <h3>{{ $t("CustomerQuestionnaire") }}</h3>
            <h4>{{ $t("complete") }}</h4>
            <el-form-item :label="$t('Pumpapplication')">
              <el-input v-model="formInline.application"></el-input>
            </el-form-item>
            <el-form-item :label="$t('HoursOperation')">
              <el-input v-model="formInline.OperationTime">
                <template slot="append"
                  >{{ $t("Hours") }}{{ "/" }}{{ $t("day") }}</template
                >
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('Pumpsquantity')">
              <el-row>
                <el-col :span="12">
                  <el-input v-model="formInline.run">
                    <template slot="prepend">{{ $t("run") }}</template>
                  </el-input>
                </el-col>
                <el-col :span="12">
                  <el-input v-model="formInline.support">
                    <template slot="prepend">{{ $t("standBy") }}</template>
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item :label="$t('Massflowrate')">
              <el-row>
                <el-col :span="10">
                  <el-input v-model="formInline.min1">
                    <template slot="prepend">{{ $t("min") }}</template>
                  </el-input>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="formInline.norm1">
                    <template slot="prepend">{{ $t("norm") }}</template>
                  </el-input>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="formInline.max1">
                    <template slot="prepend">{{ $t("max") }}</template>
                  </el-input>
                </el-col>
                <el-col :span="3"><span>t/h</span></el-col>
              </el-row>
            </el-form-item>
            <el-form-item :label="$t('Slurryflowrate')">
              <el-row>
                <el-col :span="10">
                  <el-input v-model="formInline.min2">
                    <template slot="prepend">{{ $t("min") }}</template>
                  </el-input>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="formInline.norm2">
                    <template slot="prepend">{{ $t("norm") }}</template>
                  </el-input>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="formInline.max2">
                    <template slot="prepend">{{ $t("max") }}</template>
                  </el-input>
                </el-col>
                <el-col :span="3"><span>m³/h</span></el-col>
              </el-row>
            </el-form-item>
            <el-form-item :label="$t('Sealtype')">
              <el-radio-group class="btngroup" v-model="formInline.SealType">
                <el-radio-button :label="$t('GlandSeal')"></el-radio-button>
                <el-radio-button :label="$t('Centrifugal')"></el-radio-button>
                <el-radio-button :label="$t('Mechanical')"></el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
          <!-- Slurry Description -->
          <div class="Customer Customer1">
            <h3>{{ $t("SlurryDescription") }}</h3>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('gravity')">
                  <el-input v-model="formInline.proportion">
                    <template slot="append">t/m³</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('Liquidtype')">
                  <el-input v-model="formInline.liquid"> </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('Solidspecificgravity')">
                  <el-input v-model="formInline.solid">
                    <template slot="append">t/m³</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('Liquidspecificgravity')">
                  <el-input v-model="formInline.proportionLiquid"
                    ><template slot="append">t/m³</template></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('concentration')">
                  <el-input v-model="formInline.concentration">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('Presence')">
                  <el-input v-model="formInline.oil"> </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <!-- <el-col :span="12" style="text-align: center;">
                <span>{{ $t("Solidtype") }}</span>
              </el-col> -->
              <el-col :span="12">
                <el-form-item :label="$t('Solidtype')">
                  <el-input v-model="formInline.oil"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('oilgas')">
                  <el-input v-model="formInline.NaturalGas">
                    <template slot="append">gr/m³</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('Maximum')">
                  <el-input v-model="formInline.biggest">
                    <template slot="append">mm</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('pH')">
                  <el-input v-model="formInline.thePH"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('Average')">
                  <el-input v-model="formInline.average">
                    <template slot="append">mm</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('Temperature')">
                  <el-input v-model="formInline.temperature"
                    ><template slot="append">℃</template></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- Analysis -->
          <div class="Customer1 Analysis">
            <h3>{{ $t("Solid") }}</h3>
            <div class="Analysis-tab">
              <label class="el-form-item__label">{{ $t("Size") }},mm</label>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.size"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.size1"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.size2"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.size3"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.size4"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.size5"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.size6"></el-input>
              </div>
            </div>
            <div class="Analysis-tab">
              <label class="el-form-item__label">{{ $t("Retained") }}</label>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.keep"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.keep1"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.keep2"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.keep3"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.keep4"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.keep5"></el-input>
              </div>
              <div class="el-form-item__content">
                <el-input placeholder="" v-model="form.keep6"></el-input>
              </div>
            </div>
          </div>
          <!--Pipework  -->
          <div class="Customer Installation Customer1">
            <h3>{{ $t("PumpInstallation") }}</h3>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('Specific')">
                  <el-input v-model="formInline.pumpSerous">
                    <template slot="append">M</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('MinAmbient')">
                  <el-input v-model="formInline.pumpLiquid">
                    <template slot="append">0℃</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('Location')">
                  <el-radio-group
                    class="btngroup"
                    v-model="formInline.pumpSolid"
                  >
                    <el-radio-button :label="$t('indoor')"></el-radio-button>
                    <el-radio-button
                      :label="$t('outdoor')"
                    ></el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('MaxAmbient')">
                  <el-input v-model="formInline.pumpProportion">
                    <template slot="append">0℃</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!--  -->
          <div class="Customer Customer1">
            <h3>{{ $t("Pipework") }}</h3>
            <el-row>
              <el-col :span="12">
                <h5>{{ $t("Intake") }}</h5>
              </el-col>
              <el-col :span="12">
                <h5>{{ $t("Discharge") }}</h5>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="">
                  <el-row>
                    <el-col :span="6">
                      <el-input value="1pc" readonly> </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="2pc" readonly></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="3pc" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="">
                  <el-row>
                    <el-col :span="6">
                      <el-input value="1pc" readonly> </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="2pc" readonly></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="3pc" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('Length')">
                  <el-row>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeLength"> </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeLength1"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeLength2"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="m" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('Length')">
                  <el-row>
                    <el-col :span="6">
                      <el-input v-model="formInline.dischargeLength">
                      </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="formInline.dischargeLength1"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="formInline.dischargeLength2"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="m" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('InsideDiameter')">
                  <el-row>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeDiameter"> </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeDiameter1"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeDiameter2"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="nm" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('InsideDiameter')">
                  <el-row>
                    <el-col :span="6">
                      <el-input v-model="formInline.dischargeDiameter">
                      </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="formInline.dischargeDiameter1"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="formInline.dischargeDiameter2"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="nm" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" style="text-align: center">
                <span>{{ $t("Quantity") }}</span>
              </el-col>
              <el-col :span="12" style="text-align: center">
                <span>{{ $t("Quantity") }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('Valves')">
                  <el-row>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeValve"> </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeValve1"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeValve2"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="pcs" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('Valves')">
                  <el-row>
                    <el-col :span="6">
                      <el-input v-model="formInline.dischargeValve"> </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.dischargeValve1"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.dischargeValve2"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="pcs" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('Bends')">
                  <el-row>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeBending"> </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeBending1"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input v-model="formInline.intakeBending2"></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="pcs" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('Bends')">
                  <el-row>
                    <el-col :span="6">
                      <el-input v-model="formInline.dischargeBending">
                      </el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="formInline.dischargeBending1"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="formInline.dischargeBending2"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-input value="pcs" readonly></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('Material')">
                  <el-input v-model="formInline.intakeMaterial"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('Material')">
                  <el-input v-model="formInline.dischargeMaterial"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <!--Intake  -->
          <div class="Intake Customer1">
            <h3>{{ $t("Conditions") }}</h3>
            <div class="Intake-tab">
              <div class="Intakelis">
                <div class="title">
                  <el-row>
                    <!-- <el-col :span="16">{{ $t("Sump") }}</el-col> -->
                    <!-- <el-col :span="8">
                    <el-checkbox></el-checkbox>
                  </el-col> -->
                    <el-col
                      >{{ $t("Sump") }} <el-checkbox></el-checkbox
                    ></el-col>
                  </el-row>
                </div>
                <div class="img">
                  <img src="./../../assets/images/bzzk1.png" />
                </div>
                <div class="con">
                  <el-row>
                    <el-col :span="6">A= </el-col>
                    <el-col :span="6" class="borderRt">
                      <el-input
                        placeholder=""
                        v-model="formInline.intakeA"
                      ></el-input>
                    </el-col>
                    <el-col :span="6"> B= </el-col>
                    <el-col :span="6">
                      <el-input
                        placeholder=""
                        v-model="formInline.intakeB"
                      ></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="Intakelis">
                <div class="title">
                  <el-row>
                    <el-col class="Submersible"
                      >{{ $t("Submersible") }}<el-checkbox></el-checkbox
                    ></el-col>
                    <!-- <el-col :span="8">
                    <el-checkbox></el-checkbox>
                  </el-col> -->
                  </el-row>
                </div>
                <div class="img">
                  <img src="./../../assets/images/bzzk2.png" />
                </div>
                <div class="con">
                  <el-row>
                    <el-col :span="6">C= </el-col>
                    <el-col :span="6" class="borderRt">
                      <el-input
                        placeholder=""
                        v-model="formInline.intakeC"
                      ></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="Intakelis">
                <div class="title">
                  <el-row>
                    <el-col class="Submersible"
                      >{{ $t("Suctionlift") }}<el-checkbox></el-checkbox
                    ></el-col>
                    <!-- <el-col :span="8">
                    <el-checkbox></el-checkbox>
                  </el-col> -->
                  </el-row>
                </div>
                <div class="img">
                  <img src="./../../assets/images/bzzk3.png" />
                </div>
                <div class="con">
                  <el-row>
                    <el-col :span="6">D= </el-col>
                    <el-col :span="6" class="borderRt">
                      <el-input
                        placeholder=""
                        v-model="formInline.intakeD"
                      ></el-input>
                    </el-col>
                    <el-col :span="6"> E= </el-col>
                    <el-col :span="6">
                      <el-input
                        placeholder=""
                        v-model="formInline.intakeE"
                      ></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="Intakelis">
                <div class="title">
                  <el-row>
                    <el-col class="Submersible"
                      >{{ $t("Gravityfeed") }} <el-checkbox></el-checkbox
                    ></el-col>
                    <!-- <el-col :span="8">
                    <el-checkbox></el-checkbox>
                  </el-col> -->
                  </el-row>
                </div>
                <div class="img">
                  <img src="./../../assets/images/bzzk4.png" />
                </div>
                <div class="con">
                  <el-row>
                    <el-col :span="6">F= </el-col>
                    <el-col :span="6" class="borderRt">
                      <el-input
                        placeholder=""
                        v-model="formInline.intakeF"
                      ></el-input>
                    </el-col>
                    <el-col :span="6"> G= </el-col>
                    <el-col :span="6">
                      <el-input
                        placeholder=""
                        v-model="formInline.intakeG"
                      ></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="Intakelis">
                <div class="title">
                  <el-row>
                    <el-col class="Submersible"
                      >{{ $t("Incyclone") }}<el-checkbox></el-checkbox
                    ></el-col>
                    <!-- <el-col :span="8">
                    <el-checkbox></el-checkbox>
                  </el-col> -->
                  </el-row>
                </div>
                <div class="img">
                  <img src="./../../assets/images/bzzk5.png" />
                </div>
                <div class="con">
                  <el-row>
                    <el-col :span="12">{{ $t("Pressure") }}</el-col>
                    <el-col :span="6">
                      <el-input
                        placeholder=""
                        v-model="formInline.intakePressure"
                      ></el-input>
                    </el-col>
                    <el-col :span="6"> KPa </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>

          <!-- Drive arrangement -->
          <div class="Customer1 Intake Drive">
            <h3>{{ $t("Drivearrangement") }}</h3>
            <!-- <div class="Intake-tab">
            <div class="Drivelis">
              <img src="./../../assets/images/qdzz1.png" />
              <span
                class="btn"
                @click="choBtn()"
                :style="{
                  color: fontColor,
                  backgroundColor: backColor,
                  border: borderColor,
                }"
                >{{ $t("choose") }}</span
              >
            </div>
            <div class="Drivelis">
              <img src="./../../assets/images/qdzz2.png" />
              <span class="btn">{{ $t("choose") }}</span>
            </div>
            <div class="Drivelis">
              <img src="./../../assets/images/qdzz3.png" />
              <span class="btn">{{ $t("choose") }}</span>
            </div>
            <div class="Drivelis">
              <img src="./../../assets/images/qdzz4.png" />
              <span class="btn">{{ $t("choose") }}</span>
            </div>
            <div class="Drivelis">
              <img src="./../../assets/images/qdzz5.png" />
              <span class="btn">{{ $t("choose") }}</span>
            </div>
          </div> -->
            <div class="Intake-tab">
              <div class="Drivelis" v-for="(item, i) in driveList" :key="i">
                <img :src="item.imgsrc" />
                <span
                  class="btn"
                  :class="isDrive == i ? 'btncolor' : ''"
                  @click="driveClick(i)"
                  >{{ $t("choose") }}</span
                >
              </div>
            </div>
          </div>

          <div class="Customer1">
            <h3 style="text-align: left">
              {{ $t("unloading") }}
            </h3>
            <div class="Discharge">
              <div class="rows">
                <div></div>
                <div>
                  <span
                    class="btn"
                    :class="isBtnShow == 'B' ? 'DisBtn' : ''"
                    @click="BBtn('B')"
                    >B</span
                  >
                </div>
                <div>
                  <span
                    class="btn"
                    :class="isBtnShow == 'A' ? 'DisBtn' : ''"
                    @click="ABtn('A')"
                    >A
                  </span>
                </div>
                <div></div>
              </div>
              <div class="rows">
                <div>
                  <span
                    class="btn"
                    :class="isBtnShow == 'C' ? 'DisBtn' : ''"
                    @click="CBtn('C')"
                    >C</span
                  >
                </div>
                <div></div>
                <div></div>
                <div>
                  <span
                    class="btn"
                    :class="isBtnShow == 'K' ? 'DisBtn' : ''"
                    @click="KBtn('K')"
                    >K</span
                  >
                </div>
              </div>
              <div class="rows">
                <div>
                  <span
                    class="btn"
                    :class="isBtnShow == 'D' ? 'DisBtn' : ''"
                    @click="DBtn('D')"
                    >D</span
                  >
                </div>
                <div></div>
                <div></div>
                <div>
                  <span
                    class="btn"
                    :class="isBtnShow == 'G' ? 'DisBtn' : ''"
                    @click="GBtn('G')"
                    >G</span
                  >
                </div>
              </div>
              <div class="rows">
                <div></div>
                <div>
                  <span
                    class="btn"
                    :class="isBtnShow == 'E' ? 'DisBtn' : ''"
                    @click="EBtn('E')"
                    >E</span
                  >
                </div>
                <div>
                  <span
                    class="btn"
                    :class="isBtnShow == 'F' ? 'DisBtn' : ''"
                    @click="FBtn('F')"
                    >F</span
                  >
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="submit">
            <span @click="LogindialogVisible = true">{{ $t("submit") }}</span>
          </div>
        </el-form>
      </div>
    </div>

    <mainProduct />
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />

    <el-dialog
      :append-to-body="true"
      :visible.sync="LogindialogVisible"
      class="EngineeringServiceDia"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="EngineeringServiceform">
        <el-form ref="userInfo" :rules="ccc" :model="userInfo">
          <!-- <el-form-item :label="$t('name')" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item> -->
          <el-form-item :label="$t('Email')" prop="Email">
            <el-input v-model="userInfo.Email"></el-input>
          </el-form-item>
          <!-- <el-form-item :label="$t('Compamy')" prop="company">
            <el-input v-model="form.name"></el-input>
          </el-form-item> -->
          <el-form-item :label="$t('Telephone')" prop="Telephone">
            <el-input v-model="userInfo.Telephone"></el-input>
          </el-form-item>
          <div style="text-align: center">
            <el-button type="danger" @click="saveImage('userInfo')">{{
              $t("submit")
            }}</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";
// 主要产品
import mainProduct from "./../../components/EngineeringService/mainproduct.vue";
import img1 from "./../../assets/images/banner1.jpg";
import html2canvas from "html2canvas";
export default {
  name: "About",
  components: {
    Company,
    tFooter,
    Carousel,
    mainProduct,
  },
  props: {},
  data() {
    return {
      isBtnShow: -1,
      isDrive: -1,
      driveList: [
        {
          imgsrc: require("@/assets/images/qdzz2.png"),

          value: 2,
        },
        {
          imgsrc: require("@/assets/images/qdzz3.png"),

          value: 3,
        },
        {
          imgsrc: require("@/assets/images/qdzz4.png"),

          value: 4,
        },
        {
          imgsrc: require("@/assets/images/qdzz5.png"),

          value: 5,
        },
        {
          imgsrc: require("@/assets/images/qdzz1.png"),
          value: 1,
        },
      ],
      radio: "1",
      ccc: {
        Email: [
          { required: true, message: "请输入邮箱", trigger: "change" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        Telephone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "change",
          },
          {
            min: 9,
            max: 12,
            required: true,
            message: "请正确输入联系电话",
            trigger: "change",
          },
        ],
      },
      LogindialogVisible: false,
      formInline: {
        user: "",
        region: "",
        application: "", //泵的应用
        OperationTime: "", //运作时间
        run: "", //运行
        support: "", //支持
        min1: "", //质量流率最小值
        norm1: "", //质量流率标准
        max1: "", //质量流率最大值
        min2: "", //泥浆流量最小值
        norm2: "", //泥浆流量标准
        max2: "", //泥浆流量最大值
        SealType: "", //密封类型
        proportion: "", //浆液比重
        liquid: "", //液体类型
        solid: "", //固体比重
        proportionLiquid: "", //液体比重
        concentration: "", //固体浓度
        oil: "", //油品的存在
        NaturalGas: "", //石油天然气
        biggest: "", //最大粒径
        thePH: "", //PH值
        average: "", //平均粒度
        temperature: "", //温度
        pumpSerous: "", //泵的安装浆液比重
        pumpLiquid: "", //泵的安装液体类型
        pumpSolid: "", //泵的安装固体比重
        pumpProportion: "", //泵的安装液体比重
        intakeLength: "", //摄入长度
        intakeLength1: "",
        intakeLength2: "",
        dischargePc: "", //放电PC
        dischargePc1: "",
        dischargePc2: "",
        dischargeLength: "", //放电长度
        dischargeLength1: "",
        dischargeLength2: "",
        intakeDiameter: "", //摄入内径
        intakeDiameter1: "",
        intakeDiameter2: "",
        dischargeDiameter: "", //放电内径
        dischargeDiameter1: "",
        dischargeDiameter2: "",
        intakeValve: "", //摄入阀门
        intakeValve1: "",
        intakeValve2: "",
        dischargeValve: "", //放电阀门
        dischargeValve1: "",
        dischargeValve2: "",
        intakeBending: "", //摄入弯曲
        dischargeBending: "", //放电弯曲
        intakeBending1: "",
        dischargeBending1: "",
        intakeBending2: "",
        dischargeBending2: "",
        intakeMaterial: "", //摄入材料
        dischargeMaterial: "", //放电材料
        intakeA: "", //进气A
        intakeB: "", //进气B
        intakeC: "", //进气C
        intakeD: "", //进气D
        intakeE: "", //进气E
        intakeF: "", //进气F
        intakeG: "", //进气G
        intakePressure: "", //进气压力
      },
      // imgList: {
      //   url:img1,
      //   className: "fadeInUp animated textLeft",
      //   title: "Engineering Service",
      //   xtitle: "Provide one-stop solution and complete \n supporting service",
      // },
      form: {
        size: "", //尺寸
        size1: "",
        size2: "",
        size3: "",
        size4: "",
        size5: "",
        size6: "",
        keep: "", //保留
        keep1: "",
        keep2: "",
        keep3: "",
        keep4: "",
        keep5: "",
        keep6: "",
      },
      userInfo: {
        Telephone: "",
        Email: "sales@mizztech.com",
      },
      backColor: "",
      fontColor: "",
      borderColor: "",
    };
  },
  methods: {
    //保存图片
    saveImage(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.LogindialogVisible = false;
          html2canvas(document.querySelector("#capture")).then((canvas) => {
            const imgUrl = canvas.toDataURL("png"); // 获取生成的图片的url
            // console.log(imgUrl);
            var a = document.createElement("a"); // 生成一个a元素
            var event = new MouseEvent("click"); // 创建一个单击事件
            a.download = "datacollection"; // 设置图片名称
            a.href = imgUrl; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
            this.userInfo.Email = '',
            this.userInfo.Telephone = ''
          });
        } else {
          console.log("error submit!!");
          
          return false;
        }
      });
      
    },

    driveClick(index) {
      this.isDrive = index;
      // console.log(index);
    },
    ABtn(val) {
      this.isBtnShow = val;
      console.log(val);
    },
    BBtn(val) {
      this.isBtnShow = val;
    },
    CBtn(val) {
      this.isBtnShow = val;
    },
    DBtn(val) {
      this.isBtnShow = val;
    },
    EBtn(val) {
      this.isBtnShow = val;
    },
    FBtn(val) {
      this.isBtnShow = val;
    },
    GBtn(val) {
      this.isBtnShow = val;
    },
    KBtn(val) {
      this.isBtnShow = val;
      console.log(val);
    },
  },
  computed: {
    imgList() {
      return {
        url: img1,
        className: "fadeInUp animated textLeft",
        title: this.$t("EngineeringService"),
        xtitle: this.$t("engineeXtitle"),
      };
    },
  },
  created() {},
};
</script>

<style lang="less">
.Btn {
  background-color: #d01f27;
}
.btncolor {
  background-color: #d01f27;
}
.borderRt {
  border-right: 1px solid #000;
}

.EngineeringServiceDia {
  @media screen and (min-width: 766px) {
    .el-dialog {
      width: 580px;
      // height: 582px;

      .EngineeringServiceform {
        padding: 33px 43px;
        .el-form {
          width: 493px;
          height: 100%;
          .is-required {
            .el-form-item__label {
              &::before {
                content: none;
              }

              &::after {
                content: "*";
                color: #f56c6c;
                margin-left: 10px;
              }
            }
          }
          .el-form-item__label {
            font-size: 20px;
          }

          .el-input__inner {
            height: 50px;
          }
          button {
            padding: 12px 100px;
            font-size: 20px;
            &:hover {
              width: auto;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .el-dialog {
      width: 580px;
      // height: 582px;

      .EngineeringServiceform {
        padding: 33px 43px;

        .el-form {
          width: 493px;
          height: 100%;
          .is-required {
            .el-form-item__label {
              &::before {
                content: none;
              }

              &::after {
                content: "*";
                color: #f56c6c;
                margin-left: 10px;
              }
            }
          }
          .el-form-item__label {
            font-size: 20px;
          }

          .el-input__inner {
            height: 50px;
          }
          button {
            padding: 12px 100px;
            font-size: 20px;
            &:hover {
              width: auto;
            }
          }
        }
      }
    }
  }
}

.EngineeringService {
  @media screen and (max-width: 480px) {
    .forms {
      margin: 0 156px;
      padding: 50px;
      background: #f7f7f7;
      border: 1px solid #ffffff;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
      border-radius: 20px;
      // opacity: 0.4;

      h3 {
        margin: 20px 0 30px 0;
        text-align: center;
        font-size: 48px;
        color: #333;
      }

      h5 {
        text-align: center;
        font-size: 42px;
        color: #000;
        margin: 0 0 30px 0;
      }

      h4 {
        text-align: center;
        font-size: 24px;
        color: #000;
      }

      .el-form-item__label {
        font-size: 28px;
        color: #333;
        // line-height: 50px;
        // height: 37px;
        // margin-top: 20px;
      }

      .el-input__inner {
        height: 80px;
        font-size: 35px;
      }

      .el-input-group__append {
        font-size: 24px;
        color: #333;
        background-color: #fff;
      }

      .el-input-group__prepend {
        background-color: #fff;
        font-size: 24px;
        color: #333;
      }

      .el-form-item__content {
        .btngroup {
          .el-radio-button {
            .el-radio-button__inner {
              line-height: 80px;
              padding: 7px 75px;
            }
          }

          .is-active {
            .el-radio-button__inner {
              color: #fff;
              background-color: #d12028;
              border-color: #000;
              box-shadow: 5px 0 5px 0;
            }
          }
        }
      }

      .Customer1 {
        h3 {
          margin: 50px 0;
        }

        span {
          font-size: 24px;
          color: #000;
          line-height: 50px;
        }
      }

      .Customer {
        h3 {
          // font-weight: normal;
          font-size: 50px;
        }
        h4 {
          font-weight: normal;
          font-size: 40px;
        }
        .el-form-item__label {
          width: 450px;
          padding-left: 50px;
          font-size: 35px;
        }

        .el-form-item__content {
          margin-left: 462px;

          span {
            font-size: 30px;
            color: #000;
            line-height: 80px;
            padding-left: 20px;
          }
        }
      }

      //
      .Analysis-tab {
        display: flex;
        border: 1px solid #000;

        &:last-child {
          border-top: none;
        }

        .el-form-item__label {
          width: 462px;
          text-align: center;
        }

        .el-form-item__content {
          flex: 1;
        }
      }

      //Discharge
      .Discharge {
        width: 300px;
        height: 300px;
        background: url(./../../assets/images/cfwyt.png) 0 0 no-repeat;
        background-size: cover;

        .rows {
          height: 66px;
          width: 264px;

          // &:last-child {
          //   .btn {
          //     margin-top: 22px;
          //   }

          // }

          // &:first-child {
          //   .btn {
          //     margin-bottom: 22px;
          //   }
          // }

          div {
            width: 66px;
            height: 66px;
            float: left;
            text-align: center;

            .btn {
              margin: 15.5px;
              width: 42px;
              height: 42px;
              border-radius: 21px;
              border: 1px solid #000;
              display: inline-block;
              line-height: 42px;
              font-size: 24px;
              background: #fff;
              cursor: pointer;

              &:hover {
                background: #d01f27;
                color: #fff;
                border: 1px solid #707070;
              }
            }
          }
        }
      }

      // Intake
      .Intake {
        .Intake-tab {
          display: flex;
          border: 1px solid #000;
          // height: 480px;
          background: #fff;

          .Intakelis {
            flex: 1;

            &:not(:last-child) {
              border-right: 1px solid #000;
            }

            .con {
              height: 60px;
              font-size: 28px;
              text-align: center;
              line-height: 60px;

              .el-input {
                display: block;
              }
            }

            .title {
              height: 60px;
              font-size: 28px;
              text-align: center;
              line-height: 60px;

              .el-checkbox {
                height: 24px;
                line-height: 24px;
                top: -2px;

                .el-checkbox__inner {
                  width: 24px;
                  height: 24px;

                  &::after {
                    border: 3px solid #d01028;
                    border-left: 0;
                    border-top: 0;
                    left: 7px;
                    top: 5px;
                  }
                }

                .is-checked {
                  .el-checkbox__inner {
                    background-color: #fff;
                    border-color: #d01028;
                  }
                }
              }
            }

            .img {
              height: 360px;
              border: 1px solid #000;
              border-right: none;
              border-left: none;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .con {
            }
          }

          .Drivelis {
            flex: 1;
            height: 360px;

            &:not(:last-child) {
              border-right: 1px solid #000;
            }

            text-align: center;

            img {
              height: 200px;
              margin: 40px auto;
            }

            .btn {
              display: block;
              width: 138px;
              height: 49px;
              border: 1px solid #000;
              border-radius: 5px;
              font-size: 24px;
              text-align: center;
              line-height: 49px;
              margin: 0 auto;
              cursor: pointer;

              &:hover {
                background: #d01f27;
                color: #fff;
              }
            }
          }
        }
      }

      //
      .Installation {
        .btngroup {
          .el-radio-button {
            width: 50%;

            .el-radio-button__inner {
              line-height: 37px;
              padding: 7px 20px;
            }
          }
        }
      }

      //
      .submit {
        margin: 50px auto;
        span {
          display: block;
          background: #d01f27;
          color: #fff;
          width: 270px;
          height: 80px;
          border: 1px solid rgb(226, 3, 3);
          border-radius: 5px;
          font-size: 42px;
          text-align: center;
          line-height: 80px;
          margin: 0 auto;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .forms {
      margin: 0 156px;
      padding: 50px;
      background: #f7f7f7;
      border: 1px solid #ffffff;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
      border-radius: 20px;
      // opacity: 0.4;

      h3 {
        margin: 20px 0 30px 0;
        text-align: center;
        font-size: 48px;
        color: #333;
      }

      h5 {
        text-align: center;
        font-size: 42px;
        color: #000;
        margin: 0 0 30px 0;
      }

      h4 {
        text-align: center;
        font-size: 24px;
        color: #000;
      }

      .el-form-item__label {
        font-size: 28px;
        color: #333;
        line-height: 50px;
        height: 37px;
      }

      .el-input__inner {
        height: 50px;
        font-size: 20px;
      }

      .el-input-group__append {
        font-size: 24px;
        color: #333;
        background-color: #fff;
      }

      .el-input-group__prepend {
        background-color: #fff;
        font-size: 24px;
        color: #333;
      }

      .el-form-item__content {
        .btngroup {
          .el-radio-button {
            .el-radio-button__inner {
              line-height: 37px;
              padding: 7px 75px;
            }
          }

          .is-active {
            .el-radio-button__inner {
              color: #fff;
              background-color: #d12028;
              border-color: #000;
              box-shadow: 5px 0 5px 0;
            }
          }
        }
      }

      .Customer1 {
        h3 {
          margin: 50px 0;
        }

        span {
          font-size: 24px;
          color: #000;
          line-height: 50px;
        }
      }

      .Customer {
        .el-form-item__label {
          width: 412px;
          padding-left: 50px;
          font-size: 28px;
        }

        .el-form-item__content {
          margin-left: 462px;

          span {
            font-size: 24px;
            color: #000;
            line-height: 50px;
            padding-left: 20px;
          }
        }
      }

      //
      .Analysis-tab {
        display: flex;
        border: 1px solid #000;

        &:last-child {
          border-top: none;
        }

        .el-form-item__label {
          width: 462px;
          text-align: center;
        }

        .el-form-item__content {
          flex: 1;
        }
      }

      //Discharge
      .Discharge {
        width: 264px;
        height: 264px;
        //-webkit-transform: rotate(-180deg); 
        background: url(./../../assets/images/cfwyt.png) 0 0 no-repeat;
        background-size: 220px,220px;
        background-position: 20px 20px;
        .rows {
          height: 66px;
          width: 264px;

          // &:last-child {
          //   .btn {
          //     margin-top: 22px;
          //   }

          // }

          // &:first-child {
          //   .btn {
          //     margin-bottom: 22px;
          //   }
          // }

          div {
            width: 66px;
            height: 66px;
            float: left;
            text-align: center;

            .btn {
              margin: 15.5px;
              width: 42px;
              height: 42px;
              border-radius: 21px;
              border: 1px solid #000;
              display: inline-block;
              line-height: 42px;
              font-size: 24px;
              background-color: #fff;
              cursor: pointer;

              &:hover {
                background: #d01f27;
                color: #fff;
                border: 1px solid #707070;
              }
            }
            .DisBtn {
              background-color: #d01f27;
            }
          }
        }
      }

      // Intake
      .Intake {
        .Intake-tab {
          display: flex;
          border: 1px solid #000;
          // height: 480px;
          background: #fff;

          .Intakelis {
            flex: 1;

            &:not(:last-child) {
              border-right: 1px solid #000;
            }

            .con {
              height: 60px;
              font-size: 28px;
              text-align: center;
              line-height: 60px;

              .el-input {
                display: block;
              }
            }

            .title {
              height: 80px;
              font-size: 20px;
              text-align: center;
              line-height: 80px;

              .el-checkbox {
                height: 24px;
                line-height: 24px;
                top: -2px;

                .el-checkbox__inner {
                  width: 24px;
                  height: 24px;

                  &::after {
                    border: 3px solid #d01028;
                    border-left: 0;
                    border-top: 0;
                    left: 7px;
                    top: 5px;
                  }
                }

                .is-checked {
                  .el-checkbox__inner {
                    background-color: #fff;
                    border-color: #d01028;
                  }
                }
              }
            }

            .img {
              height: 360px;
              border: 1px solid #000;
              border-right: none;
              border-left: none;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .con {
            }
          }

          .Drivelis {
            flex: 1;
            height: 360px;

            &:not(:last-child) {
              border-right: 1px solid #000;
            }

            text-align: center;

            img {
              height: 200px;
              margin: 40px auto;
            }

            .btn {
              display: block;
              width: 138px;
              height: 49px;
              border: 1px solid #000;
              border-radius: 5px;
              font-size: 24px;
              text-align: center;
              line-height: 49px;
              margin: 0 auto;
              cursor: pointer;

              &:hover {
                background: #d01f27;
                color: #fff;
              }
            }
          }
        }
      }

      //
      .Installation {
        .btngroup {
          .el-radio-button {
            width: 50%;

            .el-radio-button__inner {
              line-height: 37px;
              padding: 7px 20px;
            }
          }
        }
      }

      //
      .submit {
        margin: 50px auto;
        span {
          display: block;
          background: #d01f27;
          color: #fff;
          width: 270px;
          height: 80px;
          border: 1px solid rgb(226, 3, 3);
          border-radius: 5px;
          font-size: 42px;
          text-align: center;
          line-height: 80px;
          margin: 0 auto;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

