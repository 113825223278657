<template>
  <div class="box">
    <div class="information_info" v-show="bigShow">
      <div class="info_con1 info_con" v-for="item in personnel" :key="item.name">
        <div class="pv0"><img :src="item.img" /></div>
        <div class="pv1">{{ item.name }}</div>
        <div class="pv2">{{ $t(item.Manager) }}</div>
        <div class="pv3">
          {{  $t(item.motto) }}
        </div>
      
        <div class="pv4">{{ item.Email }}</div>
      </div>
    </div>
    <div class="lunbo">
      <div class="block">
        <el-carousel height="300px">
          <el-carousel-item v-for="item in personnel" :key="item.name">
            <div class="information">
              <div class="motto">{{ $t(item.motto) }}</div>
              <img :src="item.img" alt="" />
              <div class="itname">{{ item.name }}</div>
              <div class="manager">{{ $t(item.Manager) }}</div>
              <!-- <div class="mobile">{{ item.Mobile }}</div> -->
              <div class="itema">{{ item.Email }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import elementResizeDetector from "element-resize-detector";
export default {
  name: "",
  props: {},
  data() {
    return {
      bigShow: "",
      smallShow: "",
      windowHeight: null,
      windowWidth: null,
      personnel:'',
    };
  },
  mounted() {
    const that = this;
    that.personnel=window.g.personnel
    var erd = elementResizeDetector();
    erd.listenTo(document.getElementsByClassName("box"), (element) => {
      //监听的domdocument.get方法获取
      that.windowWidth = element.offsetWidth;
      that.windowHeight = element.offsetHeight;
      that.$nextTick(() => {
        // console.log("宽：" + that.windowWidth, "高：" + that.windowHeight);
        // 这里填写监听改变后的操作
        if (that.windowWidth < 645) {
          that.smallShow = true;
          that.bigShow = false;
        } else {
          that.smallShow = false;
          that.bigShow = true;
        }
      });
    });
  },

  created() {},
};
</script>

<style lang="less">
@media screen and (max-width: 766px) {
  .information_info {
    margin: 10px -50px;
    width: 1100px;
    height: 6950px;
    border: 1px solid #000;
    font-size: 4.7rem;
    //.contsctUs{
    //  .information{
    //    .information_info{
    //      .info_con{
    //        .pv0 img{
    //          width: 48.0625rem;
    //          height: 1000px;
    //        }
    //      }
    //    }
    //  }
    //}
    img {
      margin: 50px 1.95rem;
      width: 1550px;
      height: 1300px;
    }
    .info_con1 {
      width: 100%;
      height: 800px;
      position: relative;
      left: 10px;
      top: 20px;
    }
    .info_con2 {
      width: 100%;
      height: 1000px;
      position: relative;
      left: -1600px;
      top: 153.5rem;
    }
    .info_con3 {
      width: 100%;
      height: 1000px;
      position: relative;
      left: -200.8125rem;
      top: 297.5rem;
    }
  }
  .lunbo {
    .el-carousel__indicator--horizontal.is-active button {
      background: #fb0000;
      width: 35px;
      height: 35px;
    }
    .el-carousel__indicator--horizontal button {
      background-color: #fff;
      border: 1px solid red;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
    .information {
      color: #666;
      text-align: center;
      font-style: 50px;
      img {
        height: 400px;
        width: 430px;
        border-radius: 50%;
      }
      .itname {
        font-weight: 900;
        font-size: 70px;
      }
      div {
        margin: 20px;
      }
      .motto {
        font-size: 50px;
      }
      .mobile {
        font-size: 50px;
      }
      .manager {
        font-size: 50px;
      }

      .itema {
        font-size: 50px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .lunbo {
    display: none;
  }
}
</style>

