<template>
  <div>
    <!--轮播图 -->
    <Carpusel :list="this.loop" />
    <!-- 主要配件 -->
    <mainAccessories />
    <!-- 产品 -->
    <mainProduct />
    <!-- Technical -->
    <Technial :technialList="this.list" />
    <!-- 案例 -->
    <someCase />
    <!-- remoview -->
    <review />
    <!-- our news -->
    <ourNews />
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>
<script>
// 轮播图
import Carpusel from "../components/Home/Carousel/Carousel.vue";
// 配件
import mainAccessories from "../components/Home/mainAccessories/mainAccessories.vue";
// 产品
import mainProduct from "../components/Home/mainProduct/mainProduct.vue";
// Technical
import Technial from "../components/Home/Technial/Technial.vue";
// 案例
import someCase from "../components/Home/someCase/someCase.vue";
// review
import review from "../components/Home/review/review.vue";
// our news
import ourNews from "../components/Home/ourNews/ourNews.vue";

// <!-- 公司基本简介链接 -->
import Company from "../components/Company/Company.vue";
// 底部信息
import tFooter from "../components/footer/footer.vue";
import { loop } from "../api/api";
export default {
  name: "Index",
  components: {
    Carpusel,
    mainAccessories,
    mainProduct,
    Technial,
    someCase,
    review,
    ourNews,
    Company,
    tFooter,
  },
  data() {
    return {
      lang: "", //从sessionStorage中获取的语言标识符
      loopParam: {
        current: 1,
        size: 100,
        billStat: 1,
      },
      listParam: {
        current: 1,
        size: 100,
        billStat: 1,
      },
      list: [], //工程服务数组
      loop: [], //广告轮播数组
    };
  },
  filters: {
    imglist(val) {
      val = val.slice(-2);
      return val;
    },
  },
  methods: {
    //广告轮播图方法
    swiper1() {
      // debugger
      loop(this.loopParam).then((res) => {
        this.loop = [];
        this.list = [];
        res.data.records.forEach((item) => {
          if (item.kindName == "页头轮播 1920*690px") {
            if (this.lang == null) {
              this.loop.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1en,
                xtitle: "",
              });
            } else if (this.lang === "zh") {
              //中文
              this.loop.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billName,
                xtitle: "",
              });
            } else if (this.lang === "en") {
              //英文
              this.loop.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1en,
                xtitle: "",
              });
            } else if (this.lang === "fr") {
              //法语
              this.loop.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1fr,
                xtitle: "",
              });
            } else if (this.lang === "ru") {
              //俄语
              this.loop.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1ru,
                xtitle: "",
              });
            } else if (this.lang === "es") {
              //西班牙语
              this.loop.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1es,
                xtitle: "",
              });
            } else if (this.lang === "ar") {
              //阿拉伯语
              this.loop.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1ar,
                xtitle: "",
              });
            } else if (this.lang === "id") {
              //印尼语
              this.loop.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1id,
                xtitle: "",
              });
            } else if (this.lang === "tr") {
              //土耳其语
              this.loop.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1tr,
                xtitle: "",
              });
            }
          } else if (item.kindName == "服务 630*435px") {
            if (this.lang == null) {
              this.list.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1en,
                xtitle: "",
              });
            } else if (this.lang === "zh") {
              //中文
              this.list.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billName,
                xtitle: "",
              });
            } else if (this.lang === "en") {
              //英文
              this.list.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1en,
                xtitle: "",
              });
            } else if (this.lang === "fr") {
              //法语
              this.list.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1fr,
                xtitle: "",
              });
            } else if (this.lang === "ru") {
              //俄语
              this.list.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1ru,
                xtitle: "",
              });
            } else if (this.lang === "es") {
              //西班牙语
              this.list.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1es,
                xtitle: "",
              });
            } else if (this.lang === "ar") {
              //阿拉伯语
              this.list.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1ar,
                xtitle: "",
              });
            } else if (this.lang === "id") {
              //印尼语
              this.list.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1id,
                xtitle: "",
              });
            } else if (this.lang === "tr") {
              //土耳其语
              this.list.push({
                url: this.$global.imgUrl + item.billImg,
                className: "fadeInUp animated",
                title: item.billTxt1tr,
                xtitle: "",
              });
            }
          }
        });
      });
    },
  },
  mounted() {
    //调用广告轮播图方法
    this.swiper1();
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      console.log(this.lang);
      //语言改变 重新去请求轮播图数据
      this.swiper1();
    });
  },
};
</script> 
<style lang="less">
@media screen and (max-width: 766px) {
  .el-message--warning {
    // top: 80px !important;
    // height: 500px;
    // width: 1200px;
  }
  .el-message .el-icon-warning {
    font-size: 80px;
  }
  .el-message--warning .el-message__content {
    font-size: 80px;
    font-weight: 700;
    padding: 30px;
  }

  .el-message .el-icon-error {
    font-size: 80px;
  }
  .el-message--error .el-message__content {
    font-size: 80px;
    font-weight: 700;
    padding: 30px;
  }

  .el-message .el-icon-success {
    font-size: 80px;
  }
  .el-message--success .el-message__content {
    font-size: 80px;
    font-weight: 700;
    padding: 30px;
  }
}
</style>
