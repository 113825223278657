<template>
  <div class="ProductDetails contsctUs">
    <Carousel :imgList="imgList" />

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{
          $t("Home")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/Products' }">{{
          $t("productCenter")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ detail.goodsName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="details">
      <div class="img">
        <div class="imgbox"><img :src="this.detail.goodsImg" alt="" /></div>
        <div class="imglis">
          <swiper class="flashBg" :options="swiperOption" ref="mySwiper">
            <swiper-slide
              class="gimgv"
              v-for="(item, index) in newArr"
              :key="index"
            >
              <img
                @click="imgClick(index)"
                :src="item.img"
                style="width: 100%; height: 100%"
              />
            </swiper-slide>
          </swiper>
          <div class="newarrow but_left" slot="button-prev"></div>
          <div class="newarrow but_right" slot="button-next"></div>
        </div>
      </div>
      <div class="meny">
        <h3>{{ detail.goodsName }}</h3>
        <div class="rate">
          <el-rate
            :colors="['#99A9BF', '#F7BA2A', '#d0111b']"
            v-model="value"
            disabled
          >
          </el-rate>
          <span class="btn" @click="goAnchor('#vital1')">{{
            $t("viewreviews")
          }}</span>
        </div>
        <!-- 商品信息 -->
        <div class="spxx">
          <div class="spxxlis">
            <span class="title">{{ $t("Applications") }}:</span>
            <span class="con">{{ this.language | describe2(this.detail) }}</span>
          </div>
          <div class="spxxlis">
            <span class="title">{{ $t("Brand") }}:</span>
            <span class="con">Mizztech</span>
          </div>
          <div class="spxxlis">
            <span class="title">{{ $t("ProductNumber") }}:</span>
            <!-- <span class="con">
              {{ detail.goodsId }}
            </span> -->
            <el-dropdown
              class="dropdownPop"
              trigger="click"
              @command="handleCommand"
            >
              <span class="el-dropdown-link">
                {{ detail.goodsVision
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, indx) in this.accessories"
                  :key="indx"
                  :command="item.partId"
                  style="font-size: 2.3rem; line-height: normal"
                  >{{ item.goodsPartNumber }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="spxxlis">
            <span class="title">{{ $t("Manufacturer") }}:</span>
            <span class="con">Mizztech</span>
          </div>
          <div class="spxxlis">
            <span class="reviews" @click="LogindialogVisible = true">{{
              $t("Addreviews")
            }}</span>
          </div>
          <!--按钮-->
          <div class="spxxlis">
            <span class="btn" @click="isSHow = !isSHow">{{
              $t("RequestQoute")
            }}</span>
          </div>
          <div class="xjck" v-if="isSHow">
            <el-form ref="form" :model="form" :rules="rules">
              <el-form-item :label="$t('name')" prop="inquiryName">
                <el-input v-model="form.inquiryName"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Email')" prop="inquiryEmail">
                <el-input v-model="form.inquiryEmail"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Message')" prop="inquiryMemo">
                <el-input type="textarea" v-model="form.inquiryMemo"></el-input>
              </el-form-item>
              <div style="text-align: center">
                <el-button type="danger" @click="submit">{{
                  $t("submit")
                }}</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <!-- More Information -->
    <div class="Information">
      <h3>{{ $t("MoreInformation1") }}</h3>
      <div
        v-if="
          this.detail.goodsMemozh != null ||
          this.detail.goodsMemozh != undefined
        "
        class="goodscontent"
      >

        <pre class="detail">{{this.language | describe(this.detail)}}</pre>

      </div>
      <el-empty :description="$t('Nofurther')" v-else></el-empty>
      <h4 id="vital1">{{ $t("ProductReviews") }}</h4>
      <div class="ProductReviews">
        <div
          class="ProductReviewsLis"
          v-for="(item, index) in commetList"
          :key="index"
        >
          <div>
            <!-- <el-avatar :src="uImg + item.usersImg" fit="fill"></el-avatar> -->
            <img :src="uImg + item.usersImg" class="commentsavatar" />

            <span class="title">{{ item.userName }}</span>
            <el-rate
              :colors="['#99A9BF', '#F7BA2A', '#d0111b']"
              v-model="item.reviewStar"
              disabled
            >
            </el-rate>
          </div>
          <div class="con">
            {{ item.reviewMemo }}
          </div>
        </div>
      </div>
      <!--      分页器-->
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="commetParams.current"
          :total="this.total"
          :page-size="this.size"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="EngineeringService">
      <mainProduct />
    </div>
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />

    <el-dialog
      :append-to-body="true"
      title=""
      :visible.sync="LogindialogVisible"
      class="productsTailsDia"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :closed="hanledClose"
    >
      <div class="productsTailsform">
        <h3>{{ $t("ProductReviews") }}</h3>
        <el-form ref="form" :model="form">
          <el-rate :colors="['#99A9BF', '#F7BA2A', '#d0111b']" v-model="value">
          </el-rate>
          <el-form-item label="" prop="">
            <el-input type="textarea" v-model="add.reviewMemo"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <el-button type="danger" @click="commet">{{
            $t("submit")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// 主要产品
import mainProduct from "./../../components/EngineeringService/mainproduct.vue";
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";
import img1 from "./../../assets/images/banner1.jpg";
//导入api方法
import {
  getDetail,
  addSppl,
  getsppl,
  xundan,
  getGoodsClick,
} from "../../api/api";
export default {
  name: "About",
  components: {
    swiper,
    swiperSlide,
    Company,
    tFooter,
    Carousel,
    mainProduct,
  },
  props: {},
  data() {
    return {
      uImg: "",
      accessories: [],
      Newaccessories: [],
      userId: "",
      language: "",
      size: 5,
      spid: "", //商品id
      goodsDescription: "", //更多描述
      usersNick: "",
      total: 0, //评论总条数
      commetList: [], //评论数据
      commetParams: {
        current: 1,
        size: 5,
      }, //查询评论的参数
      add: {
        reviewMemo: "", //评论内容
      },
      lang: "", //获取系统语言
      newArr: [], //图片数组
      detail: {
        goodsName: "", //名字
        goodsDescimg: "", //用途
      }, //详情页数据
      isSHow: false,
      // rules: {
      //   name: [{ required: true, message: "请输入名字", trigger: "blur" }],
      // },
      form: {
        inquiryName: "",
        inquiryEmail: "",
        inquiryMemo: "",
      }, //询单要传的参数
      LogindialogVisible: false,
      value1: "",
      value: 5,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 20,
        loop: true,
        autoplay: false,
        // 设置点击箭头
        navigation: {
          nextEl: ".but_left",
          prevEl: ".but_right",
        },
        on: {
          click: (swiper) => {
            console.log(swiper);
          },
        },
      },
      rules: {
        inquiryName: [{ required: true, message: this.$t("Namelimit") }],
        inquiryEmail: [
          { required: true, message: this.$t("perfeccionarMessage1") },
        ],
        inquiryMemo: [
          {
            required: true,
            message: this.$t("Msglimit"),
          },
        ],
      },
    };
  },
  filters: {
    describe(language, detail) {
      if (language == "zh") {
        console.log(detail, "aaa");

        return detail.goodsMemozh;
      } else if (language == "en") {
        return detail.goodsMemoen;
      } else if (language == "es") {
        return detail.goodsMemoes;
      } else if (language == "fr") {
        return detail.goodsMemofr;
      } else if (language == "id") {
        return detail.goodsMemoid;
      } else if (language == "ru") {
        return detail.goodsMemoru;
      } else if (language == "ar") {
        return detail.goodsMemoar;
      } else {
        return detail.goodsMemotr;
      }
    },
    describe2(language, detail) {
      console.log(detail, "bbb");
      if (language == "zh") {
        return detail.goodsApplicationzh;
      } else if (language == "en") {
        return detail.goodsApplicationen;
      } else if (language == "es") {
        return detail.goodsApplicationes;
      } else if (language == "fr") {
        return detail.goodsApplicationfr;
      } else if (language == "id") {
        return detail.goodsApplicationid;
      } else if (language == "ru") {
        return detail.goodsApplicationru;
      } else if (language == "ar") {
        return detail.goodsApplicationar;
      } else {
        return detail.goodsApplicationtr;
      }
    },
    // goodsname(val) {
    //   if (val.length > 10) {
    //     val = val.slice(0, 2) + "...";
    //   }
    //   return val;
    // },
  },
  computed: {
    imgList() {
      if (this.$route.query.crusher == 1) {
        return {
          url: img1,
          className: "fadeInUp animated textLeft",
          title: this.$t("Crusher"),
          xtitle: "",
        };
      } else {
        return {
          url: img1,
          className: "fadeInUp animated textLeft",
          title: this.$t("slurryPump"),
          xtitle: "",
        };
      }
    },
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    // 获取商品浏览
    getGoodsId() {
      if (this.$cookies.get("GoodsId") == null) {
        // 调用接口
        getGoodsClick(this.spid)
          .then((res) => {
            
          })
          .catch((err) => {
            console.log(err);
          });
        this.$cookies.set("GoodsId", false);
      }
    },
    //询单
    submit() {
      var inquiryName = /^.{1,60}$/;
      var inquiryEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      var inquiryMemo = /^.{5,200}$/;

      if (!inquiryName.test(this.form.inquiryName)) {
        this.$message({
          message: this.$t("Namelimit"),
          type: "warning",
        });
      } else if (!inquiryEmail.test(this.form.inquiryEmail)) {
        this.$message({
          message: this.$t("perfeccionarMessage1"),
          type: "warning",
        });
      } else if (!inquiryMemo.test(this.form.inquiryMemo)) {
        this.$message({
          message: this.$t("Msglimit"),
          type: "warning",
        });
      } else {
        //没登录先登录判断
        let token = localStorage.getItem("token");
        if (token == null) {
          this.$message({
            message: this.$t("loginTips"),
            type: "success",
          });
        } else {
          var emailRegExp = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; //验证邮箱的正则表达式
          var ok = emailRegExp.test(this.form.inquiryEmail); //验证是否符合要求
          if (ok) {
            //调询单接口
            var userinfo = "userinfo";
            var parmas = {
              inquiryEmail: this.form.inquiryEmail,
              inquiryMemo: this.form.inquiryMemo,
              inquiryName: this.form.inquiryName,
              inquiryGoodsid: this.spid,
              inquiryUserid: userinfo.userid,
            };

            xundan(parmas).then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: "询单成功",
                  type: "success",
                });
                this.isSHow = false; //关闭询单框
              }
            });
          } else {
            this.$message({
              message: "邮箱格式不正确",
              type: "error",
            });
          }
        }
      }
    },
    //改变分页
    handlePageChange(currentPage) {
      this.commetParams.current = currentPage;
      //在此刷新数据
      //获取商品列表
      this.getCommet();
    },
    //查询评论
    // getCommet() {
    //   getsppl(this.commetParams).then((res) => {
    //     
    //     if (res.status == 200) {
    //       this.total = (Number(res.data.total) / 5) * 10; //总页数赋值
    //       this.commetList = res.data.records;
    //     } else {
    //       this.$message({
    //         message: res.data.errMsg,
    //         type: "warning",
    //       });
    //     }
    //   });
    // },

    getCommet() {
      var parmas = {
        goodsId: this.spid,
        size: this.size,
        current: this.commetParams.current,
      };
      getsppl(parmas)
        .then((res) => {
          console.log(res, "评论");
          if (res.status == 200) {
            this.total = res.data.total;
            this.size = res.data.size;
            // this.total = ((res.data.total) / 5) * 10;
            // this.total = (Number(res.data.total) / 5) * 10; //总页数赋值
            this.commetList = res.data.records;
            this.uImg = baseUrl;
            console.log(
              this.uImg + this.commetList[0].usersImg,
              "-----this.commetList"
            );
            console.log(this.commetList, "-----this.commetList");
          } else {
            this.$message({
              message: res.data.errMsg,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加评论
    commet() {
      // debugger
      if (this.add.reviewMemo == undefined) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else if (this.add.reviewMemo == "") {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else {
        let token = localStorage.getItem("token");
        if (token == null) {
          this.$message({
            message: this.$t("loginTips"),
            type: "success",
          });
        } else {
          // var userinfo = "userinfo";
          let userinfo = localStorage.getItem("userinfo");
          console.log(
            JSON.parse(userinfo).usersId,
            "JSON.parse(userinfo).usersId"
          );
          this.userId = JSON.parse(userinfo).usersId;
          var parmas = {
            reviewMemo: this.add.reviewMemo,
            reviewGoodsid: this.spid,
            reviewUsersid: this.userId,
            reviewStar: this.value,
          };

          addSppl(parmas).then((res) => {
            // debugger
            if (res.status == 200) {
              this.$message({
                message: res.data.errMsg,
                type: "success",
              });
              this.LogindialogVisible = false;
              this.getCommet();
            } else {
              this.$message({
                message: res.data.errMsg,
                type: "warning",
              });
            }
          });
        }
      }
      this.add.reviewMemo = "";
    },
    hanledClose() {
      this.add.reviewMemo = "";
      this.value = 0;
    },
    //锚点跳转
    goAnchor(selector) {
      /*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
    //图片点击
    imgClick(index) {
      console.log(index);
      this.detail.goodsImg = this.newArr[index].img;
    },
    //下拉
    handleCommand(command) {
      // this.getDetail(command);
      console.log(command, "---------------command");
      getDetail(command).then((res) => {
        if (res.status == 200) {
          this.detail = res.data.data;

          this.detail.goodsImg = this.$global.imgUrl + this.detail.goodsImg;
          let imgArr = this.detail.goodsImg0.split(",");
          imgArr.forEach((item) => {
            this.newArr.push({
              img: this.$global.imgUrl + item,
            });
          });
          if (this.lang == null) {
            this.detail.goodsName = res.data.data.goodsNameen;
            this.detail.goodsDescimg = res.data.data.goodsDescimgen;
            this.detail.goodsDescription = res.data.data.goodsMemoen;
          } else if (this.lang === "zh") {
            //中文
            this.detail.goodsName = res.data.data.goodsNamezh;
            this.detail.goodsDescimg = res.data.data.goodsDescimgzh;
            this.detail.goodsDescription = res.data.data.goodsMemozh;
          } else if (this.lang === "en") {
            //英文
            this.detail.goodsName = res.data.data.goodsNameen;
            this.detail.goodsDescimg = res.data.data.goodsDescimgen;
            this.detail.goodsDescription = res.data.data.goodsMemozhen;
          } else if (this.lang === "fr") {
            //法语
            this.detail.goodsName = res.data.data.goodsNamefr;
            this.detail.goodsDescimg = res.data.data.goodsDescimgefr;
            this.detail.goodsDescription = res.data.data.goodsMemofr;
          } else if (this.lang === "ru") {
            //俄语
            this.detail.goodsName = res.data.data.goodsNameru;
            this.detail.goodsDescimg = res.data.data.goodsDescimgru;
            this.detail.goodsDescription = res.data.data.goodsMemozhru;
          } else if (this.lang === "es") {
            //西班牙语
            this.detail.goodsName = res.data.data.goodsNamees;
            this.detail.goodsDescimg = res.data.data.goodsDescimges;
            this.detail.goodsDescription = res.data.data.goodsMemozher;
          } else if (this.lang === "ar") {
            //阿拉伯语
            this.detail.goodsName = res.data.data.goodsNamear;
            this.detail.goodsDescimg = res.data.data.goodsDescimgar;
            this.detail.goodsDescription = res.data.data.goodsMemozhar;
          } else if (this.lang === "id") {
            //印尼语
            this.detail.goodsName = res.data.data.goodsNameid;
            this.detail.goodsDescimg = res.data.data.goodsDescimgid;
            this.detail.goodsDescription = res.data.data.goodsMemozhid;
          } else if (this.lang === "tr") {
            //土耳其语
            this.detail.goodsName = res.data.data.goodsNametr;
            this.detail.goodsDescimg = res.data.data.goodsDescimgtr;
            this.detail.goodsDescription = res.data.data.goodsMemozhtr;
          }
          if (this.detail.goodsDescimg == "") {
            this.detail.goodsDescimg = "暂无描述";
          }
        }
      });
    },
    //获取详情
    getDetail(partId) {
      // console.log(partId, "----------------");
      if (partId != undefined) {
        this.spid = partId;
      } else {
        this.spid = this.$route.query.id;
        this.start = this.$route.query.start;
      }
      getDetail(this.spid).then((res) => {
        if (res.status == 200) {
          this.detail = res.data.data;
          this.accessories = res.data.data.goodsPart;
          this.detail.goodsImg = this.$global.imgUrl + this.detail.goodsImg;
          let imgArr = this.detail.goodsImg0.split(",");
          imgArr.forEach((item) => {
            this.newArr.push({
              img: this.$global.imgUrl + item,
            });
          });
          if (this.lang == null) {
            this.detail.goodsName = res.data.data.goodsNameen;
            this.detail.goodsDescimg = res.data.data.goodsDescimgen;
            this.detail.goodsDescription = res.data.data.goodsMemoen;
          } else if (this.lang === "zh") {
            //中文
            this.detail.goodsName = res.data.data.goodsNamezh;
            this.detail.goodsDescimg = res.data.data.goodsDescimgzh;
            this.detail.goodsDescription = res.data.data.goodsMemozh;
          } else if (this.lang === "en") {
            //英文
            this.detail.goodsName = res.data.data.goodsNameen;
            this.detail.goodsDescimg = res.data.data.goodsDescimgen;
            this.detail.goodsDescription = res.data.data.goodsMemozhen;
          } else if (this.lang === "fr") {
            //法语
            this.detail.goodsName = res.data.data.goodsNamefr;
            this.detail.goodsDescimg = res.data.data.goodsDescimgefr;
            this.detail.goodsDescription = res.data.data.goodsMemofr;
          } else if (this.lang === "ru") {
            //俄语
            this.detail.goodsName = res.data.data.goodsNameru;
            this.detail.goodsDescimg = res.data.data.goodsDescimgru;
            this.detail.goodsDescription = res.data.data.goodsMemozhru;
          } else if (this.lang === "es") {
            //西班牙语
            this.detail.goodsName = res.data.data.goodsNamees;
            this.detail.goodsDescimg = res.data.data.goodsDescimges;
            this.detail.goodsDescription = res.data.data.goodsMemozher;
          } else if (this.lang === "ar") {
            //阿拉伯语
            this.detail.goodsName = res.data.data.goodsNamear;
            this.detail.goodsDescimg = res.data.data.goodsDescimgar;
            this.detail.goodsDescription = res.data.data.goodsMemozhar;
          } else if (this.lang === "id") {
            //印尼语
            this.detail.goodsName = res.data.data.goodsNameid;
            this.detail.goodsDescimg = res.data.data.goodsDescimgid;
            this.detail.goodsDescription = res.data.data.goodsMemozhid;
          } else if (this.lang === "tr") {
            //土耳其语
            this.detail.goodsName = res.data.data.goodsNametr;
            this.detail.goodsDescimg = res.data.data.goodsDescimgtr;
            this.detail.goodsDescription = res.data.data.goodsMemozhtr;
          }
          if (this.detail.goodsDescimg == "") {
            this.detail.goodsDescimg = "暂无描述";
          }
        }
      });
    },
    arraySpanMethod() {
      //table合计行合并单元格
      setTimeout(() => {
        if (this.$refs.table.$el) {
          let current = this.$refs.table.$el
            .querySelector(".el-table__footer-wrapper")
            .querySelector(".el-table__footer");
          let cell = current.rows[0].cells;
          cell[1].style.display = "none";
          cell[0].colSpan = "13";
          cell[0].style.textAlign = "left";
        }
      }, 50);
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "warning-row";
      }
      return "";
    },
  },
  created() {
    //调用详情接口
    this.getDetail();
    //调用查询评论接口
    this.getCommet();
  },
  mounted() {
    console.log(this.detail.goodsMemozh, "this.detail.goodsMemozh");
    this.getGoodsId();
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    this.language = this.lang;

    // console.log(this.language, "abc");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      console.log(this.lang);
      this.language = this.lang;

      //语言改变 重新去请求
      this.getDetail();
      //调用查询评论接口
      this.getCommet();
    });
  },
};
</script>

<style lang="less">
.productsTailsDia {
  @media screen and (max-width: 766px) {
    .el-dialog {
      width: 1508px;
      height: 1530px;
      i {
        font-size: 8rem;
      }
      .productsTailsform {
        padding: 0 76px;
        h3 {
          height: 47px;
          font-size: 56px;
          padding-top: 43px;
          margin: 0 0 100px 0;
        }
        .el-form {
          height: 1000px;
          border: 1px solid #ccc;
          border-radius: 20px;
          .el-rate {
            height: 100px;
            border-bottom: 1px solid #ccc;
            background: #f3f3f3;
            border-radius: 20px 20px 0 0;
            padding-left: 30px;
            .el-rate__item {
              margin-top: 20px;
              margin-left: 5px;
              i {
                font-size: 40px;
              }
            }
          }
          textarea {
            border: none;
            height: 259px;
            font-size: 50px;
            border-radius: 0 0 20px 20px;
          }
        }
        .el-button {
          margin-top: 50px;
          width: 334px;
          height: 106px;
          font-size: 58px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .el-dialog {
      width: 1508px;
      .productsTailsform {
        padding: 0 76px;
        h3 {
          height: 47px;
          font-size: 36px;
          padding-top: 43px;
          margin: 0;
        }
        .el-form {
          // height: 324px;
          border: 1px solid #ccc;
          border-radius: 20px;
          .el-rate {
            height: 64px;
            border-bottom: 1px solid #ccc;
            background: #f3f3f3;
            border-radius: 20px 20px 0 0;
            padding-left: 30px;
            .el-rate__item {
              margin-top: 20px;
            }
          }
          textarea {
            border: none;
            height: 259px;
            font-size: 20px;
            border-radius: 0 0 20px 20px;
          }
        }
        .el-button {
          margin-top: 16px;
          margin-bottom: 50px;
          width: 234px;
          height: 56px;
          font-size: 28px;
        }
      }
    }
  }
}
.ProductDetails {
  @media screen and (max-width: 766px) {
    .dropdownPop {
      .el-dropdown-link {
        margin-left: 10px;
        cursor: pointer;
        color: #d0111b;
        font-size: 35px;
        border: 1.5px solid #8b8b8b;
        padding: 5px;
      }
      .el-icon-arrow-down {
        font-size: 25px;
        margin-left: 20px;
        color: #000;
      }
    }
    .breadcrumb {
      margin: 40px 156px 0 156px;
      .el-breadcrumb__item {
        float: none;
      }
    }
    .details {
      margin: 0 156px;
      display: flex;
      .imgbox {
        width: 500px;
        height: 500px;
        margin: 30px 0 30px 116px;
      }
      .img {
        flex: 1;
        img {
          width: 500px;
          height: 500px;
        }
        .imglis {
          position: relative;
          height: 140px;
          margin-bottom: 79px;
          .newarrow {
            width: 38px;
            height: 80px;
            position: absolute;
            top: 30px;
            font-size: 28px;
            cursor: pointer;
            z-index: 10;
            color: #999;
          }
          .but_left {
            left: 0;
            background: url(./../../assets/images/736.png) no-repeat;
            background-size: contain;
          }
          .but_right {
            right: 0;
            transform: rotate(180deg);
            background: url(./../../assets/images/736.png) no-repeat;
            background-size: contain;
          }
          .flashBg {
            height: 142px;
            width: 640px;
            .gimgv {
              height: 140px;
              // width: 140px;
              border: 1px solid #ffffff;
              img {
                margin: 0;
              }
            }
          }
        }
      }
      .meny {
        flex: 1;
        padding-left: 70px;
        h3 {
          // height: 50px;
          font-size: 38px;
          color: #333;
          margin: 30px 0 0 0;
        }

        .rate {
          margin-top: 50px;
          // width: 250px;
          // height: 60px;
          .el-rate {
            display: inline-block;
          }
          .el-rate__item {
            margin-left: 2px;
          }
          .btn {
            border-left: 2px solid #707070;
            // float: right;
            display: inline-block;
            margin-left: 20px;
            padding-left: 50px;
            font-size: 28px;
            // height: 50px;
            // line-height: 56px;
            cursor: pointer;
            &:hover {
              color: #d0111b;
            }
          }
        }
        .spxx {
          margin-top: 105px;
          font-size: 48px;
          color: #333;
          line-height: 37px;
          .xjck {
            padding: 50px;
            box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.03);
            border: 1px solid #ccc;
            border-radius: 20px;
            margin-top: 40px;
            .el-form {
              .el-form-item {
                margin: 45px 20px;
              }
              font-size: 50px;
              // margin: 20px 43px 0 20px;
              .is-required {
                .el-form-item__label {
                  &::before {
                    content: none;
                  }

                  &::after {
                    content: "*";
                    color: #f56c6c;
                    margin-left: 10px;
                  }
                }
              }
              .el-form-item__label {
                font-size: 50px;
              }

              .el-input__inner {
                height: 100px;
                font-size: 50px;
                // /deep/.el-form-item__error {
                //   font-size: 50px;
                //   color: #000;
                // }
              }
              textarea {
                height: 222px;
                font-size: 50px;
              }
              button {
                width: 500px;
                padding: 12px 100px;
                font-size: 50px;
                margin-top: 30px;
                &:hover {
                  width: auto;
                }
              }
            }
          }
          .spxxlis {
            // height: 60px;
            line-height: 60px;
            margin-left: 25px;
            .reviews {
              font-size: 48px;
              color: #d0111b;
              cursor: pointer;
              margin-top: 10px;
            }
            .btn {
              width: 512px;
              height: 70px;
              background: #d0111b;
              border: 1px solid #d0111b;
              border-radius: 8px;
              text-align: center;
              line-height: 70px;
              font-size: 34px;
              cursor: pointer;
              color: #fff;
              margin-top: 10px;
            }
            position: relative;
            display: flex;
            margin-bottom: 20px;
            .title {
              display: inline-block;
              min-width: 173px;
            }
            .con {
              flex: 1;
              word-break: break-all;
              .el-select {
                margin-left: 20px;
                margin-top: -5px;
                .el-input__inner {
                  height: 50px;
                  color: #d0111b;
                  font-size: 28px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .Information {
      margin: 50px 156px;
      .goodscontent {
        .detail {
          font-size: 40px;
          color: #333;
          white-space: pre-wrap;
          word-wrap: break-word;
          // line-height: 40px;
        }
        // width: 100%;
      }
      .tables {
        .tablesImgf {
          margin: 0 119px;
          img {
            margin-top: 70px;
            height: 600px;
          }
        }
        .el-table {
          .warning-row {
            background: #f4f4f4;
          }
          .el-table--group {
            border-color: #707070;
            &::before {
              background-color: #1f315a;
            }
            &::after {
              background-color: #1f315a;
            }
          }
          &::before {
            background-color: #1f315a;
          }
          &::after {
            background-color: #1f315a;
          }
          border-color: #707070;
          thead {
            .el-table__cell {
              background: #8b8b8b;
              border-color: #707070;
              font-size: 18px;
              color: #fff;
              text-align: center;
            }
          }
          .el-table--border {
            .el-table__cell {
              border-color: #707070;
            }
            &::before {
              background-color: #1f315a;
            }
            &::after {
              background-color: #1f315a;
            }
          }
          tbody {
            .el-table__cell {
              border-color: #707070;
              font-size: 20px;
              color: #000;
              text-align: center;
            }
          }
        }
      }

      h3 {
        color: #333;
        font-size: 50px;
        height: 50px;
        margin: 20px;
        padding: 30px 0 20px 0;
        border-bottom: 1px solid #707070;
      }
      h4 {
        margin: 50px 0;
        color: #333;
        font-size: 50px;
        font-weight: 100;
      }
      p {
        font-size: 28px;
        line-height: 42px;
      }
      .Features {
        font-size: 28px;
        line-height: 42px;
        display: block;
        margin-bottom: 20px;
        padding-left: 50px;
        background: url(./../../assets/images/437.png) no-repeat;
        background-size: 22px 24px;
        background-position: left 10px;
      }
      .img1 {
        margin: 0 50px;
        img {
          height: 550px;
        }
      }
      .ProductReviews {
        .ProductReviewsLis {
          position: relative;
          padding-top: 50px;
          .commentsavatar {
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
          &:first-child {
            border-top: 1px solid #eee;
          }
          .el-rate {
            position: absolute;
            left: 100px;
            top: 100px;
            .el-rate__item {
              // margin-left: 0px;
              margin-top: 5px;
            }
          }
          .title {
            position: absolute;
            left: 105px;
            top: 50px;
            font-size: 35px;
            color: #333;
          }
          .con {
            word-wrap: break-word;
            word-wrap: break-all;
            font-size: 35px;
            padding: 40px 60px;
            color: #999;
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .dropdownPop {
      .el-dropdown-link {
        cursor: pointer;
        color: #d0111b;
        width: 200px;
        font-size: 25px;
        margin-left: 30px;
        padding: 5px 20px;
        border: 1.5px solid #8b8b8b;
      }
      .el-icon-arrow-down {
        font-size: 25px;
        margin-left: 20px;
        color: #000;
      }
    }

    .breadcrumb {
      margin: 40px 156px 0 156px;
      .el-breadcrumb__item {
        float: none;
      }
      .el-breadcrumb {
        font-size: 24px;
      }
    }
    .details {
      margin: 0 156px;
      display: flex;
      .img {
        flex: 1;
        img {
          width: 500px;
          height: 500px;
          margin: 30px 0 30px 116px;
        }
        .imglis {
          position: relative;
          height: 140px;
          margin-bottom: 79px;
          .newarrow {
            width: 38px;
            height: 80px;
            position: absolute;
            top: 30px;
            font-size: 28px;
            cursor: pointer;
            z-index: 10;
            color: #999;
          }
          .but_left {
            left: 0;
            background: url(./../../assets/images/736.png) no-repeat;
            background-size: contain;
          }
          .but_right {
            right: 0;
            transform: rotate(180deg);
            background: url(./../../assets/images/736.png) no-repeat;
            background-size: contain;
          }
          .flashBg {
            height: 142px;
            width: 640px;
            .gimgv {
              height: 140px;
              // width: 140px;
              border: 1px solid #ffffff;
              img {
                margin: 0;
              }
            }
          }
        }
      }
      .meny {
        flex: 1;
        padding-left: 70px;
        h3 {
          // height: 50px;
          font-size: 38px;
          color: #333;
          margin: 30px 0 0 0;
        }

        .rate {
          margin-top: 18px;
          .el-rate {
            font-size: 20px;
            float: left;
          }
          .btn {
            font-size: 20px;
            padding-left: 20px;
            margin-left: 20px;
            border-left: 2px solid #707070;
            cursor: pointer;
            &:hover {
              color: #d0111b;
            }
          }
        }
        .spxx {
          margin-top: 45px;
          font-size: 28px;
          color: #333;
          line-height: 37px;
          .xjck {
            // width: 0px;
            height: 640px;
            box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.03);
            border: 1px solid #ccc;
            border-radius: 20px;
            margin-top: -18px;
            .el-form {
              margin: 20px 43px 0 20px;
              .is-required {
                .el-form-item__label {
                  &::before {
                    content: none;
                  }

                  &::after {
                    content: "*";
                    color: #f56c6c;
                    margin-left: 10px;
                  }
                }
              }
              .el-form-item__label {
                font-size: 20px;
              }

              .el-input__inner {
                height: 50px;
              }
              textarea {
                height: 222px;
              }
              button {
                padding: 12px 100px;
                font-size: 20px;
                margin-top: 30px;
                &:hover {
                  width: auto;
                }
              }
            }
          }
          .spxxlis {
            .reviews {
              font-size: 28px;
              color: #d0111b;
              cursor: pointer;
            }
            .btn {
              width: 512px;
              height: 70px;
              background: #d0111b;
              border: 1px solid #d0111b;
              border-radius: 8px;
              text-align: center;
              line-height: 70px;
              font-size: 34px;
              cursor: pointer;
              color: #fff;
            }
            position: relative;
            display: flex;
            margin-bottom: 20px;
            .title {
              display: inline-block;
              min-width: 180px;
            }
            .con {
              flex: 1;
              margin-left: 10px;
              width: 200px;
              word-wrap:normal;
              word-break: break-all;
              .el-select {
                margin-left: 20px;
                margin-top: -5px;
                .el-input__inner {
                  height: 50px;
                  color: #d0111b;
                  font-size: 28px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .Information {
      margin: 0 156px;
      .goodscontent {
        .detail {

          font-size: 28px;
          color: #333;
          text-align: left;
          line-height: 42px;
          white-space: pre-wrap;
          word-wrap: break-word;
        }
      }
      .tables {
        .tablesImgf {
          margin: 0 119px;
          img {
            margin-top: 70px;
            height: 600px;
          }
        }
        .el-table {
          .warning-row {
            background: #f4f4f4;
          }
          .el-table--group {
            border-color: #707070;
            &::before {
              background-color: #1f315a;
            }
            &::after {
              background-color: #1f315a;
            }
          }
          &::before {
            background-color: #1f315a;
          }
          &::after {
            background-color: #1f315a;
          }
          border-color: #707070;
          thead {
            .el-table__cell {
              background: #8b8b8b;
              border-color: #707070;
              font-size: 18px;
              color: #fff;
              text-align: center;
            }
          }
          .el-table--border {
            .el-table__cell {
              border-color: #707070;
            }
            &::before {
              background-color: #1f315a;
            }
            &::after {
              background-color: #1f315a;
            }
          }
          tbody {
            .el-table__cell {
              border-color: #707070;
              font-size: 20px;
              color: #000;
              text-align: center;
            }
          }
        }
      }

      h3 {
        color: #333;
        font-size: 38px;
        height: 50px;
        margin: 0;
        padding: 30px 0 20px 0;
        border-bottom: 1px solid #707070;
      }
      h4 {
        margin: 70px 0 40px 0;
        color: #333;
        font-size: 32px;
        font-weight: 100;
      }
      p {
        font-size: 28px;
        line-height: 42px;
      }
      .Features {
        font-size: 28px;
        line-height: 42px;
        display: block;
        margin-bottom: 20px;
        padding-left: 50px;
        background: url(./../../assets/images/437.png) no-repeat;
        background-size: 22px 24px;
        background-position: left 10px;
      }
      .img1 {
        margin: 0 50px;
        img {
          height: 550px;
        }
      }
      .ProductReviews {
        .ProductReviewsLis {
          position: relative;
          padding-top: 30px;
          .commentsavatar {
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
          &:first-child {
            border-top: 1px solid #eee;
          }
          .el-rate {
            position: absolute;
            left: 100px;
            top: 79px;
          }
          .title {
            position: absolute;
            left: 105px;
            top: 45px;
            font-size: 18px;
            color: #333;
          }
          .con {
            word-wrap: break-word;
            word-wrap: break-all;
            font-size: 20px;
            line-height: 40px;
            padding: 20px 0;
            color: #999;
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 766px) {
  /deep/ .el-form-item__error {
    font-size: 35px;
  }
}
</style>
