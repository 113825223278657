<template>
  <div id="app">
    <HeaderVue />
    <navBar />
    <Buoy />
    <div class="Indexcon">
      <el-scrollbar
        ref="scroll"
        style="height: 100%"
        wrap-style="overflow-x:hidden;"
      >
        <router-view />
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import HeaderVue from "./components/header/Header.vue";
import navBar from "./components/nav/nav-var.vue";
import Buoy from "./components/buoy/buoy.vue";
export default {
  name: "Index",
  components: {
    HeaderVue,
    navBar,
    Buoy,
  },
  methods: {},
  watch: {
    $route: function clearSelectionRow() {
      this.$refs.scroll.wrap.scrollTop = 0; //wrap 是源码中带的
    },
  },
  mounted() {
    document.title=window.g.documentTitle
  },
};
</script>
<style lang="less">
.Indexcon {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
html,
body,
form,
fieldset,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-text-size-adjust: 100%;
}
</style>
