<template>
  <div>
    <!-- 登录界面 -->
    <el-dialog
      :append-to-body="true"
      title=""
      :visible.sync="LogindialogVisible"
      class="longinS"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="logo"></div>
      <div class="loginform">
        <el-form ref="form" :model="form" label-width="0">
          <el-form-item>
            <el-input
              v-model="form.usersEmail"
              :placeholder="$t('Email')"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.usersPwd"
              :placeholder="$t('passwordTips')"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="danger" class="Continue" @click="login()">{{
              $t("Login")
            }}</el-button>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="loginChecked" @change="remember()">
              <span>{{ $t("freeTips") }}</span></el-checkbox
            >

            <span class="wjmm" @click="psssworddialogVisible = true">{{
              $t("forgetTips")
            }}</span>
          </el-form-item>
          <el-form-item>
            <el-button
              @click="createdialogVisible = true"
              style="color: #d3242d"
              >{{ $t("createTips") }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 忘记密码 -->
    <el-dialog
      :append-to-body="true"
      title=""
      :visible.sync="psssworddialogVisible"
      class="longinS"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="logo"></div>
      <div class="loginform">
        <el-form ref="form" :model="forget" label-width="0">
          <el-form-item>
            <el-input
              v-model="forget.usersEmail"
              :placeholder="$t('Email')"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              class="GetValicodeinput"
              v-model="forget.authCode"
              :placeholder="$t('Verification')"
            ></el-input>
            <el-button
              class="GetValicode"
              @click="handleClick"
              disabled
              v-show="Verification == false"
            >
              <span>{{ $t("getVerification") }}</span>
              <span>{{ timer }}</span>
            </el-button>
            <el-button
              class="GetValicode"
              @click="handleClick"
              v-show="Verification == true"
            >
              <span>{{ $t("getVerification") }}</span>
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="forget.usersPwd"
              :placeholder="$t('passwordTips')"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="danger" class="Continue" @click="changePassword"
              >{{ $t("changePassword") }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 注册界面 -->
    <el-dialog
      :append-to-body="true"
      title=""
      :visible.sync="createdialogVisible"
      class="longinS"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="logo"></div>
      <div class="loginform">
        <el-form ref="form" :model="form2" label-width="0">
          <el-form-item>
            <el-upload
              class="avatar-uploader"
              :action="getImg()"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="form2.usersEmail"
              :placeholder="$t('Email')"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form2.usersPwd"
              :placeholder="$t('passwordTips')"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="danger" class="Continue" @click="register()"
              >{{ $t("Register") }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              style="color: #d3242d"
              @click="createdialogVisible = false"
              >{{ $t("Already") }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { register, login, sendEmail, forgetPassword } from "../../api/api";
export default {
  data() {
    return {
      imageUrl: "",
      errMsg: "", //发送验证码接口的校验验证码
      Verification: true, //通过v-show控制显示获取还是倒计时
      timer: 60, //定义初始时间为60s
      showUsersEmail: "", //要展示的用户名
      showUser: false,
      LogindialogVisible: false,
      psssworddialogVisible: false,
      createdialogVisible: false,
      activeIndex: "1",
      activeIndex2: "1",
      active: 0,
      loginChecked: true,
      form: {}, //登录的参数
      form2: {}, //注册的参数对象
      forget: {}, //忘记密码参数对象
    };
  },
  created() {
    // var lang = window.sessionStorage.getItem('lang');
    // console.log(lang)
    // this.$i18n.mergeLocaleMessage(lang, res.data)
    // this.$i18n.setLocaleMessage(lang, res.data)
    // debugger
    let showUsersEmail = localStorage.getItem("usersEmail");
    let showUser = localStorage.getItem("showUser");
    this.showUsersEmail = showUsersEmail;
    this.showUser = showUser;
    let time = localStorage.getItem("time"); //没点下次免费登录后存入本地的时间
    let loginTime = localStorage.getItem("loginTime"); //点了下次免费登录后存入本地的时间
    var nowTime = new Date().getTime(); //获取系统当前时间
    console.log(Math.abs(nowTime - time) >= 1800000);
    if (time != null) {
      // debugger
      if (Math.abs(nowTime - time) >= 1800000) {
        // debugger
        localStorage.removeItem("token");
        localStorage.removeItem("showUser");
        localStorage.removeItem("time");
        this.$forceUpdate(); //页面强制刷新
        // this.$message({
        //     message: this.$t('loginAgain'),
        //     type: 'warning'
        // });
        this.showUser = false;
      }
    } else if (Math.abs(nowTime - loginTime) >= 2626560000) {
      // debugger
      localStorage.removeItem("token");
      localStorage.removeItem("showUser");
      localStorage.removeItem("loginTime");
      this.$forceUpdate(); //页面强制刷新
      // this.$message({
      //     message: this.$t('loginAgain'),
      //     type: 'warning'
      // });
      this.showUser = false;
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = baseUrl + res.errMsg;
      console.log(this.imageUrl, "-------this.imageUrl");
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    init(data) {
      this.LogindialogVisible = true;
      //data是父组件弹窗传递过来的值，我们可以打印看看
      console.log(data);
    },
    //退出
    toLogout() {
      localStorage.removeItem("token");
      localStorage.removeItem("showUser");
      localStorage.removeItem("time");
      localStorage.removeItem("loginTime");
      localStorage.removeItem("showUser");
      localStorage.removeItem("usersEmail");
      this.$message({
        message: this.$t("logoutTips"),
        type: "success",
      });
      //强制刷新页面
      this.$router.go(0);
    },
    //修改密码并登录
    changePassword() {
      if (this.forget.usersEmail == "" || this.forget.usersEmail == undefined) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else if (
        this.forget.authCode == "" ||
        this.forget.authCode == undefined
      ) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else if (
        this.forget.usersPwd == "" ||
        this.forget.usersPwd == undefined
      ) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else {
        if (this.forget.authCode != this.errMsg) {
          this.$message({
            message: this.$t("perfeccionarMessage"),
            type: "warning",
          });
        } else {
          forgetPassword(this.forget).then((res) => {
            
            this.$message({
              message: res.data.errMsg,
              type: "warning",
            });
            if (res.data.code == 1) {
              //调用登录接口
              login(this.forget).then((res) => {
                
                if (res.data.code == 1) {
                  localStorage.setItem("token", res.data.data.token);
                  this.showUsersEmail = this.forget.usersEmail;
                  localStorage.setItem("usersEmail", this.forget.usersEmail);
                  this.showUser = true;
                  localStorage.setItem("showUser", this.showUser);
                  var time = new Date().getTime(); //获取系统当前时间
                  localStorage.setItem("time", time); //把token获取开始时间存入本地
                  this.$message({
                    message: res.data.errMsg,
                    type: "success",
                  });
                  this.createdialogVisible = false;
                  this.LogindialogVisible = false;
                  this.psssworddialogVisible = false;
                } else {
                  this.$message({
                    message: res.data.errMsg,
                    type: "warning",
                  });
                }
              });
            }
          });
        }
      }
    },
    //获取验证码
    handleClick() {
      this.Verification = false; //点击button改变v-show的状态
      //调用发送验证码接口
      if (this.forget.usersEmail == "") {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else {
        sendEmail(this.forget.usersEmail).then((res) => {
          
          this.errMsg = res.data.errMsg; //供校验的验证码
        });
      }
      let auth_timer = setInterval(() => {
        //定时器设置每秒递减
        this.timer--; //递减时间
        if (this.timer <= 0) {
          this.Verification = true; //60s时间结束还原v-show状态并清除定时器
          clearInterval(auth_timer);
          this.timer = 60;
        }
      }, 1000);
    },
    //下次免费登录
    remember() {
      console.log(this.loginChecked);
      if (this.loginChecked == true) {
        var prescription = 2626560000; //一个月(毫秒值)
        var obj = {
          prescription: "",
        };
        obj.prescription = prescription;
        let twoobj = Object.assign(this.form, obj);
        console.log(twoobj);
        this.form = twoobj;
        // debugger
      } else {
        delete this.form.prescription;
        console.log(this.form);
        // debugger
      }
    },
    //登录的方法
    login() {
      if (this.form.usersEmail == "" || this.form.usersEmail == undefined) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else if (this.form.usersPwd == "" || this.form.usersPwd == undefined) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else {
        //调用登录接口
        console.log(this.form);
        login(this.form).then((res) => {
          console.log(res, "userinfouserinfouserinfo");
          // debugger
          if (res.data.code == 1) {
            localStorage.setItem(
              "userinfo",
              JSON.stringify(res.data.data.USERINFO)
            );
            localStorage.setItem("token", res.data.data.token);
            this.showUsersEmail = this.form.usersEmail;
            localStorage.setItem("usersEmail", this.form.usersEmail);
            this.showUser = true;
            localStorage.setItem("showUser", this.showUser);
            var loginTime = new Date().getTime(); //获取系统当前时间
            var time = new Date().getTime(); //获取系统当前时间
            if (this.loginChecked == true) {
              localStorage.setItem("loginTime", loginTime); //把token获取开始时间存入本地
            } else {
              localStorage.setItem("time", time); //把token获取开始时间存入本地
            }
            this.$message({
              message: "登陆成功",
              type: "success",
            });
            this.createdialogVisible = false;
            this.LogindialogVisible = false;
          } else {
            this.$message({
              message: res.data.errMsg,
              type: "warning",
            });
          }
        });
      }
    },
    getImg() {
      return baseUrl + "/home/addUserImage";
    },
    //注册方法
    register() {
      var email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      console.log(this.form2.usersEmail, "email.test(this.form2.usersEmail)");
      if (
        this.form2.usersEmail == undefined ||
        email.test(this.form2.usersEmail)
      ) {
        if (this.form2.usersPwd == "" || this.form2.usersPwd == undefined) {
          this.$message({
            message: this.$t("perfeccionarMessage"),
            type: "warning",
          });
        } else {
          //调用注册接口
          // debugger;
          if (this.imageUrl != "") {
            let info = {
              usersEmail: this.form2.usersEmail,
              usersPwd: this.form2.usersPwd,
              usersImg: this.imageUrl,
            };
            register(info).then((res) => {
              
              if (res.data.status == "ERROR") {
                this.$message({
                  message: res.data.errMsg,
                  type: "warning",
                });
              }
              if (res.data.code == 1) {
                //调用登录接口
                login(this.form2).then((res) => {
                  console.log(res, "res321654987");
                  if (res.data.code == 1) {
                    localStorage.setItem("token", res.data.data.token);
                    this.showUsersEmail = this.form2.usersEmail;
                    localStorage.setItem("usersEmail", this.form2.usersEmail);
                    this.showUser = true;
                    localStorage.setItem("showUser", this.showUser);
                    var time = new Date().getTime(); //获取系统当前时间
                    localStorage.setItem("time", time); //把token获取开始时间存入本地
                    this.$message({
                      message: res.data.errMsg,
                      type: "success",
                    });
                    this.createdialogVisible = false;
                    this.LogindialogVisible = false;
                  } else {
                    this.$message({
                      message: res.data.errMsg,
                      type: "warning",
                    });
                  }
                });
              }
            });
          } else {
            this.$message({
              message: "请上传头像!",
              type: "warning",
            });
          }
        }
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    toVisible() {
      this.active = 6;
      this.LogindialogVisible = true;
    },
    toContactUs() {
      this.active = 7;
      this.$router.push("/contactUs");
    },
    toAbout() {
      this.active = 4;
      this.$router.push("/About");
    },
    toNews() {
      this.active = 5;
      this.$router.push("/news");
    },
    toFreeHelp() {
      this.active = 3;
      this.$router.push("/MaintenanceHlep");
    },
    toProducts() {
      this.active = 1;
      this.$router.push("/Products");
    },
    toEngineeringService() {
      this.active = 2;
      this.$router.push("/EngineeringService");
    },
  },
  hongtiao(e) {
    this.setData({
      currentIndexNav: e.target.dataset.index,
    });
  },
};
</script>
<style lang="less">
.longinS {
  // 弹框宽高
  .el-dialog {
    width: 1700px;
    height: 2628px;
    padding-top: 40px;
    // input上下左右内边距
    .loginform {
      margin: 33px 89px 0 94px;
      .avatar-uploader {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 40px;
          color: #8c939d;
          width: 300px;
          height: 300px;
          line-height: 300px;
          text-align: center;
        }
        .avatar {
          width: 300px;
          height: 300px;
          display: block;
        }
      }
    }
    i {
      font-size: 12rem;
    }
    // log大小
    .logo {
      height: 22.0625rem;
    }
    // 按钮大小
    .el-button {
      margin: 23px 0 0 95px;
      width: 85%;
      height: 11.25rem;
      // font-size: 4.375rem;
      font-size: 50px;
    }
    // input框大小
    .el-input__inner {
      height: 200px;
      border: 10px solid #050000;
      font-size: 5rem;
    }
    // Free Login Next Time 前面的框大小
    .el-checkbox__inner {
      margin: 53px 0 80px 10px;
      border: 1px solid #d0101b;
      width: 50px;
      height: 50px;
    }
    // Free Login Next Time 字体大小
    .el-checkbox__label {
      // font-size: 5.875rem;
      font-size: 50px;
    }
    // Forget password
    .el-form-item__content {
      line-height: 19.5rem;
      // position: relative;
      // font-size: 5.875rem;
    }
    .GetValicode {
      width: 40%;
      padding: 0;
      float: right;
      margin-top: 40px;
    }
    .GetValicodeinput {
      width: 50%;
    }
    .wjmm {
      font-size: 50px;
      float: right;
      // margin-left: 200px;
      margin-left: 0;
      display: block;
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
    }
  }
}
</style>
