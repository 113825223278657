<template>
  <div class="nav" id="nav">
    <div class="denglu">
      <img src="./../../assets/images/yonghu.png" @click="handlClick()" style="width: 28px" />
      <loginS v-if="Visiable" ref="form"></loginS>
    </div>
    <div class="xlk">
      <xiala />
    </div>
    <div class="menu1">
      <div :class="{ uls: true, 'active-color': 1 === active }">
        <span @click="toProducts()" @mouseenter="(menunavShow = true), (engineeringnavShow = false)">{{ $t("Products") }}
        </span>
        <div class="menunav" @mouseleave="out()">
          <div class="menunav1" v-show="menunavShow">
            <ul v-show="this.lang == 'en'">
              <li v-for="(item, index) in Lsit1" :key="index" @mouseenter="nav1move(index)" @click="nav1cli(index)">
                {{ item.kindNameen }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'zh'">
              <li v-for="(item, index) in Lsit1" :key="index" @mouseenter="nav1move(index)" @click="nav1cli(index)">
                {{ item.kindNamezh }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'ar'">
              <li v-for="(item, index) in Lsit1" :key="index" @mouseenter="nav1move(index)" @click="nav1cli(index)">
                {{ item.kindNamear }}
                <i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'es'">
              <li v-for="(item, index) in Lsit1" :key="index" @mouseenter="nav1move(index)" @click="nav1cli(index)">
                {{ item.kindNamees }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'fr'">
              <li v-for="(item, index) in Lsit1" :key="index" @mouseenter="nav1move(index)" @click="nav1cli(index)">
                {{ item.kindNamefr }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'id'">
              <li v-for="(item, index) in Lsit1" :key="index" @mouseenter="nav1move(index)" @click="nav1cli(index)">
                {{ item.kindNameid }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'ru'">
              <li v-for="(item, index) in Lsit1" :key="index" @mouseenter="nav1move(index)" @click="nav1cli(index)">
                {{ item.kindNameru }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'tr'">
              <li v-for="(item, index) in Lsit1" :key="index" @mouseenter="nav1move(index)" @click="nav1cli(index)">
                {{ item.kindNametr }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
          </div>

          <div class="menunav2" v-show="menunavShow2">
            <ul v-show="this.lang == 'tr'">
              <li v-for="(item, index) in Lsit2" :key="index" @mouseenter="nav1move1(index)" @click="nav1cli1(index)">
                {{ item.kindNametr }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'ru'">
              <li v-for="(item, index) in Lsit2" :key="index" @mouseenter="nav1move1(index)" @click="nav1cli1(index)">
                {{ item.kindNameru }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'en'">
              <li v-for="(item, index) in Lsit2" :key="index" @mouseenter="nav1move1(index)" @click="nav1cli1(index)">
                <!-- 显示问题 -->
                {{ item.kindNameen }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'zh'">
              <li v-for="(item, index) in Lsit2" :key="index" @mouseenter="nav1move1(index)" @click="nav1cli1(index)">
                {{ item.kindNamezh }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'id'">
              <li v-for="(item, index) in Lsit2" :key="index" @mouseenter="nav1move1(index)" @click="nav1cli1(index)">
                {{ item.kindNameid }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'fr'">
              <li v-for="(item, index) in Lsit2" :key="index" @mouseenter="nav1move1(index)" @click="nav1cli1(index)">
                {{ item.kindNamefr }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'es'">
              <li v-for="(item, index) in Lsit2" :key="index" @mouseenter="nav1move1(index)" @click="nav1cli1(index)">
                {{ item.kindNamees }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
            <ul v-show="this.lang == 'ar'">
              <li v-for="(item, index) in Lsit2" :key="index" @mouseenter="nav1move1(index)" @click="nav1cli1(index)">
                {{ item.kindNamear }}<i v-show="item.list !=null">></i>
              </li>
            </ul>
          </div>

          <div class="menunav3" v-show="menunavShow3">
            <ul v-show="this.lang == 'en'">
              <li v-for="(item, index) in Lsit3" :key="index" @mouseenter="nav1move2(index)" @click="nav1cli2(index)">
                {{ item.kindNameen }}
              </li>
            </ul>
            <ul v-show="this.lang == 'ar'">
              <li v-for="(item, index) in Lsit3" :key="index" @mouseenter="nav1move2(index)" @click="nav1cli2(index)">
                {{ item.kindNamear }}
              </li>
            </ul>
            <ul v-show="this.lang == 'es'">
              <li v-for="(item, index) in Lsit3" :key="index" @mouseenter="nav1move2(index)" @click="nav1cli2(index)">
                {{ item.kindNamees }}
              </li>
            </ul>
            <ul v-show="this.lang == 'fr'">
              <li v-for="(item, index) in Lsit3" :key="index" @mouseenter="nav1move2(index)" @click="nav1cli2(index)">
                {{ item.kindNamefr }}
              </li>
            </ul>
            <ul v-show="this.lang == 'id'">
              <li v-for="(item, index) in Lsit3" :key="index" @mouseenter="nav1move2(index)" @click="nav1cli2(index)">
                {{ item.kindNameid }}
              </li>
            </ul>
            <ul v-show="this.lang == 'ru'">
              <li v-for="(item, index) in Lsit3" :key="index" @mouseenter="nav1move2(index)" @click="nav1cli2(index)">
                {{ item.kindNameru }}
              </li>
            </ul>
            <ul v-show="this.lang == 'tr'">
              <li v-for="(item, index) in Lsit3" :key="index" @mouseenter="nav1move2(index)" @click="nav1cli2(index)">
                {{ item.kindNametr }}
              </li>
            </ul>
            <ul v-show="this.lang == 'zh'">
              <li v-for="(item, index) in Lsit3" :key="index" @mouseenter="nav1move2(index)" @click="nav1cli2(index)">
                {{ item.kindNamezh }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div :class="{ uls: true, 'active-color': 2 === active }">
        <span @click="toEngineeringService()" @mouseenter="seenter()">
          {{ $t("EngineeringService") }}
        </span>
        <div class="engineeringnav" @mouseleave="engineeringnavShow = false">
          <div class="engineeringnav1" v-show="engineeringnavShow">
            <ul>
              <li @click="toQuotation()">{{ $t("RapidQuotation") }}</li>
              <li @click="toDataAcquisition()">{{ $t("DataAcquisition") }}</li>
              <li @click="toManufacturing()">{{ $t("RapidManufacturing") }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div :class="{ uls: true, 'active-color': 3 === active }">
        <span @click="toFreeHelp()">{{ $t("FreeMaintanceHelp") }}</span>
      </div>
    </div>

    <div class="menu2">
      <div :class="{ uls: true, 'active-color': 4 === active }">
        <span @click="toAbout()">{{ $t("About") }}</span>
      </div>
      <div :class="{ uls: true, 'active-color': 5 === active }">
        <span @click="toNews()">{{ $t("News") }}</span>
      </div>
      <div :class="{ uls: true, 'active-color': 7 === active }">
        <span @click="toContactUs()">{{ $t("ContactUs") }}</span>
      </div>
      <div :class="{ uls: true, 'active-color': 6 === active }">
        <span v-if="showUser">{{ this.showUsersEmail }}</span>
        <span @click="toVisible()" v-else>{{ $t("Login") }}</span>
      </div>
      <div :class="{ uls: true, 'active-color': 8 === active }" v-if="showUser">
        <span @click="toLogout()">{{ $t("logout") }}</span>
      </div>
    </div>

    <!-- 登录界面 -->
    <el-dialog :append-to-body="true" title="" :visible.sync="LogindialogVisible" class="longin"
      :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="logo"></div>
      <div class="loginform">
        <el-form ref="form" :model="form" label-width="0">
          <el-form-item>
            <el-input v-model="form.usersEmail" :placeholder="$t('Email')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="password" v-model="form.usersPwd" :placeholder="$t('passwordTips')"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="danger" class="Continue" @click="login()">{{
              $t("Login")
            }}</el-button>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="loginChecked" @change="remember()">
              <span>{{ $t("freeTips") }}</span></el-checkbox>

            <span class="wjmm" @click="psssworddialogVisible = true">{{
              $t("forgetTips")
            }}</span>
          </el-form-item>
          <el-form-item>
            <el-button @click="createdialogVisible = true" style="color: #d3242d">{{ $t("createTips") }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 忘记密码 -->
    <el-dialog :append-to-body="true" title="" :visible.sync="psssworddialogVisible" class="longin"
      :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="logo"></div>
      <div class="loginform">
        <el-form ref="form" :model="forget" label-width="0">
          <el-form-item>
            <el-input v-model="forget.usersEmail" :placeholder="$t('Email')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input class="GetValicodeinput" v-model="forget.authCode" :placeholder="$t('Verification')"></el-input>
            <el-button class="GetValicode" @click="handleClick" disabled v-show="Verification == false">
              <span>{{ $t("getVerification") }}</span>
              <span>{{ timer }}</span>
            </el-button>
            <el-button class="GetValicode" @click="handleClick" v-show="Verification == true">
              <span>{{ $t("getVerification") }}</span>
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-input type="password" v-model="forget.usersPwd" :placeholder="$t('passwordTips')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="danger" class="Continue" @click="changePassword">{{ $t("changePassword") }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 注册界面 -->
    <el-dialog :append-to-body="true" title="" :visible.sync="createdialogVisible" class="longin"
      :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="logo"></div>
      <div class="loginform">
        <el-form ref="form" :model="form2" label-width="0">
          <!-- 头像 -->
          <el-form-item>
            <el-upload :show-file-list="false" class="avatar-uploader" :action="getImg()"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-input v-model="form2.usersEmail" :placeholder="$t('Email')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="password" v-model="form2.usersPwd" :placeholder="$t('passwordTips')"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="danger" class="Continue" @click="register()">{{ $t("Register") }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button style="color: #d3242d" @click="createdialogVisible = false">{{ $t("Already") }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import loginS from "@/components/nav/loginS";
import xiala from "@/components/nav/xiala";

import {
  register,
  login,
  sendEmail,
  forgetPassword,
  getSort,
} from "../../api/api";
export default {
  components: { loginS, xiala },
  data() {
    return {
      imageUrl: "",
      Visiable: false,
      errMsg: "", //发送验证码接口的校验验证码
      Verification: true, //通过v-show控制显示获取还是倒计时
      timer: 60, //定义初始时间为60s
      showUsersEmail: "", //要展示的用户名
      showUser: false,
      LogindialogVisible: false,
      psssworddialogVisible: false,
      createdialogVisible: false,
      activeIndex: "1",
      activeIndex2: "1",
      active: 0,
      loginChecked: true,
      form: {}, //登录的参数
      form2: {}, //注册的参数对象
      forget: {}, //忘记密码参数对象
      menunavShow: false,
      menunavShow2: false,
      menunavShow3: false,
      Lsit1: [],
      Lsit2: [],
      Lsit3: [],
      listate: null,
      lang: "en",
      navtitle: "",
      navindex: "",
      engineeringnavShow: false,
    };
  },
  mounted() {
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // console.log(sessionStorage.getItem("lang"), 'mounted');
  },
  created() {
    //页面一进入获取默认语言
    // this.lang = sessionStorage.getItem("lang");
    // console.log(sessionStorage.getItem("lang"), 'created');

    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      console.log(this.lang);
      //语言改变 重新去请求
      if (this.lang == "en") {
        // this.navtitle = this.Lsit1.kindNamezh;

      }
    });
    // var lang = window.sessionStorage.getItem('lang');
    // console.log(lang)
    // this.$i18n.mergeLocaleMessage(lang, res.data)
    // this.$i18n.setLocaleMessage(lang, res.data)
    // debugger
    this.getSort();
    let showUsersEmail = localStorage.getItem("usersEmail");
    let showUser = localStorage.getItem("showUser");
    this.showUsersEmail = showUsersEmail;
    this.showUser = showUser;
    let time = localStorage.getItem("time"); //没点下次免费登录后存入本地的时间
    let loginTime = localStorage.getItem("loginTime"); //点了下次免费登录后存入本地的时间
    var nowTime = new Date().getTime(); //获取系统当前时间
    if (time != null) {
      // debugger
      if (Math.abs(nowTime - time) >= 1800000) {
        // debugger
        localStorage.removeItem("token");
        localStorage.removeItem("showUser");
        localStorage.removeItem("time");
        this.$forceUpdate(); //页面强制刷新
        // this.$message({
        //     message: this.$t('loginAgain'),
        //     type: 'warning'
        // });
        this.showUser = false;
      }
    } else if (Math.abs(nowTime - loginTime) >= 2626560000) {
      // debugger
      localStorage.removeItem("token");
      localStorage.removeItem("showUser");
      localStorage.removeItem("loginTime");
      this.$forceUpdate(); //页面强制刷新
      // this.$message({
      //     message: this.$t('loginAgain'),
      //     type: 'warning'
      // });
      this.showUser = false;
    }
  },
  methods: {
    // 头像
    handleAvatarSuccess(res, file, fileList) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.imageUrl = baseUrl + res.errMsg;
      console.log(this.imageUrl);
      console.log(res, "rrrrrr");
      console.log(file, "fffff");
      console.log(fileList, "llllllll");
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    getImg() {
      return baseUrl + "/home/addUserImage";
    },
    getSort() {
      getSort().then((res) => {
        this.Lsit1 = res.data.list;
        console.log(this.Lsit1, "哈哈哈哈哈哈哈哈哈");
      });
    },
    nav1move(index) {
      this.navindex = index;
      this.Lsit2 = this.Lsit1[index].list;
      this.menunavShow2 = true;
      this.menunavShow3 = false;
    },
    nav1move1(index) {
      this.listate = index;
      this.Lsit3 = this.Lsit2[this.listate].list;
      this.menunavShow3 = true;
    },
    nav1move2(index) {

    },
    out() {
      (this.menunavShow = false),
        (this.menunavShow2 = false),
        (this.menunavShow3 = false);
    },
    seenter() {
      this.out();
      this.engineeringnavShow = true;
    },
    nav1cli(index) {
      this.$router.push({
        path: "/Products",
        query: {
          kindCode: this.Lsit1[index].kindCode,
        },
      });
    },
    nav1cli1(index) {
      this.$router.push({
        path: "/Products",
        query: {
          kindCode: this.Lsit1[this.navindex].kindCode,
        },
      });
    },
    nav1cli2(index) {
      this.$router.push({
        path: "/Products",
        query: {
          kindCode: this.Lsit1[this.navindex].kindCode,
        },
      });
    },
    toManufacturing() {
      this.$router.push("/Manufacturing");
    },
    toDataAcquisition() {
      this.$router.push("/DataAcquisition");
    },
    toQuotation() {
      this.$router.push("/Quotation");
    },
    // 7.实现点击事件，点击事件一定要包含以下内容
    handlClick(data) {
      this.Visiable = true;
      this.$nextTick(() => {
        //这里的dialog与上面dialog-component组件里面的ref属性值是一致的
        //init调用的是dialog-component组件里面的init方法
        //data是传递给弹窗页面的值
        this.$refs.form.init(data);
      });
    },
    //退出
    toLogout() {
      localStorage.removeItem("token");
      localStorage.removeItem("showUser");
      localStorage.removeItem("time");
      localStorage.removeItem("loginTime");
      localStorage.removeItem("showUser");
      localStorage.removeItem("usersEmail");
      this.$message({
        message: this.$t("logoutTips"),
        type: "success",
      });
      //强制刷新页面
      this.$router.go(0);
    },
    //修改密码并登录
    changePassword() {
      if (this.forget.usersEmail == "" || this.forget.usersEmail == undefined) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else if (
        this.forget.authCode == "" ||
        this.forget.authCode == undefined
      ) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else if (
        this.forget.usersPwd == "" ||
        this.forget.usersPwd == undefined
      ) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else {
        if (this.forget.authCode != this.errMsg) {
          this.$message({
            message: this.$t("perfeccionarMessage"),
            type: "warning",
          });
        } else {
          forgetPassword(this.forget).then((res) => {

            this.$message({
              message: res.data.errMsg,
              type: "warning",
            });
            if (res.data.code == 1) {
              //调用登录接口
              login(this.forget).then((res) => {

                if (res.data.code == 1) {
                  localStorage.setItem("token", res.data.data.token);
                  this.showUsersEmail = this.forget.usersEmail;
                  localStorage.setItem("usersEmail", this.forget.usersEmail);
                  this.showUser = true;
                  localStorage.setItem("showUser", this.showUser);
                  var time = new Date().getTime(); //获取系统当前时间
                  localStorage.setItem("time", time); //把token获取开始时间存入本地
                  this.$message({
                    message: res.data.errMsg,
                    type: "success",
                  });
                  this.createdialogVisible = false;
                  this.LogindialogVisible = false;
                  this.psssworddialogVisible = false;
                } else {
                  this.$message({
                    message: res.data.errMsg,
                    type: "warning",
                  });
                }
              });
            }
          });
        }
      }
    },
    //获取验证码
    handleClick() {
      this.Verification = false; //点击button改变v-show的状态
      //调用发送验证码接口
      if (this.forget.usersEmail == "") {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else {
        sendEmail(this.forget.usersEmail).then((res) => {

          this.errMsg = res.data.errMsg; //供校验的验证码
        });
      }
      let auth_timer = setInterval(() => {
        //定时器设置每秒递减
        this.timer--; //递减时间
        if (this.timer <= 0) {
          this.Verification = true; //60s时间结束还原v-show状态并清除定时器
          clearInterval(auth_timer);
          this.timer = 60;
        }
      }, 1000);
    },
    //下次免费登录
    remember() {
      console.log(this.loginChecked);
      if (this.loginChecked == true) {
        var prescription = 2626560000; //一个月(毫秒值)
        var obj = {
          prescription: "",
        };
        obj.prescription = prescription;
        let twoobj = Object.assign(this.form, obj);
        console.log(twoobj);
        this.form = twoobj;
        // debugger
      } else {
        delete this.form.prescription;
        console.log(this.form);
        // debugger
      }
    },
    //登录的方法
    login() {
      if (this.form.usersEmail == "" || this.form.usersEmail == undefined) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else if (this.form.usersPwd == "" || this.form.usersPwd == undefined) {
        this.$message({
          message: this.$t("perfeccionarMessage"),
          type: "warning",
        });
      } else {
        //调用登录接口
        console.log(this.form);
        login(this.form).then((res) => {

          // debugger
          if (res.data.code == 1) {
            localStorage.setItem(
              "userinfo",
              JSON.stringify(res.data.data.USERINFO)
            );
            localStorage.setItem("token", res.data.data.token);
            this.showUsersEmail = this.form.usersEmail;
            localStorage.setItem("usersEmail", this.form.usersEmail);
            this.showUser = true;
            localStorage.setItem("showUser", this.showUser);
            var loginTime = new Date().getTime(); //获取系统当前时间
            var time = new Date().getTime(); //获取系统当前时间
            if (this.loginChecked == true) {
              localStorage.setItem("loginTime", loginTime); //把token获取开始时间存入本地
            } else {
              localStorage.setItem("time", time); //把token获取开始时间存入本地
            }
            this.$message({
              message: "登陆成功",
              type: "success",
            });
            this.createdialogVisible = false;
            this.LogindialogVisible = false;
          } else {
            this.$message({
              message: res.data.errMsg,
              type: "warning",
            });
          }
        });
      }
    },
    //注册方法
    register() {
      // console.log(123456);
      // var email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      // debugger;
      // if (
      //   this.form2.usersEmail == "" ||
      //   this.form2.usersEmail == undefined ||
      //   email.test(this.form2.usersEmail)
      // ) {
      //   this.$message({
      //     message: this.$t("perfeccionarMessage"),
      //     type: "warning",
      //   });
      // } else if (
      //   this.form2.usersPwd == "" ||
      //   this.form2.usersPwd == undefined
      // ) {
      //   this.$message({
      //     message: this.$t("perfeccionarMessage"),
      //     type: "warning",
      //   });
      // } else {
      //   //调用注册接口
      //   console.log(this.form2);
      //   // debugger
      //   register(this.form2).then((res) => {
      //     
      //     this.$message({
      //       message: res.data.errMsg,
      //       type: "warning",
      //     });
      //     if (res.data.code == 1) {
      //       //调用登录接口
      //       login(this.form2).then((res) => {
      //         
      //         if (res.data.code == 1) {
      //           localStorage.setItem("token", res.data.data.token);
      //           this.showUsersEmail = this.form2.usersEmail;
      //           localStorage.setItem("usersEmail", this.form2.usersEmail);
      //           this.showUser = true;
      //           localStorage.setItem("showUser", this.showUser);
      //           var time = new Date().getTime(); //获取系统当前时间
      //           localStorage.setItem("time", time); //把token获取开始时间存入本地
      //           this.$message({
      //             message: res.data.errMsg,
      //             type: "success",
      //           });
      //           this.createdialogVisible = false;
      //           this.LogindialogVisible = false;
      //         } else {
      //           this.$message({
      //             message: res.data.errMsg,
      //             type: "warning",
      //           });
      //         }
      //       });
      //     }
      //   });
      // }

      var email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      console.log(baseUrl, "-----baseUrl");
      console.log(this.imageUrl, "-------------this.imageUrl");
      if (
        this.form2.usersEmail == "" ||
        this.form2.usersEmail == undefined ||
        email.test(this.form2.usersEmail)
      ) {
        if (this.form2.usersPwd != "" || this.form2.usersPwd != undefined) {
          //调用注册接口
          console.log(this.form2);
          // debugger

          if (this.imageUrl != "") {
            let info = {
              usersEmail: this.form2.usersEmail,
              usersPwd: this.form2.usersPwd,
              usersImg: this.imageUrl,
            };
            console.log(info, "info");
            register(info).then((res) => {

              if (res.data.status == "ERROR") {
                this.$message({
                  message: res.data.errMsg,
                  type: "warning",
                });
              }
              if (res.data.code == 1) {
                //调用登录接口
                login(this.form2).then((res) => {
                  console.log(res, "resresres123456");
                  if (res.data.code == 1) {
                    localStorage.setItem("token", res.data.data.token);
                    this.showUsersEmail = this.form2.usersEmail;
                    localStorage.setItem("usersEmail", this.form2.usersEmail);
                    this.showUser = true;
                    localStorage.setItem("showUser", this.showUser);
                    localStorage.setItem(
                      "userinfo",
                      JSON.stringify(res.data.data.USERINFO)
                    );
                    var time = new Date().getTime(); //获取系统当前时间
                    localStorage.setItem("time", time); //把token获取开始时间存入本地
                    this.$message({
                      message: res.data.errMsg,
                      type: "success",
                    });
                    this.createdialogVisible = false;
                    this.LogindialogVisible = false;
                  } else {
                    this.$message({
                      message: res.data.errMsg,
                      type: "warning",
                    });
                  }
                });
              }
            });
          } else {
            this.$message({
              message: "请上传头像!",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: this.$t("perfeccionarMessage"),
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: this.$t("perfeccionarMessage1"),
          type: "warning",
        });
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    toVisible() {
      this.active = 6;
      this.LogindialogVisible = true;
    },
    toContactUs() {
      this.active = 7;
      this.$router.push("/contactUs");
    },
    toAbout() {
      this.active = 4;
      this.$router.push("/About");
    },
    toNews() {
      this.active = 5;
      this.$router.push("/news");
    },
    toFreeHelp() {
      this.active = 3;
      this.$router.push("/MaintenanceHlep");
    },
    toProducts() {
      this.active = 1;
      this.$router.push("/Products");
    },
    toEngineeringService() {
      this.active = 2;
      this.$router.push("/EngineeringService");
    },
  },
  hongtiao(e) {
    this.setData({
      currentIndexNav: e.target.dataset.index,
    });
  },
};
</script>
<style lang="less">
.avatar-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}

.longin {
  .el-dialog {
    width: 780px;
    height: 550px;
    padding-top: 40px;

    .loginform {
      margin: 33px 89px 0 94px;
    }

    .el-button {
      width: 100%;
    }

    .el-checkbox__inner {
      border: 1px solid #d0101b;
    }

    .GetValicode {
      width: 230px;
      float: right;
    }

    .GetValicodeinput {
      width: 278px;
    }

    .wjmm {
      float: right;
      cursor: pointer;

      &:hover {
        color: #409eff;
      }
    }
  }
}

/*.el-dropdown-menu__item{
  font-size: 6rem !important;
  margin: 0 0 0 0 !important;
  line-height: 1 !important;
}*/
.Continue {
  background: #d0101b !important;
  border: 1px solid #707070 !important;
}

.nav {
  width: 100%;
  z-index: 80;
  // background: #000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  //小屏样式
  @media screen and (max-width: 766px) {
    position: fixed;
    top: 230px;
    height: 0.05rem;

    #nav {
      z-index: 200;
      background: rgba(0, 0, 0, 0);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0);
      position: relative;
    }

    .el-dropdown-link {
      position: relative;
      top: 1.75rem;
    }

    .Drop-downBox {
      background-color: rgba(204, 204, 204, 0.01);
      width: 200px;
      height: 200px;
      position: relative;
      top: -12.75rem;
    }

    .menu1 {
      display: none;
    }

    .menu2 {
      display: none;
    }

    img {
      width: 150px;
      position: relative;
      // left: 10.875rem;
      // top: 3rem;
    }

    .denglu {
      position: absolute;
      left: 1720px;
      top: -210px;
      // background: #d0101b;
    }

    .xlk {
      position: absolute;
      left: 9px;
      top: 12px;
    }
  }

  @media screen and (min-width: 768px) {
    position: fixed;
    top: 89px;
    height: 68px;

    .Drop-downBox {
      display: none;
    }

    .menu1 {
      float: left;
      padding-left: 80px;

      .menunav {
        position: absolute;
        padding: 0px;
        margin-top: 18px;

        .menunav3 {
          vertical-align: top;
        }

        .menunav2 {
          vertical-align: top;
        }

        div {
          display: inline-block;
          background: #fff;
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            padding: 15px;

            &:hover {
              color: #fff;
              background-color: #d0111b;
              cursor: pointer;
            }
          }

          i {
            font-style: normal;
            float: right;
            margin-left: 20px;
          }
        }
      }

      .engineeringnav {
        position: absolute;

        .engineeringnav1 {
          background: #fff;
          margin: 0;

          ul {
            padding: 0px;

            li {
              list-style: none;
              padding: 15px;

              &:hover {
                color: #fff;
                background-color: #d0111b;
                cursor: pointer;
              }
            }
          }
        }
      }

      .uls {
        display: inline-block;
        margin: 20px 35px 19px 0;
        text-align: left;
        height: 29px;

        span {
          cursor: pointer;
          color: #fff;
          line-height: 29px;
          font-size: 22px;
          padding-bottom: 18px;
        }
      }

      span:hover {
        // margin-top: 80px;
        border-bottom: 1px solid red;
      }

      .active-color {
        span {
          border-bottom: 1px solid red;
        }

        // margin-top: 80px;
      }
    }

    .menu2 {
      float: right;
      padding-right: 80px;
      text-align: right;

      .uls {
        display: inline-block;
        margin: 19px;

        span {
          cursor: pointer;
          height: 30px;
          color: #fff;
          line-height: 30px;
          font-size: 22px;
          padding-bottom: 18px;
        }
      }

      span:hover {
        // margin-top: 80px;
        border-bottom: 1px solid red;
      }

      .active-color {

        // margin-top: 80px;
        span {
          border-bottom: 1px solid red;
        }
      }
    }

    .denglu {
      display: none;
    }
  }

  @media screen and (max-width: 1279px) {}
}
</style>
