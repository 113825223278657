<template>
  <div class="products contsctUs">
    <Carousel :imgList="imgList" />
    <!-- 产品列表 -->
    <div class="productlist">
      <div class="selectList">
        <h2>{{ $t("productCenter") }}</h2>
        <div class="bordered"></div>
        <div class="collapse">
          <el-collapse accordion v-model="yjfl" @change="changeyjfl">
            <!--              一级分类-->
            <div v-for="(item, index) in sortList" :key="index">
              <el-collapse-item :name="item.kindCode" :title="item.kindName">
                <!--                  二级分类-->
                <el-collapse v-model="yjfl1" @change="changeyjfl1" accordion>
                  <div v-for="(item1, index1) in item.list" :key="index1">
                    <el-collapse-item
                      v-show="lang == 'zh'"
                      :title="item1.kindNamezh"
                      :name="item1.kindCode"
                    >
                      <!--                        三级分类-->
                      <div
                        @click.stop="clickSort(item2)"
                        v-for="(item2, index2) in item1.list"
                        :key="index2"
                        :class="[
                          isActive == item2.kindCode ? 'active' : 'disActive',
                        ]"
                      >
                        <span v-show="lang == 'zh'">{{
                          item2.kindNamezh
                        }}</span>
                        <span v-show="lang == 'en'">{{
                          item2.kindNameen
                        }}</span>
                        <span v-show="lang == 'fr'">{{
                          item2.kindNamefr
                        }}</span>
                        <span v-show="lang == 'ru'">{{
                          item2.kindNameru
                        }}</span>
                        <span v-show="lang == 'es'">{{
                          item2.kindNamees
                        }}</span>
                        <span v-show="lang == 'ar'">{{
                          item2.kindNamear
                        }}</span>
                        <span v-show="lang == 'id'">{{
                          item2.kindNameid
                        }}</span>
                        <span v-show="lang == 'tr'">{{
                          item2.kindNametr
                        }}</span>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item
                      v-show="lang == 'en'"
                      :title="item1.kindNameen"
                      :name="item1.kindCode"
                    >
                      <!--                        三级分类-->
                      <div
                        @click.stop="clickSort(item2)"
                        v-for="(item2, index2) in item1.list"
                        :key="index2"
                        :class="[
                          isActive == item2.kindCode ? 'active' : 'disActive',
                        ]"
                      >
                        <span v-show="lang == 'zh'">{{
                          item2.kindNamezh
                        }}</span>
                        <span v-show="lang == 'en'">{{
                          item2.kindNameen
                        }}</span>
                        <span v-show="lang == 'fr'">{{
                          item2.kindNamefr
                        }}</span>
                        <span v-show="lang == 'ru'">{{
                          item2.kindNameru
                        }}</span>
                        <span v-show="lang == 'es'">{{
                          item2.kindNamees
                        }}</span>
                        <span v-show="lang == 'ar'">{{
                          item2.kindNamear
                        }}</span>
                        <span v-show="lang == 'id'">{{
                          item2.kindNameid
                        }}</span>
                        <span v-show="lang == 'tr'">{{
                          item2.kindNametr
                        }}</span>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item
                      v-show="lang == 'fr'"
                      :title="item1.kindNamefr"
                      :name="item1.kindCode"
                    >
                      <!--                        三级分类-->
                      <div
                        @click.stop="clickSort(item2)"
                        v-for="(item2, index2) in item1.list"
                        :key="index2"
                        :class="[
                          isActive == item2.kindCode ? 'active' : 'disActive',
                        ]"
                      >
                        <span v-show="lang == 'zh'">{{
                          item2.kindNamezh
                        }}</span>
                        <span v-show="lang == 'en'">{{
                          item2.kindNameen
                        }}</span>
                        <span v-show="lang == 'fr'">{{
                          item2.kindNamefr
                        }}</span>
                        <span v-show="lang == 'ru'">{{
                          item2.kindNameru
                        }}</span>
                        <span v-show="lang == 'es'">{{
                          item2.kindNamees
                        }}</span>
                        <span v-show="lang == 'ar'">{{
                          item2.kindNamear
                        }}</span>
                        <span v-show="lang == 'id'">{{
                          item2.kindNameid
                        }}</span>
                        <span v-show="lang == 'tr'">{{
                          item2.kindNametr
                        }}</span>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item
                      v-show="lang == 'ru'"
                      :title="item1.kindNameru"
                      :name="item1.kindCode"
                    >
                      <!--                        三级分类-->
                      <div
                        @click.stop="clickSort(item2)"
                        v-for="(item2, index2) in item1.list"
                        :key="index2"
                        :class="[
                          isActive == item2.kindCode ? 'active' : 'disActive',
                        ]"
                      >
                        <span v-show="lang == 'zh'">{{
                          item2.kindNamezh
                        }}</span>
                        <span v-show="lang == 'en'">{{
                          item2.kindNameen
                        }}</span>
                        <span v-show="lang == 'fr'">{{
                          item2.kindNamefr
                        }}</span>
                        <span v-show="lang == 'ru'">{{
                          item2.kindNameru
                        }}</span>
                        <span v-show="lang == 'es'">{{
                          item2.kindNamees
                        }}</span>
                        <span v-show="lang == 'ar'">{{
                          item2.kindNamear
                        }}</span>
                        <span v-show="lang == 'id'">{{
                          item2.kindNameid
                        }}</span>
                        <span v-show="lang == 'tr'">{{
                          item2.kindNametr
                        }}</span>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item
                      v-show="lang == 'es'"
                      :title="item1.kindNamees"
                      :name="item1.kindCode"
                    >
                      <!--                        三级分类-->
                      <div
                        @click.stop="clickSort(item2)"
                        v-for="(item2, index2) in item1.list"
                        :key="index2"
                        :class="[
                          isActive == item2.kindCode ? 'active' : 'disActive',
                        ]"
                      >
                        <span v-show="lang == 'zh'">{{
                          item2.kindNamezh
                        }}</span>
                        <span v-show="lang == 'en'">{{
                          item2.kindNameen
                        }}</span>
                        <span v-show="lang == 'fr'">{{
                          item2.kindNamefr
                        }}</span>
                        <span v-show="lang == 'ru'">{{
                          item2.kindNameru
                        }}</span>
                        <span v-show="lang == 'es'">{{
                          item2.kindNamees
                        }}</span>
                        <span v-show="lang == 'ar'">{{
                          item2.kindNamear
                        }}</span>
                        <span v-show="lang == 'id'">{{
                          item2.kindNameid
                        }}</span>
                        <span v-show="lang == 'tr'">{{
                          item2.kindNametr
                        }}</span>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item
                      v-show="lang == 'ar'"
                      :title="item1.kindNamear"
                      :name="item1.kindCode"
                    >
                      <!--                        三级分类-->
                      <div
                        @click.stop="clickSort(item2)"
                        v-for="(item2, index2) in item1.list"
                        :key="index2"
                        :class="[
                          isActive == item2.kindCode ? 'active' : 'disActive',
                        ]"
                      >
                        <span v-show="lang == 'zh'">{{
                          item2.kindNamezh
                        }}</span>
                        <span v-show="lang == 'en'">{{
                          item2.kindNameen
                        }}</span>
                        <span v-show="lang == 'fr'">{{
                          item2.kindNamefr
                        }}</span>
                        <span v-show="lang == 'ru'">{{
                          item2.kindNameru
                        }}</span>
                        <span v-show="lang == 'es'">{{
                          item2.kindNamees
                        }}</span>
                        <span v-show="lang == 'ar'">{{
                          item2.kindNamear
                        }}</span>
                        <span v-show="lang == 'id'">{{
                          item2.kindNameid
                        }}</span>
                        <span v-show="lang == 'tr'">{{
                          item2.kindNametr
                        }}</span>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item
                      v-show="lang == 'id'"
                      :title="item1.kindNameid"
                      :name="item1.kindCode"
                    >
                      <!--                        三级分类-->
                      <div
                        @click.stop="clickSort(item2)"
                        v-for="(item2, index2) in item1.list"
                        :key="index2"
                        :class="[
                          isActive == item2.kindCode ? 'active' : 'disActive',
                        ]"
                      >
                        <span v-show="lang == 'zh'">{{
                          item2.kindNamezh
                        }}</span>
                        <span v-show="lang == 'en'">{{
                          item2.kindNameen
                        }}</span>
                        <span v-show="lang == 'fr'">{{
                          item2.kindNamefr
                        }}</span>
                        <span v-show="lang == 'ru'">{{
                          item2.kindNameru
                        }}</span>
                        <span v-show="lang == 'es'">{{
                          item2.kindNamees
                        }}</span>
                        <span v-show="lang == 'ar'">{{
                          item2.kindNamear
                        }}</span>
                        <span v-show="lang == 'id'">{{
                          item2.kindNameid
                        }}</span>
                        <span v-show="lang == 'tr'">{{
                          item2.kindNametr
                        }}</span>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item
                      v-show="lang == 'tr'"
                      :title="item1.kindNametr"
                      :name="item1.kindCode"
                    >
                      <!--                        三级分类-->
                      <div
                        @click.stop="clickSort(item2)"
                        v-for="(item2, index2) in item1.list"
                        :key="index2"
                        :class="[
                          isActive == item2.kindCode ? 'active' : 'disActive',
                        ]"
                      >
                        <span v-show="lang == 'zh'">{{
                          item2.kindNamezh
                        }}</span>
                        <span v-show="lang == 'en'">{{
                          item2.kindNameen
                        }}</span>
                        <span v-show="lang == 'fr'">{{
                          item2.kindNamefr
                        }}</span>
                        <span v-show="lang == 'ru'">{{
                          item2.kindNameru
                        }}</span>
                        <span v-show="lang == 'es'">{{
                          item2.kindNamees
                        }}</span>
                        <span v-show="lang == 'ar'">{{
                          item2.kindNamear
                        }}</span>
                        <span v-show="lang == 'id'">{{
                          item2.kindNameid
                        }}</span>
                        <span v-show="lang == 'tr'">{{
                          item2.kindNametr
                        }}</span>
                      </div>
                    </el-collapse-item>
                  </div>
                </el-collapse>
              </el-collapse-item>
            </div>
          </el-collapse>
        </div>
      </div>
      <!--      空提示-->
      <el-empty
        :description="$t('category')"
        v-if="nullTip == true || list.length == 0"
        style="width: 1000px"
      ></el-empty>
      <!--        产品列表-->
      <div class="productALl" v-else>
        <div class="productlis" v-for="(item, index) in list" :key="index">
          <div class="imgSize" @click="toProductDetails(index)">
            <img :src="item.goodsImg" alt="" />
          </div>
          <h5>{{ item | goodsName(list, NewList) }}</h5>
          <span class="productSpan" @click="toProductDetails(index)">{{
            $t("FindMore")
          }}</span>
        </div>
      </div>
    </div>
    <div class="pagination" v-if="nullTip == false">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="params.current"
        :total="this.total"
        :page-size="size"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <!-- 公司基本简介链接 -->
    <Company />
    <!-- 底部 -->
    <tFooter />
  </div>
</template>

<script>
// 轮播图
import Carousel from "./../../components/ContactUs/Carousel.vue";
// <!-- 公司基本简介链接 -->
import Company from "./../../components/Company/Company.vue";
// 底部信息
import tFooter from "./../../components/footer/footer.vue";
//导入api文件中的获取商品列表接口方法
import { getSort, getProduct1 } from "../../api/api";
export default {
  name: "products",
  components: {
    Company,
    tFooter,
    Carousel,
  },
  props: {},
  data() {
    return {
      NewList: [],
      yjfl: "",
      yjfl1: "",
      nullTip: false, //判断为空的布尔值
      sortParams: {
        current: 1,
        size: 10,
        kindPid: 0,
      },
      params: {
        current: 1,
        size: 15,
      },
      list: [], //商品列表
      sortList: [], //一级分类列表
      sortList2: [], //二级分类列表
      sortList3: [], //三级分类列表
      oneName: "",
      lang: "", //默认语言
      total: 0, //总页数
      size: 0,
      isActive: "",
    };
  },
  filters: {
    goodsName(val, list, NewList) {
      for (let index = 0; index < NewList.length; index++) {
        if (val.goodsName == NewList[index]) {
          return val.goodsVision;
        }
      }
      if (val.goodsName.length > 20) {
        val.goodsName = val.goodsName.slice(0, 20) + "...";
      }

     //以前是return的goodsName
      return val.goodsVision;
    },
  },
  methods: {
    changeyjfl(val) {
      console.log(val, "val");
      this.params.goodsKindcode = this.yjfl;
      console.log(this.yjfl, "this.yjflthis.yjflthis.yjfl");
      this.isActive = "";
      this.getList();
    },
    changeyjfl1() {
      this.params.goodsKindcode = this.yjfl1;
      this.isActive = "";
      this.getList();
    },
    //点击分类
    clickSort(item2) {
      this.params.goodsKindcode = item2.kindCode;
      this.isActive = item2.kindCode;

      this.getList();
    },
    //获取分类
    getSort() {
      getSort().then((res) => {
        // console.log(res)
        if (res.status == 200) {
          this.sortList.length = 0;
          console.log(res.data.list);
          if (this.lang == null) {
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNameen,
                list: item.list,
              });
            });
          } else if (this.lang === "zh") {
            //中文
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNamezh,
                list: item.list,
              });
            });
            } else if (this.lang === "en") {
            //英文
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNameen,
                list: item.list,
              });
            });
          } else if (this.lang === "fr") {
            //法语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNamefr,
                list: item.list,
              });
            });
          } else if (this.lang === "ru") {
            //俄语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNameru,
                list: item.list,
              });
            });
          } else if (this.lang === "es") {
            //西班牙语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNamees,
                list: item.list,
              });
            });
          } else if (this.lang === "ar") {
            //阿拉伯语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNamear,
                list: item.list,
              });
            });
          } else if (this.lang === "id") {
            //印尼语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNameid,
                list: item.list,
              });
            });
          } else if (this.lang === "tr") {
            //土耳其语
            res.data.list.forEach((item) => {
              this.sortList.push({
                kindCode: item.kindCode,
                kindId: item.kindId,
                kindPid: item.kindPid,
                kindName: item.kindNametr,
                list: item.list,
              });
            });
          }
        }
      });
    },
    //改变分页
    handlePageChange(currentPage) {
      this.params.current = currentPage;
      //在此刷新数据
      //获取商品列表
      this.getList();
    },

    duplicates(arr) {
      let temp = [];
      arr.forEach((item) => {
        if (
          arr.indexOf(item) != arr.lastIndexOf(item) &&
          temp.indexOf(item) == -1
        ) {
          temp.push(item);
        }
      });
      this.NewList = temp;
      return temp;
    },
    //获取商品列表
    getList() {
      getProduct1(this.params).then((res) => {
        let arr = res.data.records;
        let b = [];
        for (let index = 0; index < arr.length; index++) {
          b.push(arr[index].goodsNameen);
        }
        this.duplicates(b);


        if (res.status == 200) {
          this.list.length = 0;
          this.total = res.data.total; //总页数赋值
          this.size = res.data.size; //每页数赋值

          // debugger
          if (res.data.records.length == 0) {
            this.nullTip = true;
          } else {
            this.nullTip = false;
          }
          res.data.records.forEach((item) => {
            if (this.lang == null) {
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameen,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "zh") {
              //中文
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamezh,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "en") {
              //英文
              // debugger
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameen,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "fr") {
              //法语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamefr,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "ru") {
              //俄语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameru,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "es") {
              //西班牙语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamees,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "ar") {
              //阿拉伯语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamear,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "id") {
              //印尼语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameid,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "tr") {
              //土耳其语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNametr,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            }
          });
        }
      });
    },
    toProductDetails(index) {
      console.log(this.list[index].goodsId, "产品信息");
      this.$router.push({
        path: "/ProductDetails",
        query: {
          id: this.list[index].goodsId,
        },
      });
    },
  },
  watch: {
    $route() {
      this.$router.go(0);
    },
  },
  computed: {
    imgList() {
      return {
        url: window.g.productsImg,
        className: "fadeInUp animated textLeft",
        title: this.$t("productCenter"),
        xtitle: this.$t("xtitleTips"),
      };
    },
  },
  created() {
    let kindCode = this.$route.query.kindCode;
    // console.log(kindCode);
    //获取分类
    this.getSort();
    //获取搜索页传来的数据
    let arr = this.$route.query.arr;
    // debugger
    if (arr != undefined) {
      // debugger
      let list = JSON.parse(arr);
      console.log(list, "list");
      this.list = list;
    } else if (kindCode != undefined) {
      console.log(kindCode, "kindcode");
      this.params.goodsKindcode = kindCode;
      this.getList();
      this.yjfl = Number(kindCode);
      // console.log(this.yjfl);
      // console.log(this.sortList, "kindCodekindCodekindCode");
      // debugger
    } else {
      //获取商品列表
      this.getList();
      // debugger
    }
  },
  mounted() {
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      console.log(this.lang);
      //语言改变 重新去请求
      //获取分类
      this.getSort();
      //获取商品列表
      this.getList();
    });
  },
  //页面销毁前
  beforeDestroy() {},
};
</script>

<style lang="less">
.products {
  @media screen and (max-width: 766px) {
    .pagination {
      margin: 300px 0;
      text-align: center;
    }
    .productlist {
      // margin: 60px 80px;
      // padding-right: 100px;
      display: flex;
      .selectList {
        width: 550px;
        padding-left: 50px;
        h2 {
          font-size: 70px;
          height: 48px;
          margin: 10.5rem 10px;
          width: 600px;
        }
        .bordered {
          height: 5px;
          background: #d11d26;
          width: 550px;
          margin-top: 25px;
        }
        .collapse {
          width: 500px;
          .el-collapse {
            border-top: none;
          }
          .el-collapse-item__header {
            height: 300px;
            font-size: 50px;
            letter-spacing: 3px;
            line-height: 1;
            .active-bold {
              // margin-top: 80px;
              font-weight: bold;
            }
          }
          .el-collapse-item__header:hover {
            font-weight: bold;
          }

          .el-collapse-item__content {
            padding-bottom: 0;
            .el-collapse {
              border-top: 1px solid #ebeef5;
              width: 435px;
              margin-left: 70px;
              // .is-active {
              //   div {
              //     &:first-child {
              //       .is-active {
              //         border-bottom: 1px solid #ccc;
              //       }
              //     }
              //   }
              // }
              .el-collapse-item__wrap {
                border-bottom: none;
              }
              .el-collapse-item__content {
                width: 500px;
                font-size: 50px;
                color: #000;
                padding-left: -40px;
                line-height: 80px;
                cursor: pointer;
                div {
                  &:hover {
                    color: #d0001b;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }

      .productALl {
        // margin: 50px 0 0 77px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; /*换行*/
        // padding: 50px;
        .productlis {
          width: 450px;
          // height: 69.75rem;
          padding: 100px;
          .imgSize {
            width: 450px;
            height: 450px;
          }
          .productSpan {
            display: none;
          }
          &:hover {
            cursor: pointer;
            img {
              transition: all 0.5s ease 0s;
              transform: scale(1.1);
            }
            // span {
            //   background-color: white;
            //   color: black;
            //   border: 1px solid red;
            //   font-weight: 600;
            // }
          }
          img {
            width: 450px;
            height: 450px;
            border: 1px solid #ffffff;
            // transform: scale(1.1);
          }
          h5 {
            margin: 30px auto;
            font-weight: 500;
            font-size: 40px;
            text-align: center;
          }
          span {
            display: block;
            height: 4.125rem;
            width: 21.875rem;
            background: #d0111b;
            color: #fff;
            font-weight: 400;
            line-height: 4.125rem;
            margin: 0 auto;
            text-align: center;
            font-size: 35px;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .pagination {
      margin: 70px 0 70px 562px;
      text-align: center;
    }
    .productlist {
      margin: 60px 80px;
      display: flex;
      .selectList {
        width: 405px;
        h2 {
          font-size: 36px;
          height: 48px;
          margin: 0;
          // width: 247px;
        }
        .bordered {
          height: 5px;
          background: #d11d26;
          width: 247px;
          margin-top: 25px;
        }
        .collapse {
          width: 405px;
          .el-collapse {
            border-top: none;
          }
          .el-collapse-item__header {
            height: 90px;
            font-size: 28px;
            .active-bold {
              // margin-top: 80px;
              font-weight: bold;
            }
          }
          .el-collapse-item__header:hover {
            font-weight: bold;
          }

          .el-collapse-item__content {
            padding-bottom: 0;
            .el-collapse {
              border-top: 1px solid #ebeef5;
              width: 335px;
              margin-left: 70px;
              // .is-active {
              //   div {
              //     &:first-child {
              //       .is-active {
              //         border-bottom: 1px solid #ccc;
              //       }
              //     }
              //   }
              // }
              .el-collapse-item__wrap {
                border-bottom: none;
              }
              .el-collapse-item__content {
                font-size: 20px;
                color: #000;
                padding-left: 50px;
                line-height: 80px;
                cursor: pointer;
                div {
                  &:hover {
                    color: #d0001b;
                    font-weight: bold;
                  }
                }
                .active {
                  color: #d0001b;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .productALl {
        margin: 50px 0 0 77px;
        flex: 1;
        display: flex;
        // justify-content: space-around;
        flex-wrap: wrap; /*换行*/

        .productlis {
          margin-left: 1rem;
          width: 400px;
          height: 600px;
          overflow: hidden;
          &:hover {
            img {
              transition: all 0.5s ease 0s;
              transform: scale(1.1);
            }
            span {
              background-color: white;
              color: black;
              border: 1px solid red;
              font-weight: 700;
            }
          }
          img {
            width: 400px;
            height: 400px;
            border: 1px solid #ffffff;
          }
          h5 {
            margin: 30px auto;
            font-size: 24px;
            text-align: center;
          }
          span {
            display: block;
            height: 50px;
            width: 206px;
            background: #d0111b;
            color: #fff;
            font-weight: 700;
            line-height: 50px;
            margin: 0 auto;
            text-align: center;
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
