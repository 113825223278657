<template>
  <div class="review">
    <div class="review0" v-for="(item, index) in list" :key="index">
      <div class="review01">{{ $t("CUSTOMERREVIEWS") }}</div>
      <div class="review02">
        <span class="el-icon-star-on"></span>
        <span class="el-icon-star-on"></span>
        <span class="el-icon-star-on"></span>
        <span class="el-icon-star-on"></span>
        <span class="el-icon-star-on"></span>
      </div>
      <div class="review1">
        <img @click="jumpDetail(index)" id="review11" :src="item.goodsImg" />
        <button class="reviewbtn">B024</button>
      </div>
      <div class="review03">
        <div class="p1">
          {{ $t("never") }}
        </div>
        <div class="p2">—— Matilda</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProduct } from "../../../api/api";

export default {
  name: "Review",
  props: {},
  data() {
    return {
      lang: "", //获取语言
      params: {
        //参数
        current: 1,
        size: 1,
      },
      list: [], //产品数据
    };
  },
  methods: {
    //获取商品列表
    getList() {
      getProduct(this.params).then((res) => {
        
        if (res.status == 200) {
          this.list.length = 0;
          // debugger
          if (res.data.records.length == 0) {
            this.nullTip = true;
          } else {
            this.nullTip = false;
          }
          res.data.records.forEach((item) => {
            if (this.lang == null) {
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameen,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "zh") {
              //中文
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamezh,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "en") {
              //英文
              // debugger
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameen,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "fr") {
              //法语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamefr,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "ru") {
              //俄语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameru,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "es") {
              //西班牙语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamees,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "ar") {
              //阿拉伯语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNamear,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "id") {
              //印尼语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNameid,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            } else if (this.lang === "tr") {
              //土耳其语
              this.list.push({
                goodsImg: this.$global.imgUrl + item.goodsImg,
                goodsId: item.goodsId,
                goodsName: item.goodsNametr,
                goodsVision: item.goodsVision,
              });
              // console.log(this.list)
            }
          });
        }
      });
    },
    jumpDetail(index) {
      console.log(index);
      console.log(this.list[index].goodsId);
      this.$router.push({
        path: "/ProductDetails",
        query: {
          id: this.list[index].goodsId,
        },
      });
    },
  },
  created() {
    //调用商品列表
    this.getList();
  },
  mounted() {
    //页面一进入获取默认语言
    this.lang = sessionStorage.getItem("lang");
    // 添加对 sessionStorage 的监听
    window.addEventListener("setItem", () => {
      this.lang = sessionStorage.getItem("lang");
      console.log(this.lang);
      //语言改变 重新去请求
      this.getList();
    });
  },
};
</script>

<style lang="less" scoped>
.review {
  @media screen and (max-width: 766px) {
    // height: 600px;
    padding: 12px;
    background: #f1e8df;
    margin: 40px 110px 100px 110px;
    .review0 {
      width: 100%;
      // height: 400px;
      // float: left;
      margin-left: 30px;
      padding-top: 46px;
      position: relative;
      .review01 {
        font-size: 38px;
        height: 26px;
        color: #333;
        font-family: Microsoft YaHei;
        font-weight: bold;
      }

      .review02 {
        font-size: 35px;
        height: 33px;
        margin-top: 65px;

        span {
          margin-right: 5px;
          color: #f00;
        }
      }

      .review03 {
        width: 1000px;
        overflow: hidden;
        margin-top: 35px;
        font-size: 35px;
        .p1 {
          overflow: hidden;
          color: #333;
        }

        .p2 {
          margin-top: 100px;
          text-align: left;
          height: 4.625rem;
          color: #666;
        }
      }

      .review1 {
        width: 450px;
        height: 450px;
        position: absolute;
        top: 64px;
        overflow: hidden;
        right: 60px;
        img {
          width: 450px;
          height: 450px;
        }

        .reviewbtn {
          width: 220px;
          height: 40px;
          border: 0;
          background-color: #fafafa;
          color: #333;
          font-size: 14px;
          margin: 0 auto;
          position: relative;
          left: 90px;
          top: -220px;
          outline: none;
          display: none;
        }
        .reviewbtn:hover {
          width: 220px;
          height: 40px;
          border: 0;
          background-color: #fafafa;
          color: #333;
          font-size: 14px;
          margin: 0 auto;
          position: relative;
          left: 90px;
          top: -220px;
          outline: none;
          display: block;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    height: 582px;
    background: #f1e8df;
    margin: 40px 110px 0 110px;
    .review0 {
      width: 100%;
      height: 400px;
      float: left;
      margin-left: 30px;
      padding-top: 46px;
      position: relative;

      .review01 {
        font-size: 30px;
        height: 26px;
        color: #333;
        font-family: Microsoft YaHei;
        font-weight: bold;
      }

      .review02 {
        font-size: 33px;
        height: 33px;
        margin-top: 65px;

        span {
          margin-right: 5px;
          color: #f00;
        }
      }

      .review03 {
        width: 803px;
        overflow: hidden;
        margin-top: 35px;

        .p1 {
          font-size: 24px;
          line-height: 40px;
          height: 272px;
          overflow: hidden;
          color: #333;
        }

        .p2 {
          text-align: left;
          font-size: 24px;
          height: 26px;
          color: #666;
        }
      }

      .review1 {
        width: 450px;
        height: 450px;
        float: right;
        position: absolute;
        top: 64px;
        overflow: hidden;
        right: 60px;

        img {
          width: 450px;
          height: 450px;
        }

        .reviewbtn {
          width: 220px;
          height: 40px;
          border: 0;
          background-color: #fafafa;
          color: #333;
          font-size: 14px;
          margin: 0 auto;
          position: relative;
          left: 90px;
          top: -220px;
          outline: none;
          display: none;
        }
        .reviewbtn:hover {
          width: 220px;
          height: 40px;
          border: 0;
          background-color: #fafafa;
          color: #333;
          font-size: 14px;
          margin: 0 auto;
          position: relative;
          left: 90px;
          top: -220px;
          outline: none;
          display: block;
        }
      }
    }
  }
}
</style>
