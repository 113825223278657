<template>
  <el-carousel
    trigger="click"
    class="Carousel"
    :interval="5000"
    @change="changeNum"
  >
    <el-carousel-item
      class="Carousel-item"
      v-for="(item, index) in list"
      :key="index"
    >
      <div>
        <h2 :class="activeIndex == index ? item.className : ''">
          {{ item.title }}
        </h2>
        <p
          v-if="item.xtitle"
          :class="activeIndex == index ? item.className : ''"
        >
          {{ item.xtitle }}
        </p>
        <img :src="item.url" alt="" />
      </div>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    changeNum(num) {
      this.activeIndex = num;
    },
  },
};
</script>
<style lang="less">
.Carousel {
  width: 100%;
  // height: 900px;
  @media screen and (max-width: 766px) {
    height: 800px;
    margin-top: 400px;
    .el-carousel__container {
      height: 92%;
      .textLeft {
        text-align: left;
      }
      h2 {
        font-size: 70px;
        color: #fff;
        position: absolute;
        top: 300px;
        text-align: center;
        width: 80%;
        left: 10%;
      }
      p {
        font-size: 70px;
        color: #fff;
        position: absolute;
        top: 100px;
        text-align: center;
        width: 80%;
        left: 10%;
        font-weight: bold;
      }
      img {
        width: 100%;
        height: 800px;
      }
    }
    .el-carousel__indicator--horizontal.is-active button {
      background: #fb0000;
    }
  }
  @media screen and (min-width: 768px) {
    // height: 100%;
    margin-top: 89px;
    .el-carousel__container {
      height: 750px;
      .textLeft {
        text-align: left;
      }
      h2 {
        font-size: 48px;
        color: #fff;
        position: absolute;
        top: 280px;
        text-align: center;
        width: 80%;
        left: 10%;
      }
      p {
        font-size: 32px;
        color: #fff;
        position: absolute;
        top: 350px;
        text-align: center;
        width: 80%;
        left: 10%;
        font-weight: bold;
      }
      img {
        width: 100%;
        height: 750px;
      }
    }
    .el-carousel__indicator--horizontal.is-active button {
      background: #fb0000;
    }
  }
}
</style>
