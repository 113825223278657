<template>
  <div class="contsctUs">
    <div class="chatusn">
      <div class="chatus-item">
        <div class="img">
          <img src="./../../assets/images/chatphone.png" alt="" />
        </div>
        <div class="title">{{ $t("Speakteam") }}</div>
        <div class="cont">
          {{ $t("experienced") }}
        </div>
        <div class="btn">{{ $t("CallUs") }}</div>
      </div>
      <div class="chatus-item">
        <div class="img">
          <img src="./../../assets/images/chatxx.png" alt="" />
        </div>
        <div class="title">Whatsapp</div>
        <div class="cont">
          {{ $t("online") }}
        </div>

        <el-popover placement="top" trigger="click">
          <div class="YDwechat-con">
            <div class="YDwechatred"></div>
            <div class="YDwechatbai"></div>
            <div class="YDerweima">
              <div class="logo1">
                <img src="./../../assets/images/logo.png" style="width: 90px" />
              </div>
              <div class="YDContact">{{ $t("contactTips") }}</div>
              <div class="YDtel">+86 138 1077 7318</div>
              <div class="YDContact">{{ $t("codeTips") }}</div>
              <div class="YDsaoyisao">
                <img
                  src="./../../assets/images/erweima.png"
                  alt=""
                  style="width: 80px"
                />
              </div>
            </div>
          </div>

          <div slot="reference" class="btn">{{ $t("CallUs") }}</div>
        </el-popover>
      </div>
      <div class="chatus-item">
        <div class="img">
          <img src="./../../assets/images/chatwh.png" alt="" />
        </div>
        <div class="title">{{ $t("Requirements") }}</div>
        <div class="cont">
          {{ $t("receive") }}
        </div>
        <div class="btn" type="danger" @click="Requirementsn = true">
          {{ $t("CallUs") }}
        </div>
      </div>
    </div>

    <!-- Requirements 弹窗 -->
    <el-dialog
      :append-to-body="true"
      title=""
      :visible.sync="Requirementsn"
      class="contsctUsmodel1"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="logo"></div>
      <div class="loginform1">
        <el-form ref="form" :model="form" label-width="0">
          <el-form-item>
            <el-input v-model="form.name" placeholder="Name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.name" placeholder="Compamy"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.name" placeholder="Email"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.name" placeholder="Telephone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              type="textarea"
              v-model="form.name"
              :rows="4"
              placeholder="Message"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="danger">Send</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      title=""
      :visible.sync="Requirements"
      class="contsctUsmodel1"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="logo"></div>
      <div class="loginform1">
        <el-form ref="form" :model="form" label-width="0">
          <el-form-item>
            <el-input v-model="form.name" placeholder="Name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.name" placeholder="Compamy"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.name" placeholder="Email"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.name" placeholder="Telephone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              type="textarea"
              v-model="form.name"
              :rows="4"
              placeholder="Message"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="danger">Send</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import img1 from "./../../assets/images/banner1.jpg";

export default {
  data() {
    return {
      imgList: {
        url: img1,
        className: "fadeInUp animated textLeft",
        title: "Contact Us",
        xtitle: "Anytime，Anywhere. \n We're here when you need us.",
      },
      Requirements: false,
      Requirementsn: false,
      form: {
        name: "",
      },
    };
  },
  methods: {},
};
</script>

<style scpoed lang="less">
.contsctUsmodel1 {
  .el-dialog {
    width: 102.125rem;
    height: 117.125rem;
    padding-top: 40px;
    .logo {
      height: 22.0625rem;
    }
    i {
      font-size: 12rem;
    }
    .loginform1 {
      margin: 33px 89px 0 94px;
      .el-textarea {
        font-size: 70px;
        .el-textarea__inner{
          font-weight: 600;
        }
      }
    }
    .el-button {
      width: 100%;
      font-size: 5.875rem;
    }
    .GetValicode {
      width: 230px;
      float: right;
    }
    .GetValicodeinput {
      width: 278px;
    }
    .wjmm {
      float: right;
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
    }
  }
}
.loginform1 .el-input__inner {
  height: 150px;
  font-size: 50px;
}
.YDwechat-con {
  margin:50px;
  text-align: center;
  position: relative;
  width: 600px;
  .YDContact {
    font-size: 40px;
  }
  .YDwechatred {
    margin-top: 30px;
    height: 600px;
    background: #ce131c;
    border-radius: 30px 30px 0 0;
  }
  .YDwechatbai {
    height: 250px;
  }
  .YDerweima {
    position: absolute;
    margin: 30px;
    background-color: #fff;
    width: 530px;
    top: 0;
    border-radius: 25px;
    .YDtel {
      background: #23d366;
      background-image: url(../../assets/images/whatsapp.png);
      color: #fff;
      margin: 20px 45px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-position: 17px center;
      background-size: 30px;
      padding-left: 70px;
      font-size: 40px;
      text-align: left;
      height: 50px;
    }
    .YDsaoyisao {
      margin-top: 50px;
    }
  }
}
.contsctUs {
  @media screen and (max-width: 766px) {
    .Carousel {
      .el-carousel__container1 {
        position: relative;
        height: 100%;

        .textLeft {
          text-align: left;
        }

        h2 {
          font-size: 60px;
          font-weight: normal;

          color: #fff;
          position: absolute;
          top: 269px;
          left: 10%;
          // padding-bottom: 54px;
          // border-bottom: 2px solid #fff;
        }

        p {
          font-weight: normal;

          font-size: 32px;
          color: #fff;
          position: absolute;
          top: 374px;
          text-align: center;
          width: 80%;
          left: 10%;
          height: 47px;
          line-height: 63px;
          font-weight: bold;
          white-space: pre;
        }

        img {
          width: 100%;
          height: 734px;
        }
      }
    }

    .chatusn {
      margin: 82px 150px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .chatus-item {
        width: 520px;
        padding: 50px 5px;
        margin: 50px;
        background: #fff;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
        border: 1px solid #ffffff;
        border-radius: 30px;
        opacity: 1;

        .img {
          width: 83px;
          height: 77px;
          margin: 84px 218px 54px 219px;

          img {
            width: 83px;
            height: 77px;
          }
        }

        .title {
          height: 37px;
          font-size: 28px;
          color: #000;
          text-align: center;
        }

        .cont {
          line-height: 50px;
          font-size: 24px;
          text-align: center;
          margin: 31px 10px;
          color: #999;
          // height: 151px;
        }

        .btn {
          // height: 73px;
          // width: 206px;
          border-radius: 8px;
          // border: 1px solid #707070;
          // font-size: 28px;
          // line-height: 73px;
          color: #d3323c;
          background: #dedcdd;
          // margin: 5px auto;
          // margin-bottom: 20px;
          text-align: center;
          cursor: pointer;

          &:hover {
            background: #d0111d;
            color: #fff;
          }
        }
      }
    }
    .information {
      margin: 75px 150px;
      h2 {
        height: 47rem;
        font-size: 4rem !important;
        text-align: left;
      }
      .borderred {
        width: 160px;
        height: 2px;
        background: #d11d26;
        margin-bottom: 60px;
      }
      h3 {
        height: 64px;
        font-size: 48px;
        text-align: left;
        font-weight: 500;
      }

      .information_info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .info_con {
          width: 481px;
          height: 715px;
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
          border: 1px solid #ccc;
          text-align: center;
          opacity: 1;
          .pv0 {
            width: 481px;
            height: 379px;
            img {
              width: 481px;
              height: 379px;
            }
          }
          .pv1 {
            font-size: 24px;
            color: #666;
            font-weight: bold;
            margin-top: 27px;
            height: 31px;
          }
          .pv2 {
            margin-top: 16px;
            font-size: 18px;
            color: #999;
            height: 24px;
          }
          .pv3 {
            padding-top: 13px 5px 0 5px;
            font-size: 16px;
            color: #999;
            line-height: 22px;
            height: 106px;
          }
          .pv4 {
            margin-top: 13px;
            font-size: 20px;
            color: #333;
            height: 26px;
          }
        }
      }
    }
    .chatus {
      display: none;
    }
  }
}
</style>
