<template>
  <div class="someCase">
    <div class="mp promt">
      {{ $t("SuccessfulCases") }}
    </div>
    <div class="four_flash">
      <swiper class="flashBg" :options="swiperOption" ref="mySwiper">
        <swiper-slide
          class="gimgv"
          v-for="(item, index) in list"
          :data-index="index"
          :key="index"
        >
          <img
            @click.stop="jumpDetail(index)"
            :src="item.casesMedia"
            style="width: 100%; height: 100%"
          />
        </swiper-slide>
        <div class="newarrow but_left" slot="button-prev"></div>
        <div class="newarrow but_right" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>
<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { getcase1 } from "../../../api/api";

export default {
  name: "SomeCase",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      list: [],
      form: {
        current: 1,
        size: 10,
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    swiperOption() {
      var option = {
        slidesPerView: 3,
        spaceBetween: 50,
        loop: true,
        autoplay: false,
        // 设置点击箭头
        navigation: {
          nextEl: ".but_right",
          prevEl: ".but_left",
        },
        //  on: {
        //   click: (swiper) => {
        //     console.log(swiper);
        //      this.$_this.push('/SuccessfulCases')
        //   },
        // },
      };
      return option;
    },
  },
  methods: {
    //案例列表
    getcase() {
      getcase1(this.form).then((res) => {
        
        if (res.status == 200) {
          res.data.records.forEach((item) => {
            this.list.push({
              casesMedia: this.$global.imgUrl + item.casesMedia,
              casesId: item.casesId,
            });
          });
        }
      });
    },
    //案例详情
    jumpDetail(index) {
      console.log(index);
      console.log(this.list[index].casesId);
      this.$router.push({
        path: "/SuccessfulCases",
        query: {
          casesId: this.list[index].casesId,
        },
      });
    },
    pre() {},
    next() {},
  },
  mounted() {
    //调用案例接口
    this.$nextTick(() => {
      this.getcase();
    });
  },
};
</script>
<style lang="less">
.someCase {
  @media screen and (max-width: 766px) {
    // height: 700px;
    margin: 0 110px;
    .promt {
      text-align: center;
      font-size: 8.375rem;
      height: 166px;
      padding: 70px 0;
    }
    .four_flash {
      position: relative;
      width: 100%;
      height: 582px;
      .flashBg {
        height: 582px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        .imgUls {
          height: 582px;
          width: max-content;
          position: relative;
        }
      }
      .newarrow {
        width: 150px;
        height: 180px;
        position: absolute;
        top: 220px;
        font-size: 28px;
        cursor: pointer;
        z-index: 10;
        color: #707070;
      }
      .but_left {
        left: 90px;
        background: url(./../../../assets/images/left.png) no-repeat;
        background-size: contain;
      }
      .but_right {
        right: 90px;
        transform: rotate(180deg);
        background: url(./../../../assets/images/left.png) no-repeat;
        background-size: contain;
      }
    }
  }
  @media screen and (min-width: 768px) {
    // height: 700px;
    margin: 0 110px;
    .promt {
      text-align: center;
      font-size: 38px;
      font-weight: 600;
      height: 66px;
      padding: 40px 0;
    }
    .four_flash {
      position: relative;
      width: 100%;
      height: 582px;
      .flashBg {
        height: 582px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        .imgUls {
          height: 582px;
          width: max-content;
          position: relative;
        }
      }
      .newarrow {
        width: 50px;
        height: 80px;
        position: absolute;
        top: 220px;
        font-size: 28px;
        cursor: pointer;
        z-index: 10;
        color: #707070;
      }
      .but_left {
        left: 90px;
        background: url(./../../../assets/images/left.png) no-repeat;
        background-size: contain;
      }
      .but_right {
        right: 90px;
        transform: rotate(180deg);
        background: url(./../../../assets/images/left.png) no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>

