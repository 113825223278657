import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  getters:{//是对state数据的派生，只会依赖 state 中的成员去更新

  },
  mutations: {//同步操作

  },
  actions:{//此处可处理异步操作

  },

});
